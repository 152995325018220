'use strict'
import { h } from './RR.js';
import { Junction, Pump, Valve, Car, Tap, Et, Exch, Translate, Rotate, Pipe, Pr, SchemaContext, CRect, CText, presentInScope } from '../components/MiscComponents.js';

export const ServiceKeys={
    'Chauffage':0,'Delestage':0,'Deneigement':0,'ECS':0,'Froid':0,'GeoCooling':0,'PacSurInjection':0,'Piscine':0,'SolaireSurInjection':0,'SolaireThermique':0,'StockageChaud':0,'StockageFroid':0,
};

function hpSvcOn(scope,hp){
    const mode=scope[`Pxx_gene_${hp}_mode`],c=Math.floor(mode/100)%10,d=Math.floor(mode/10)%10,u=Math.floor(mode/1)%10;
    return {
        [hp]:d>0,
        'Chauffage':d>0 && u==1,
        //'Delestage':0,//?
        'Deneigement':d>0 && c==1,//?
        'ECS':d>0 && u==2,
        'Froid':d>0 && c==2,
        'GeoCooling':d>0 && c==5,
        'PacSurInjection':d>0 && u==4,
        'Piscine':d>0 && u==3,
        //'SolaireSurInjection':0,
        'SolaireThermique':d>0 && c==7,
        //'StockageChaud':0,
        'StockageFroid':d>0 && c==4,
    }
}

export function mergeSvcOn(scope,svcOn,hp){
    let on=Object.assign({},svcOn);
    const [i0,i1]=typeof hp=='number' && hp>=1 && hp<=8 ? [hp,hp] : [1,8];
    for(let i=i0;i<=i1;i++){
        const Hp0X='Hp0'+i;
        if(scope[`Cxx_syst_${Hp0X}_Presente`]==1){
            const mode=scope[`Pxx_gene_${Hp0X}_mode`],d=Math.floor(mode/10)%10;
            if(d>0) {
                let svcOn1=hpSvcOn(scope,Hp0X);
                for(let svc in svcOn1)if(svcOn1[svc]) on[svc]=true;
            }
        }
    }
    return on;
}

export function defaultItemWidth(item) {
    return item==''?0:/^Et/.test(item)?30:/^Ex/.test(item) ? 0 : 25;
}

export function Spread(props){
    const {x1,x2,y1,y2,scope,showAll,on,debug}=props;
    const present = presentInScope(scope,showAll);
    const alwaysPresent=x=>/^(|Vmitigeur|Car)$/.test(x);
    const items=props.items
    .filter(a=>(Array.isArray(a)?a:[a]).reduce((acc,x)=>acc||alwaysPresent(x)||showAll||scope[`Cxx_syst_${x}_Presente`]>0,false))
    .filter(a=>a.reduce((acc,item)=>acc||item!=''),false);
    const itemWidth=props.itemWidth||defaultItemWidth;
    const widths=items.map(a=>a.reduce((acc,_,i)=>Math.max(acc,itemWidth(a[i])),0));
    const minWidth=widths.reduce((acc,w)=>acc+w,0);
    const mx=(x2-x1)/minWidth;
    const xs=[];
    if(widths.length==0)widths.push(0)
    let x=x1;
    widths.forEach((_,i)=>{x=x+((i>0?widths[i-1]*.5:0)+widths[i]*.5)*mx;xs.push(x)})
    const toElem=(item,i)=>{
        let ret=item;
        const p=props[item+'props']||{};
        if(item==''||!(alwaysPresent(item)||present(item))) return ret;
        if(/^Pr/.test(item)) ret= h(Translate,{x,y:i==1?y2:y1},Pr(scope,showAll,i==1)([0,item]));
        if(/^Et/.test(item)) ret=h(Et,{x,y:[y1,y2][i],label:item});
        if(/^Ex/.test(item)) ret=h(Exch,{x,y:(y1+y2)*.5,label:item,scaleY:(y2-y1+20)/70});
        if(/^Pu/.test(item)) ret= h(Pump,{rotate:180*(1-i),x,y:[y1,y2][i],scope,label:item,on,double:scope[`Cxx_regu_${item}_Double`]>0,...p});
        if((/^Car/).test(item)) ret= h(Car,{rotate:180*(i),x,y:[y1,y2][i],...p});
        if(/^V/.test(item)) ret= h(Valve,{rotate:i==0?-90:90,x,y:[y1,y2][i],label:item,on,...p});
        if(/^S/.test(item)) ret= h(Tap,{rotate:i==0?180:0,x,y:[y1,y2][i],label:item,...p});
        return ret;
    };
    const ret= items.map((items2,j)=>{
        x=xs[j];
        return items2.map(toElem);
    });
    return ret;
}
