import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { getResultFullName } from '../../../utils/compute.utils';
import { getTableValue } from '../../../utils/data.utils';
import SectionTitle from '../../SectionTitle';
import './ResultsExpandableTable.css';

const ResultsExpandableTable = ({
  template,
  level,
  comparedResults,
  onOpen
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [unfolded, setUnfolded] = useState(false);
  const [loading, setLoading] = useState(false);
  //#endregion

  //#region [methods]
  const handleUnfold = async () => {
    setLoading(true);
    await onOpen();
    setUnfolded(!unfolded);
    setLoading(false);
  };
  //#endregion

  //#region [render]
  return template ? (
    <div>
      {template.title && (
        <SectionTitle level={level}>{template.title}</SectionTitle>
      )}
      <table
        className={`template-table results-table results-expandable-table${
          comparedResults.length === 1 ? ' two-cols-table' : ''
        }`}
      >
        {comparedResults.length > 1 && (
          <thead>
            <tr>
              <th></th>
              {comparedResults.map((result, i) => (
                <th
                  key={'th_result_' + i}
                  title={getResultFullName(i18n, result)}
                >
                  <p className='result-table-title'>
                    {i18n._('result', { index: i + 1 })}
                  </p>
                  {result.Comment && (
                    <p className='result-table-details ellipsis'>
                      {result.Comment}
                    </p>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          <tr>
            <td className='table-label cursor-pointer' onClick={handleUnfold}>
              <span>
                <FontAwesomeIcon
                  icon={unfolded ? 'caret-square-down' : 'caret-square-right'}
                />
                {template.fixedRow.label}
                {loading && <Spinner animation='border' />}
              </span>
            </td>
            {template.fixedRow.values.map((value, index) => {
              const tableValue = getTableValue(
                i18n,
                value,
                template.fixedRow.unit,
                template.fixedRow.precision
              );
              return (
                <td
                  key={'fixed_td_' + index}
                  title={tableValue}
                  className={
                    comparedResults.length > 1
                      ? 'compare-table-value'
                      : 'table-value'
                  }
                >
                  {tableValue}
                </td>
              );
            })}
          </tr>
          {unfolded &&
            template.expandableRows.map((row, rowIndex) => (
              <tr key={'expandable_tr_result_' + rowIndex}>
                <td className='table-label'>{row.label}</td>
                {row.values.map((value, colIndex) => {
                  const tableValue = getTableValue(
                    i18n,
                    value,
                    row.unit,
                    row.precision
                  );
                  return (
                    <td
                      key={'td_result_' + rowIndex + '_' + colIndex}
                      title={tableValue}
                      className={
                        comparedResults.length > 1
                          ? 'compare-table-value'
                          : 'table-value'
                      }
                    >
                      {tableValue}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  ) : null;
  //#endregion
};

export default ResultsExpandableTable;
