'use strict';
import { h, useContext } from './RR.js';
import {
  Junction,
  Pump,
  Valve,
  Car,
  Tap,
  Et,
  HpFan,
  Translate,
  Rotate,
  Pipe,
  Pr,
  presentInScope,
  CText,
  Exch
} from './MiscComponents.js';
import { VerticalPipesStrokeInfos } from '../components/PacG1.js';
import { blockname } from './blocknames';
import SchemaContext from '../../../../../contexts/SchemaContext.jsx';

export function Captage(props) {
  const { scope, svcOn, position, xV700, showAll } = props,
    { showBlockNames } = useContext(SchemaContext),
    present = presentInScope(scope, showAll),
    {
      onLeft,
      onRight,
      invertedLeft,
      invertedRight,
      injSol,
      gc,
      gcRight,
      modes
    } = VerticalPipesStrokeInfos({ scope, svcOn, X: 9, position }),
    on = onLeft,
    inj = Object.keys(modes).filter((hp) => modes[hp] % 10 == 4).length > 0;
  const max = (a, b) => (a > b ? a : b);
  let y = 0;
  let dyt = // y-spaceForInjectionBlockName
    (!present('Pr401') && !present('Pr403') ? 10 : 0) +
    (!present('Pr402') && !present('Pr404') ? 10 : 0) +
    !present('Ex400')
      ? 20
      : 0;

  let items = [
    //h('rect',{x:-30,y:-50,width:130,height:270,className:'dotted'}),
    //frame injection
    h('rect', {
      x: 100,
      y: -dyt,
      width: 300,
      height: 150 + dyt,
      className: 'dotted'
    }),

    h(
      CText,
      { x: -30 + 130 / 2, y: -40, className: 'block-label m' },
      showBlockNames
        ? blockname(scope, 'schema_Captage_BlockName', 'Captage')
        : ''
    ),
    h(
      CText,
      { x: 100 + 300 / 2, y: 10 - dyt, className: 'block-label m' },
      showBlockNames
        ? blockname(scope, 'schema_Injection_BlockName', 'Injection')
        : ''
    )
  ];

  let stroke = [
    onLeft ? (injSol ? '#0aa' : 'blue') : 'black',
    gcRight ? 'blue' : onLeft ? (injSol ? 'red' : '#0aa') : 'black',
    inj ? 'red' : 'black',
    inj ? 'blue' : 'black'
  ];
  {
    const dy = 0;
    items.push(
      h('g', { transform: `translate(45 ${y + dy * 0.5})` }, [
        present(`Vm401`)
          ? h(Valve, { rotate: 0, label: 'Vm401', y: -15, on: onLeft || gc })
          : ''
      ])
    );
    y += dy;
  }

  if (present(`Pr403`) || present(`Pr404`)) {
    const dy = 20;
    items.push(
      h(Pipe, {
        name: 'L2',
        d: `M 25 ${max(0, y + dy)} L 25 ${max(0, y)}`,
        stroke: stroke[0]
      }),
      h(Pipe, {
        name: 'R2',
        d: `M 45 ${max(50, y)} L 45 ${max(50, y + dy)}`,
        stroke: stroke[1]
      }),
      [
        { x: 25, label: 'Pr403', rot: 0 },
        { x: 45, label: 'Pr404', rot: 180 }
      ].map(({ x, label, rot }, i) =>
        h(
          'g',
          { transform: `translate(${x} ${y + dy * 0.5})` },
          !present(`${label}`)
            ? ''
            : h('g', { transform: `rotate(${rot})` }, [
                h('use', { href: '#probe', transform: `rotate(-90)` }),
                h('g', { transform: `translate(-40 0)` }, [
                  h('g', { transform: `rotate(${-rot})` }, [
                    h(CText, {}, label)
                  ])
                ])
              ])
        )
      )
    );
    y += dy;
  }
  if (present(`Ex400`)) {
    const dy = 20;
    items.push(
      h(
        'g',
        { transform: `translate(35 ${y + 10}) rotate(90)` },
        h(Exch, { label: 'Ex400', scaleY: 50 / 70 })
      )
    );
    y += dy;
  }
  if (present(`Pr401`) || present(`Pr402`)) {
    const dy = 20;
    items.push(
      h(Pipe, {
        d: `M 25 ${max(0, y + dy)} L 25 ${max(0, y)}`,
        stroke: stroke[0]
      }),
      h(Pipe, {
        d: `M 45 ${max(50, y)} L 45 ${max(50, y + dy)}`,
        stroke: stroke[1]
      }),
      [
        { x: 25, label: 'Pr401', rot: 0 },
        { x: 45, label: 'Pr402', rot: 180 }
      ].map(({ x, label, rot }) =>
        h(
          'g',
          { transform: `translate(${x} ${y + dy * 0.5})` },
          !present(`${label}`)
            ? ''
            : h('g', { transform: `rotate(${rot})` }, [
                h('use', { href: '#probe', transform: `rotate(-90)` }),
                h('g', { transform: `translate(-40 0)` }, [
                  h('g', { transform: `rotate(${-rot})` }, [
                    h(
                      'text',
                      {
                        'dominant-baseline': 'middle',
                        'text-anchor': 'middle'
                      },
                      label
                    )
                  ])
                ])
              ])
        )
      )
    );
    y += dy;
  }

  const sss = svcOn.SolaireSurInjection,
    strokeLeft = gcRight
      ? '#0aa'
      : on || inj || sss
      ? inj || injSol || sss
        ? '#0aa'
        : 'blue'
      : 'black',
    strokeRight = gcRight
      ? 'blue'
      : on || inj || sss
      ? inj || injSol || sss
        ? 'orange'
        : '#0aa'
      : 'black',
    invertedFlow = inj;

  {
    const dy = 80;

    const yj1 = y + (dy * 3) / 8,
      //V700PipeHeight = yj1 + 150 * (position - posFroid) - 50,
      V700PipeHeight = 30;
    items.push(
      h(Pipe, {
        d: `M 25 ${y + (dy * 3) / 8} v ${(-dy * 3) / 8}`,
        stroke: stroke[0]
      }),
      h(Pipe, {
        d: `M 45 ${max(50, y)} L 45 ${max(50, y + (dy * 6) / 8)}`,
        stroke: stroke[1]
      }),
      !present(`V700`)
        ? ''
        : [
            isNaN(V700PipeHeight)
              ? ''
              : h(Pipe, {
                  d: `M 25 ${yj1} h ${-25 + xV700} v ${-V700PipeHeight}`,
                  stroke: gcRight ? '#0aa' : 'black'
                }),
            h(Junction, { x: xV700, y: yj1 - V700PipeHeight }),
            V700PipeHeight <= 30
              ? h(
                  CText,
                  {
                    x: xV700,
                    y: yj1 - V700PipeHeight - 10,
                    style: 'stroke-width:.8'
                  },
                  'G'
                )
              : '',
            h(Valve, {
              label: 'V700',
              rotate: 180,
              x: xV700,
              y: yj1 - V700PipeHeight + 15,
              on: gcRight
            })
          ],
      h(
        'g',
        { transform: `translate(0 ${y})` },
        h(Injection, { scope, on: inj, sss, ypipe: y - 25, showAll })
      ),
      h(Pipe, {
        d: `M 25 ${y + dy} v ${(-dy * 5) / 8}`,
        stroke: strokeLeft,
        invertedFlow
      }),
      h(Pipe, {
        d: `M 45 ${y + (dy * 6) / 8} v ${(dy * 2) / 8}`,
        stroke: strokeRight,
        invertedFlow
      })
    );
    y += dy;
  }
  if (present(`S400`)) {
    const dy = 30;
    items.push(
      h(Pipe, {
        d: `M 25 ${y + dy} v ${-dy}`,
        stroke: strokeLeft,
        invertedFlow
      }),
      h(Pipe, { d: `M 45 ${y} v ${dy}`, stroke: strokeRight, invertedFlow }),
      h(
        'g',
        { transform: `translate(0 ${y})` },
        h(
          'g',
          { transform: `translate(45 0)` },
          h(Tap, { label: 'S400', rotate: -90 })
        )
      )
    );
    y += dy;
  }
  if (present(`Et400`)) {
    const dy = 60;
    items.push(
      h(Pipe, {
        d: `M 25 ${y + dy} v ${-dy}`,
        stroke: strokeLeft,
        invertedFlow
      }),
      h(Pipe, { d: `M 45 ${y} v ${dy}`, stroke: strokeRight, invertedFlow }),
      h(
        'g',
        { transform: `translate(0 ${y})` },
        h(
          'g',
          { transform: `translate(25 ${dy * 0.5})` },
          h(Et, { label: 'Et400', rotate: 90, fill: '#c0f' })
        )
      )
    );
    y += dy;
  }
  // frame captage
  items.push(
    h('rect', {
      x: -30,
      y: -50,
      width: 130,
      height: y + 40,
      className: 'dotted'
    })
  );
  items.push(
    h(Translate, { y }, [
      present(`loop`)
        ? h(Loop, {
            on,
            strokeLeft,
            strokeRight,
            invertedFlow,
            title: blockname(scope, 'schema_Loop_BlockName', 'Boucle')
          })
        : h(Btes)
    ])
  );
  y += 120;
  return h('g', {}, items);
}

function Btes(props) {
  const h1 = 100,
    r1 = 7,
    r2 = 12;

  const u = h('path', {
    fill: 'url(#GradEch)',
    d: `M ${25 + (r2 - r1) / 2} 0 v ${h1} a ${r1} ${r1} 180 1 0 ${
      r1 * 2
    } 0 v ${-h1} h ${r2 - r1} v ${h1} a ${r2} ${r2} 180 1 1 ${
      r2 * -2
    } 0 v ${-h1}`,
    'stroke-width': 0
  });
  return h(
    'g',
    { className: 'BTES', transform: `translate(0,-20)` },
    [5, 4, 3, 2, 1].map((i) =>
      h(
        'g',
        {
          transform: `scale(${256 / Math.pow(15 + i, 2)}) translate(${
            (i - 1) * 8
          } 20)`
        },
        u
      )
    )
  );
}

function Loop(props) {
  const { on, strokeLeft, strokeRight, invertedFlow, title } = props;
  const y = 0,
    dy = 30;
  return h('g', {}, [
    h(Pipe, { d: `M 25 ${y + dy} v ${-dy}`, stroke: strokeLeft, invertedFlow }),
    h(Pipe, { d: `M 45 ${y} v ${dy}`, stroke: strokeRight, invertedFlow }),

    h('path', { d: `M 25 20 m 0 3 l -4 -6 h 8 l -4 6`, fill: 'black' }),
    h('path', { d: `M 46 20 m 0 -3 l -4 6 h 8 l -4 -6`, fill: 'black' }),
    // a rx ry angle large-arc-flag sweep-flag dx dy
    h(Translate, { x: 35, y: 50 }, [
      [0, 180].map((deg) =>
        h(
          'g',
          { transform: `rotate(${deg})` },
          h('path', {
            d: `M 38 -22.5 h 5 a 15 15 90 0 1 15 15 v 15 a 15 15 90 0 1 -15 15 h -60 v 10 l -15 -15 l 15 -15 v 10 h 60 a 5 5 90 0 0 5 -5 v -15 a 5 5 90 0 0 -5 -5 h -5 z`,
            stroke: 'aqua',
            fill: 'aqua'
          })
        )
      )
    ]),
    h('text', { x: 35, y: 50, className: 'block-title m' }, title)
  ]);
  //116x65
}

export function Injection(props) {
  const y = 0,
    dy = 80,
    { scope, on, sss, ypipe, showAll } = props,
    present = presentInScope(scope, showAll),
    hotColor = 'orange',
    widthExMiss = scope[`Cxx_syst_Ex410_Presente`] > 0 ? 0 : 20;

  return h('g', {}, [
    h(Pipe, {
      name: 'LH1',
      d: `M 25 30 h 15 m 10 0 h 45`,
      stroke: on || sss ? '#0aa' : 'black'
    }),
    h(Pipe, {
      name: 'LH11',
      d: `M 95 30 L ${190 + widthExMiss} 30`,
      stroke: on ? '#0aa' : 'black'
    }),
    h(Pipe, {
      name: 'RH1',
      d: `M 210 30 L 355 30`,
      stroke: on ? 'blue' : 'black'
    }),
    h(Pipe, {
      name: '___RV2',
      d: `M 375 ${-ypipe - 25} L 375 60`,
      stroke: on ? hotColor : 'black'
    }),
    h(Pipe, {
      name: 'RH2',
      d: `M 375 60 L 210 60`,
      stroke: on ? hotColor : 'black'
    }),
    h(Pipe, {
      name: '___LH2',
      d: `M 115 60 h -70`,
      stroke: on || sss ? 'orange' : 'black'
    }),
    h(Pipe, {
      name: '___MH2',
      d: `M ${190 + widthExMiss} 60 L 115 60`,
      stroke: on ? 'orange' : 'black'
    }),
    h(Pipe, {
      name: 'RV2',
      d: `M 355 30  v ${-y - ypipe - 5}`,
      stroke: on ? 'blue' : 'black'
    }),
    [
      { x: 25, cy: 30, fill: 'blue', dir: 'right' },
      { x: 45, cy: 60, fill: 'red', dir: 'left' }
    ].map(({ x, cy, fill, dir }) =>
      h('g', { transform: `translate(${x} ${y})` }, [
        Junction({ y: cy }),
        h('use', { href: '#arrow' + dir + fill, x: 60 - x, y: cy })
      ])
    ),
    h('g', { transform: `translate(200 30)` }, [
      h('g', { transform: `translate(-80 0)` }, [
        present(`Vm410`) ? h(Valve, { label: 'Vm410', rotate: -90 }) : '',
        present(`Pu410`)
          ? h(Pump, { scope, label: 'Pu410', rotate: 180, on })
          : ''
      ]),
      [
        [-30, 'Pr411'],
        [30, 'Pr413']
      ].map(Pr(scope, showAll)),
      h('use', { href: '#arrowrightblue', x: 130 })
    ]),
    !present(`Ex410`)
      ? ''
      : h('g', { transform: `translate(200 45)` }, [
          h(Exch, { label: 'Ex410', scaleY: 50 / 70 })
        ]),
    h('g', { transform: `translate(200 60)` }, [
      h(Car, { rotate: 180, x: -65 }),
      [
        [-30, 'Pr412'],
        [30, 'Pr414']
      ].map(Pr(scope, showAll, true)),
      h('use', { href: '#arrowleftred', x: 130 })
    ]),
    showAll || +scope[`Cxx_syst_ServiceSolaireSurInjection`] > 0
      ? h(
          'g',
          { transform: `translate(15 ${dy})` },
          h(InjectionHybride, { scope, showAll, on: sss })
        )
      : ''
  ]);
}

export const InjectionHybride = (props) => {
  const { scope, on, showAll, title } = props,
    { showFrames } = useContext(SchemaContext),
    present = presentInScope(scope, showAll);
  return h('g', {}, [
    h('rect', { x: 55, y: 10, width: 330, height: 100, className: 'dotted' }),
    h('g', { className: 'hline1', transform: `translate(200 40)` }, [
      h(Junction, { x: -100, y: -60 }),
      h(Pipe, {
        d: `M -100 -60 v 60 h 150`,
        stroke: on ? 'red' : 'black',
        fill: 'none',
        invertedFlow: on
      }),
      h('path', { d: `M 50 0 h20`, stroke: 'darkorange' }),
      Pr(scope, showAll)([-45, 'Pr422'])
    ]),
    h('g', { className: 'hline0', transform: `translate(200 55)` }, [
      h('rect', {
        x: 50,
        y: -16,
        width: 20,
        height: 32,
        stroke: 'none',
        fill: 'url(#solar)'
      }),
      h('text', { x: 75 }, title)
    ]),
    h('g', { className: 'hline2', transform: `translate(200 70)` }, [
      h(Junction, { x: -120, y: -120 }),
      h(Pipe, {
        d: `M -120 -120 v 25 m 0 10 v 85 h 170`,
        stroke: on ? 'blue' : 'black',
        fill: 'none',
        invertedFlow: false
      }),
      present(`Vm420`)
        ? h(
            'g',
            { transform: `translate(-120 -15)` },
            h(Valve, { label: 'Vm420', rotate: 0 })
          )
        : '',
      h('path', { d: `M 50 0 h20`, stroke: 'blue' }),
      Pr(scope, showAll, true)([-45, 'Pr421']),
      present(`Et420`)
        ? h(
            'g',
            { transform: `translate(25 0)` },
            h(Et, { label: 'Et420', dy: 30 })
          )
        : ''
    ])
  ]);
};
