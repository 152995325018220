import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { getSstParamsConstraints } from '../../../../../../../../../../../server/models/design/calculationData.model';
import SimuSstInput from '../SimuSstInput';
import './SimuSstTable.css';

const SimuSstTable = ({ project, calculationData, onSstConstraintChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [methods]
  const getStdData = (sst, key) => {
    return Math.round(sst.summaryData[key]).toLocaleString();
  };

  const isConstraintPresent = (key) => {
    return key in calculationData.constraints.params;
  };

  const getSstParam = (sst, key) => {
    return Object.values(getSstParamsConstraints(sst, project)).find(
      (param) => param.key === key
    );
  };
  //#endregion

  //#region [render]
  const constraintsCount =
    (isConstraintPresent('HPGPowerMaxPerSST') ? 1 : 0) +
    (isConstraintPresent('HPAPowerMax') ? 1 : 0) +
    (isConstraintPresent('GasPowerMax') ? 1 : 0);
  if (constraintsCount === 0) return null;
  const tableStyle =
    constraintsCount === 1
      ? { width: 'fit-content', minWidth: '500px' }
      : { width: '100%' };
  return (
    <div>
      <h3 className='mt-3'>{i18n._('compute.simu.sizingPerSst')}</h3>
      <table className='simu-sst-table custom-table' style={tableStyle}>
        <thead>
          <tr>
            <th>{i18n._('compute.simu.substation')}</th>
            {isConstraintPresent('HPGPowerMaxPerSST') && (
              <th>{i18n._('compute.HPGPowerMaxPerSST')}</th>
            )}
            {isConstraintPresent('HPAPowerMax') && (
              <th>{i18n._('compute.HPAPowerMax')}</th>
            )}
            {isConstraintPresent('GasPowerMax') && (
              <th>{i18n._('compute.GasPowerMax')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {project.substations.map((sst, index) => {
            return (
              <tr key={'simuSstTableTr_' + index}>
                <td>
                  {sst.InitStationName}
                  <FontAwesomeIcon
                    icon='fa-regular fa-circle-question'
                    data-tooltip-id={'tooltip_simu_sst_summary_data_' + index}
                    data-tooltip-content={i18n._('description.summaryData', {
                      sumHeat: getStdData(sst, 'sumHeat'),
                      maxHeat: getStdData(sst, 'maxHeat'),
                      sumCold: getStdData(sst, 'sumCold'),
                      maxCold: getStdData(sst, 'maxCold'),
                      sumHotwater: getStdData(sst, 'sumHotwater'),
                      maxHotwater: getStdData(sst, 'maxHotwater')
                    })}
                  />
                  <Tooltip
                    id={'tooltip_simu_sst_summary_data_' + index}
                    place='right'
                    className='info-tooltip'
                    opacity={1}
                  />
                </td>
                {isConstraintPresent('HPGPowerMaxPerSST') &&
                  (() => {
                    const hpgParam = getSstParam(sst, 'HPGPowerMaxPerSST');
                    const hpgValue =
                      calculationData.constraints.params[hpgParam.key][index];
                    return (
                      <td>
                        <SimuSstInput
                          label={i18n._('compute.HPGPowerMaxPerSST')}
                          param={hpgParam}
                          value={hpgValue}
                          maxFormula={i18n._(
                            'compute.HPGPowerMaxPerSst.formula'
                          )}
                          onChange={(param, value) =>
                            onSstConstraintChange(param, value, index)
                          }
                        />
                      </td>
                    );
                  })()}
                {isConstraintPresent('HPAPowerMax') &&
                  (() => {
                    const hpaParam = getSstParam(sst, 'HPAPowerMax');
                    const hpaValue =
                      calculationData.constraints.params[hpaParam.key][index];
                    return (
                      <td>
                        <SimuSstInput
                          label={i18n._('compute.HPAPowerMax')}
                          param={hpaParam}
                          value={hpaValue}
                          maxFormula={i18n._(
                            'compute.HPAPowerMaxPerSst.formula'
                          )}
                          onChange={(param, value) =>
                            onSstConstraintChange(param, value, index)
                          }
                        />
                      </td>
                    );
                  })()}
                {isConstraintPresent('GasPowerMax') &&
                  (() => {
                    const gasParam = getSstParam(sst, 'GasPowerMax');
                    const gasValue =
                      calculationData.constraints.params[gasParam.key][index];
                    return (
                      <td>
                        <SimuSstInput
                          label={i18n._('compute.GasPowerMax')}
                          param={gasParam}
                          value={gasValue}
                          maxFormula={i18n._(
                            'compute.GasPowerMaxPerSst.formula'
                          )}
                          onChange={(param, value) =>
                            onSstConstraintChange(param, value, index)
                          }
                        />
                      </td>
                    );
                  })()}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
  //#endregion
};

export default React.memo(SimuSstTable);
