import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { COST_SCHEMA } from '../../../../../server/validation/cost.validation';
import {
  fetchCost,
  fetchProjectLocation,
  updateCost
} from '../../../api/project.api';
import Bloc from '../../../components/Bloc/Bloc';
import AjvContext from '../../../contexts/AjvContext';
import PopupContext from '../../../contexts/PopupContext';
import { IS_DEV } from '../../../utils/env.utils';
import './CostsPage.css';
import BtesBloc from './blocs/BtesBloc';
import ElecBloc from './blocs/ElecBloc';
import ElecLotBloc from './blocs/ElecLotBloc';
import GasBloc from './blocs/GasBloc';
import GasBoilerBloc from './blocs/GasBoilerBloc';
import HpaBloc from './blocs/HpaBloc';
import HpgBloc from './blocs/HpgBloc';
import HydraulicLotBloc from './blocs/HydraulicLotBloc';
import InjectionBloc from './blocs/InjectionBloc';
import ItesBloc from './blocs/ItesBloc';
import OtherCapexBloc from './blocs/OtherCapexBloc';
import PhotovoltaicBloc from './blocs/PhotovoltaicBloc';
import ReferenceOtherOpex from './blocs/ReferenceOtherOpex';
import SolarThermalBloc from './blocs/SolarThermalBloc';
import SolutionOtherOpexBloc from './blocs/SolutionOtherOpexBloc';
import SubsidiesBloc from './blocs/SubsidiesBloc';
import WaterTankColdBloc from './blocs/WaterTankColdBloc';
import WaterTankHotBloc from './blocs/WaterTankHotBloc';
import WaterTankHotwaterBloc from './blocs/WaterTankHotwaterBloc';

const CostsPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { ajv } = useContext(AjvContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [form, setForm] = useState();
  const [projectLocation, setProjectLocation] = useState();
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const form = await fetchCost(projectId);
        const location = await fetchProjectLocation(projectId);
        setForm(() => form);
        setProjectLocation(() => location);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [methods]
  const handleInputChange = (key, value) => {
    setForm((oldForm) => ({ ...oldForm, [key]: value }));
  };

  const handleTableValueChange = (paramKey, rowKey, value) => {
    setForm((oldForm) => ({
      ...oldForm,
      [paramKey]: {
        ...oldForm[paramKey],
        [rowKey]: value
      }
    }));
  };

  const handleSubmitClick = async () => {
    try {
      await updateCost(projectId, form);
      navigate(
        `/company/${companyId}/project/${projectId}/design/compute?type=opti`
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  if (!form || !projectLocation) return null;
  const validate = ajv.compile(COST_SCHEMA);
  const isFormValid = validate(form);
  if (IS_DEV && !isFormValid) console.log(validate.errors);
  return (
    <div className='cost-page'>
      <Bloc title={i18n._('capex.hypotheses')}>
        <BtesBloc
          form={form}
          projectLocation={projectLocation}
          onInputChange={handleInputChange}
          onTableValueChange={handleTableValueChange}
        />
        <HpgBloc form={form} onTableValueChange={handleTableValueChange} />
        <HpaBloc form={form} onTableValueChange={handleTableValueChange} />
        <GasBoilerBloc
          form={form}
          onTableValueChange={handleTableValueChange}
        />
        <WaterTankHotBloc form={form} onInputChange={handleInputChange} />
        <WaterTankColdBloc form={form} onInputChange={handleInputChange} />
        <WaterTankHotwaterBloc form={form} onInputChange={handleInputChange} />
        <InjectionBloc form={form} onInputChange={handleInputChange} />
        <SolarThermalBloc form={form} onInputChange={handleInputChange} />
        <PhotovoltaicBloc form={form} onInputChange={handleInputChange} />
        <ItesBloc form={form} onInputChange={handleInputChange} />
        <HydraulicLotBloc form={form} onInputChange={handleInputChange} />
        <ElecLotBloc form={form} onInputChange={handleInputChange} />
        <OtherCapexBloc form={form} onInputChange={handleInputChange} />
        <SubsidiesBloc form={form} onInputChange={handleInputChange} />
      </Bloc>
      <Bloc title={i18n._('opex.hypotheses')}>
        <ElecBloc form={form} onInputChange={handleInputChange} />
        <GasBloc form={form} onInputChange={handleInputChange} />
        <SolutionOtherOpexBloc form={form} onInputChange={handleInputChange} />
        <ReferenceOtherOpex form={form} onInputChange={handleInputChange} />
      </Bloc>
      <div className='bottom-page-btns'>
        <Button
          variant='primary'
          onClick={handleSubmitClick}
          disabled={!isFormValid}
        >
          {i18n._('save')}
        </Button>
      </div>
    </div>
  );
  //#endregion
};

export default CostsPage;
