import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { fetchEngineDataSent } from '../../../../../../api/calculation.api';
import PopupContext from '../../../../../../contexts/PopupContext';
import { getEngineError } from '../../../../../../utils/error.utils';
import { downloadFile } from '../../../../../../utils/file.utils';
import './EngineErrorCard.css';

const EngineErrorCard = ({ error }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleDlEngineDataClick = async () => {
    try {
      const { code, calculationId, projectId } = error;
      const engineData = await fetchEngineDataSent(calculationId, projectId);
      const filename = i18n._('calculation.engineError.filename', {
        code,
        projectId
      });
      downloadFile(
        `${filename}.json`,
        new Blob([JSON.stringify(engineData)], {
          type: 'text/json'
        })
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { code, message } = error;
  const engineError = getEngineError(i18n, code);
  return (
    <div className='error-card'>
      <div className='error-card-title'>
        <FontAwesomeIcon icon='face-frown' />
        <span>{i18n._('calculation.error')}</span>
        {code && <span>{i18n._('calculation.error.code', { code })}</span>}
        <Button variant='outline-secondary' onClick={handleDlEngineDataClick}>
          <FontAwesomeIcon icon='download' />
          {i18n._('calculation.dataSent.dl')}
        </Button>
      </div>
      <div className='error-card-bottom'>
        {engineError ? (
          <div className='error-card-engine'>
            {engineError.message && (
              <span className='error-card-message'>
                <FontAwesomeIcon icon='circle' />
                {engineError.message}
              </span>
            )}
            {engineError.causes?.map((engineErrorCause, i) => (
              <div
                className='error-card-causes-actions'
                key={'error_card_cause_' + i + 1}
              >
                <span>
                  <FontAwesomeIcon icon='xmark' />
                  {engineErrorCause.cause}
                </span>
                {engineErrorCause.actions?.map((action, j) => (
                  <span key={'error_card_action_' + j + 1}>
                    <FontAwesomeIcon icon='wrench' />
                    {action}
                  </span>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <span>
            <FontAwesomeIcon icon='xmark' />
            {message}
          </span>
        )}
      </div>
    </div>
  );
  //#endregion
};

export default React.memo(EngineErrorCard);
