import { useLingui } from '@lingui/react';
import React from 'react';
import { Form } from 'react-bootstrap';
import { SIMU_GOAL } from '../../../../../../../../../server/models/design/calculationData.model';
import SimuInput from '../../components/SimuInput';
import './SimuGoalsSection.css';

const SimuGoalsSection = ({
  calculationData,
  onConstraintChange,
  onHpgEnabledCheck
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <div className='simulation-section'>
      <h2>{i18n._('compute.simu.goals')}</h2>
      <SimuInput
        label={
          <span className='compute-lbHpgPartTarget'>
            <span>{i18n._('compute.InitConstraintHpg')}</span>
            <Form.Check
              type='switch'
              checked={calculationData.constraints.inp.LbHpgPartTargetEnabled}
              onChange={onHpgEnabledCheck}
              name='LbHpgPartTargetEnabled'
              id='LbHpgPartTargetEnabled'
            />
          </span>
        }
        value={calculationData.constraints.inp.InitConstraintHpg}
        param={SIMU_GOAL.InitConstraintHpg}
        disabled={!calculationData.constraints.inp.LbHpgPartTargetEnabled}
        onChange={onConstraintChange}
      />
    </div>
  );
  //#endregion
};

export default React.memo(SimuGoalsSection);
