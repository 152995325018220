import Ajv from 'ajv';
import { isNull } from '../utils/data.utils';

const ajv = new Ajv({ allErrors: true });

// on ajoute le mot clé isNotEmpty pour vérifier si une chaîne de caractères n'est pas vide
ajv.addKeyword({
  keyword: 'isNotEmpty',
  type: 'string',
  schemaType: 'boolean',
  code(cxt) {
    const { data, schema } = cxt;
    if (schema) cxt.fail(`${data}.trim() === ''`);
  },
  error: {
    message: 'string must not be empty'
  }
});

// on ajoute le mot clé bounds pour vérifier si bounds[0][key] <= bounds[1][key]
ajv.addKeyword({
  keyword: 'areBounds',
  type: 'array',
  schemaType: 'boolean',
  validate: function (schema, data) {
    if (!schema) return true; // si lowerBound est différent de true, on passe toujours la validation
    if (data.length !== 2) return false; // si le tableau n'a pas 2 éléments la validation est échouée
    const [lowerBound, upperBound] = data;
    return Object.keys(lowerBound).every((prop) => {
      const minValue = lowerBound[prop];
      const maxValue = upperBound[prop];
      const isNullOrEmpty = (val) => isNull(val) || val === '';
      if (Array.isArray(minValue) && Array.isArray(maxValue)) {
        return (
          minValue.length === maxValue.length &&
          maxValue.every((val) => !isNullOrEmpty(val)) &&
          minValue.every((val, i) => !isNullOrEmpty(val) && val <= maxValue[i])
        );
      } else {
        return !(
          minValue > maxValue ||
          (isNullOrEmpty(minValue) && !isNullOrEmpty(maxValue)) ||
          (!isNullOrEmpty(minValue) && isNullOrEmpty(maxValue))
        );
      }
    });
  },
  error: {
    message: 'bounds[0] must be lower than bounds[1]'
  }
});

export default ajv;
