import {
  COMPUTE_TYPE,
  REFERENCE_SOLUTION,
  UNIT
} from '../../../server/constants';
import {
  OPTI_GOALS,
  SIMU_GOALS
} from '../../../server/models/design/calculationData.model';
import { formatValueWithUnit } from './data.utils';
import { formatDateAndHour } from './date.utils';

export const getComputeType = (i18n, type) => {
  return type === COMPUTE_TYPE.SIMU
    ? i18n._('computeType.simu')
    : i18n._('computeType.opti');
};

const getResultDescriptionName = (i18n, descriptionName, descriptionIndex) => {
  let resultDescriptionName;
  if (descriptionName) {
    resultDescriptionName = descriptionName;
  } else {
    resultDescriptionName =
      descriptionIndex !== -1
        ? i18n._('description', { index: descriptionIndex + 1 })
        : i18n._('description.deleted');
  }
  return resultDescriptionName;
};

export const getCompareResultName = (i18n, comment, resultIndex) => {
  const name = [i18n._('result', { index: resultIndex + 1 })];
  if (comment) name.push(comment);
  return name.join(i18n._('result.name.separator'));
};

export const getGroupHeadingName = (i18n, group) => {
  const name = [];
  // on ajoute au nom du résultat le type...
  name.push(getComputeType(i18n, group.type));

  // ...la date
  name.push(formatDateAndHour(i18n, group.created_at));

  // ... la description
  const descriptionName = getResultDescriptionName(
    i18n,
    group.descriptionName,
    group.descriptionIndex
  );
  name.push(descriptionName);

  return name.join(i18n._('result.name.separator'));
};

export const getOptionName = (i18n, goalPart, comment) => {
  const name = [];
  if (goalPart) {
    name.push(
      i18n._(`result.name.goal.${goalPart.key}`, {
        value: formatValueWithUnit(i18n, goalPart.value * 100, UNIT.PERCENT)
      })
    );
  } else {
    name.push(i18n._('result.name.noGoal'));
  }
  if (comment) name.push(comment);
  return name.join(i18n._('result.name.separator'));
};

export const getResultFullName = (i18n, result) => {
  const name = [];
  // on ajoute au nom du résultat le type...
  name.push(getComputeType(i18n, result.Type));

  // ...la date
  name.push(formatDateAndHour(i18n, result.created_at));

  // ... la description
  const descriptionName = getResultDescriptionName(
    i18n,
    result.descriptionName,
    result.descriptionIndex
  );
  name.push(descriptionName);

  // l'objectif
  if (result.GoalPart) {
    name.push(
      i18n._(`result.name.goal.${result.GoalPart.key}`, {
        value: formatValueWithUnit(
          i18n,
          result.GoalPart.value * 100,
          UNIT.PERCENT
        )
      })
    );
  } else {
    name.push(i18n._('result.name.noGoal'));
  }

  // le commentaire
  if (result.Comment) name.push(result.Comment);

  return name.join(i18n._('result.name.separator'));
};

export const getSelectedGoalParam = (inp) => {
  if (inp.InitConstraintEnabled) {
    return OPTI_GOALS.find((goal) => inp[goal.key] !== goal.default);
  } else if (inp.LbHpgPartTargetEnabled) {
    return SIMU_GOALS.find((goal) => inp[goal.key] !== goal.default);
  } else {
    return null;
  }
};

export const getSummaryRefPath = (inp) => {
  return inp.InitReferenceSolution === REFERENCE_SOLUTION.GAS_CHILLER
    ? 'summaryRefGas'
    : 'summaryRefHpa';
};
