'use strict';
export * from 'react';
import { createElement } from 'react';
import { createRoot } from 'react-dom';

export const render = (comp, el) => createRoot(el).render(comp);

let levels = [{ idgen: 0 }];

export function h(type, config, children) {
  if (!config) config = {};
  const level = levels[levels.length - 1];
  levels.push({ idgen: 0 });
  if (!config.key) {
    config.key = config.id || '_' + (levels.length - 1) + '_' + ++level.idgen;
  }
  if (typeof config.style == 'string') {
    config.style = Object.fromEntries(
      config.style.split(/;\s*/).map((s) => s.split(':').map((s) => s.trim()))
    );
  }
  if (typeof config.style == 'object') {
    let s = {};
    for (let k in config.style) {
      const k1 = k.replace(/-([a-z])/g, (m) => m[1].toUpperCase());
      s[k1] = config.style[k];
    }
    config.style = s;
  }
  if (config.for) {
    config.htmlFor = config.for;
    delete config.for;
  }
  if ('value' in config && !config.onChange) config.readOnly = true;
  if ('checked' in config && !config.onChange) config.readOnly = true;
  const hprops = Object.keys(config).filter((k) => /-([a-z])/g.test(k));
  if (hprops.length > 0) {
    for (let k of hprops) {
      const k1 = k.replace(/-([a-z])/g, (m) => m[1].toUpperCase());
      config[k1] = config[k];
      delete config[k];
    }
  }
  const args = Array.from(arguments);
  if (args.length > 3) {
    children = args.slice(2);
  }
  levels.pop();
  return createElement(type, config, children);
}
