import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'core-js/stable';
import { en, fr } from 'make-plural/plurals';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { LANGUAGE, STORAGE_KEYS } from '../../server/constants.js';
import Navigate from './components/Navigate';
import ajv from './conf/ajv.conf.js';
import AjvContext from './contexts/AjvContext.jsx';
import './icons.js';
import { messages as enMessages } from './locale/en/messages';
import { messages as frMessages } from './locale/fr/messages';
import Router from './navigation/Router';
import history from './navigation/history';
import './styles/global.css';

//#region [internationalization]
const DEFAULT_LANGUAGE = LANGUAGE.FR;
i18n.loadLocaleData({
  en: { plurals: en },
  fr: { plurals: fr }
});
i18n.load({
  en: enMessages,
  fr: frMessages
});
i18n.activate(localStorage[STORAGE_KEYS.LANGUAGE] || DEFAULT_LANGUAGE);

Plotly.register([require('plotly.js-locales/fr')]);
//#endregion

//#region [router]
const root = createRoot(document.getElementById('design-ui'));
root.render(
  <I18nProvider i18n={i18n}>
    <AjvContext.Provider value={{ ajv }}>
      <HistoryRouter history={history}>
        <Navigate />
        <Router />
      </HistoryRouter>
    </AjvContext.Provider>
  </I18nProvider>
);
//#endregion
