const {
  getBtesParams,
  HPG,
  HPA,
  GAS,
  WATERTANK_HOT,
  WATERTANK_COLD,
  WATERTANK_HOTWATER,
  INJECTION,
  SOLAR,
  PV,
  ITES,
  HYDRAULIC,
  ELEC_CAPEX,
  ELEC_OPEX,
  GAS_OPEX,
  OTHER_CAPEX,
  SUBSIDIES,
  OTHER_OPEX_ACCENTA,
  OTHER_OPEX_REF,
} = require('../models/design/cost.model');

const btesParams = getBtesParams();
const COST_SCHEMA = {
  type: 'object',
  properties: {
    [btesParams.InitBtesCostA.key]: {
      type: btesParams.InitBtesCostA.type,
      minimum: btesParams.InitBtesCostA.min,
      maximum: btesParams.InitBtesCostA.max
    },
    [btesParams.InitBtesCostB.key]: {
      type: btesParams.InitBtesCostB.type,
      minimum: btesParams.InitBtesCostB.min,
      maximum: btesParams.InitBtesCostB.max
    },
    [btesParams.InitBtesDiscountDict.key]: {
      type: 'object',
      properties: {
        2000: {
          type: 'number',
          minimum: btesParams.InitBtesDiscountDict.min,
          maximum: btesParams.InitBtesDiscountDict.max
        },
        5000: {
          type: 'number',
          minimum: btesParams.InitBtesDiscountDict.min,
          maximum: btesParams.InitBtesDiscountDict.max
        },
        10000: {
          type: 'number',
          minimum: btesParams.InitBtesDiscountDict.min,
          maximum: btesParams.InitBtesDiscountDict.max
        },
        20000: {
          type: 'number',
          minimum: btesParams.InitBtesDiscountDict.min,
          maximum: btesParams.InitBtesDiscountDict.max
        }
      },
      required: ['2000', '5000', '10000', '20000'],
      additionalProperties: false
    },
    [HPG.InitHPGCost.key]: {
      type: 'object',
      properties: {
        0: {
          type: 'number',
          minimum: HPG.InitHPGCost.min,
          maximum: HPG.InitHPGCost.max
        },
        50: {
          type: 'number',
          minimum: HPG.InitHPGCost.min,
          maximum: HPG.InitHPGCost.max
        },
        150: {
          type: 'number',
          minimum: HPG.InitHPGCost.min,
          maximum: HPG.InitHPGCost.max
        },
        550: {
          type: 'number',
          minimum: HPG.InitHPGCost.min,
          maximum: HPG.InitHPGCost.max
        }
      },
      required: ['0', '50', '150', '550'],
      additionalProperties: false
    },
    [HPA.InitHPACostHeat.key]: {
      type: 'object',
      properties: {
        0: {
          type: 'number',
          minimum: HPA.InitHPACostHeat.min
        },
        50: {
          type: 'number',
          minimum: HPA.InitHPACostHeat.min
        },
        150: {
          type: 'number',
          minimum: HPA.InitHPACostHeat.min
        },
        750: {
          type: 'number',
          minimum: HPA.InitHPACostHeat.min
        }
      },
      required: ['0', '50', '150', '750'],
      additionalProperties: false
    },
    [HPA.InitHPACostHeatCold.key]: {
      type: 'object',
      properties: {
        0: {
          type: 'number',
          minimum: HPA.InitHPACostHeatCold.min
        },
        50: {
          type: 'number',
          minimum: HPA.InitHPACostHeatCold.min
        },
        150: {
          type: 'number',
          minimum: HPA.InitHPACostHeatCold.min
        },
        750: {
          type: 'number',
          minimum: HPA.InitHPACostHeatCold.min
        }
      },
      required: ['0', '50', '150', '750'],
      additionalProperties: false
    },
    [GAS.InitCapexGas.key]: {
      type: 'object',
      properties: {
        0: {
          type: 'number',
          minimum: GAS.InitCapexGas.min
        },
        50: {
          type: 'number',
          minimum: GAS.InitCapexGas.min
        },
        200: {
          type: 'number',
          minimum: GAS.InitCapexGas.min
        },
        750: {
          type: 'number',
          minimum: GAS.InitCapexGas.min
        }
      },
      required: ['0', '50', '200', '750'],
      additionalProperties: false
    },
    [WATERTANK_HOT.InitWaterTankHotCostA.key]: {
      type: WATERTANK_HOT.InitWaterTankHotCostA.type,
      minimum: WATERTANK_HOT.InitWaterTankHotCostA.min
    },
    [WATERTANK_HOT.InitWaterTankHotCostB.key]: {
      type: WATERTANK_HOT.InitWaterTankHotCostA.type,
      minimum: WATERTANK_HOT.InitWaterTankHotCostB.min
    },
    [WATERTANK_COLD.InitWaterTankColdCostA.key]: {
      type: WATERTANK_HOT.InitWaterTankHotCostA.type,
      minimum: WATERTANK_COLD.InitWaterTankColdCostA.min
    },
    [WATERTANK_COLD.InitWaterTankColdCostB.key]: {
      type: WATERTANK_COLD.InitWaterTankColdCostB.type,
      minimum: WATERTANK_COLD.InitWaterTankColdCostB.min
    },
    [WATERTANK_COLD.InitWaterTankColdExchangerCostA.key]: {
      type: WATERTANK_COLD.InitWaterTankColdExchangerCostA.type,
      minimum: WATERTANK_COLD.InitWaterTankColdExchangerCostA.min
    },
    [WATERTANK_COLD.InitWaterTankColdExchangerCostB.key]: {
      type: WATERTANK_COLD.InitWaterTankColdExchangerCostB.type,
      minimum: WATERTANK_COLD.InitWaterTankColdExchangerCostB.min
    },
    [WATERTANK_COLD.InitWaterTankColdPumpCostA.key]: {
      type: WATERTANK_COLD.InitWaterTankColdPumpCostA.type,
      minimum: WATERTANK_COLD.InitWaterTankColdPumpCostA.min
    },
    [WATERTANK_COLD.InitWaterTankColdPumpCostB.key]: {
      type: WATERTANK_COLD.InitWaterTankColdPumpCostB.type,
      minimum: WATERTANK_COLD.InitWaterTankColdPumpCostB.min
    },
    [WATERTANK_HOTWATER.InitWaterTankHotwaterCostA.key]: {
      type: WATERTANK_HOTWATER.InitWaterTankHotwaterCostA.type,
      minimum: WATERTANK_HOTWATER.InitWaterTankHotwaterCostA.min
    },
    [WATERTANK_HOTWATER.InitWaterTankHotwaterCostB.key]: {
      type: WATERTANK_HOTWATER.InitWaterTankHotwaterCostB.type,
      minimum: WATERTANK_HOTWATER.InitWaterTankHotwaterCostB.min
    },
    [WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA.key]: {
      type: WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA.type,
      minimum: WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA.min
    },
    [WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB.key]: {
      type: WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB.type,
      minimum: WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB.min
    },
    [WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA.key]: {
      type: WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA.type,
      minimum: WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA.min
    },
    [WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB.key]: {
      type: WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB.type,
      minimum: WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB.min
    },
    [INJECTION.InitInjectionExchangerCostA.key]: {
      type: INJECTION.InitInjectionExchangerCostA.type,
      minimum: INJECTION.InitInjectionExchangerCostA.min
    },
    [INJECTION.InitInjectionExchangerCostB.key]: {
      type: INJECTION.InitInjectionExchangerCostB.type,
      minimum: INJECTION.InitInjectionExchangerCostB.min
    },
    [INJECTION.InitInjectionPumpCostA.key]: {
      type: INJECTION.InitInjectionPumpCostA.type,
      minimum: INJECTION.InitInjectionPumpCostA.min
    },
    [INJECTION.InitInjectionPumpCostB.key]: {
      type: INJECTION.InitInjectionPumpCostB.type,
      minimum: INJECTION.InitInjectionPumpCostB.min
    },
    [SOLAR.InitSolarThermalPanelCostA.key]: {
      type: SOLAR.InitSolarThermalPanelCostA.type,
      minimum: SOLAR.InitSolarThermalPanelCostA.min
    },
    [SOLAR.InitSolarThermalVanneCost.key]: {
      type: SOLAR.InitSolarThermalVanneCost.type,
      minimum: SOLAR.InitSolarThermalVanneCost.min
    },
    [SOLAR.InitSolarThermalPumpCostA.key]: {
      type: SOLAR.InitSolarThermalPumpCostA.type,
      minimum: SOLAR.InitSolarThermalPumpCostA.min
    },
    [SOLAR.InitSolarThermalPumpCostB.key]: {
      type: SOLAR.InitSolarThermalPumpCostB.type,
      minimum: SOLAR.InitSolarThermalPumpCostB.min
    },
    [PV.InitPVModuleCostA.key]: {
      type: PV.InitPVModuleCostA.type,
      minimum: PV.InitPVModuleCostA.min
    },
    [PV.InitPVModuleCostB.key]: {
      type: PV.InitPVModuleCostB.type,
      minimum: PV.InitPVModuleCostB.min
    },
    [PV.InitPVModuleSupportCostA.key]: {
      type: PV.InitPVModuleSupportCostA.type,
      minimum: PV.InitPVModuleSupportCostA.min
    },
    [PV.InitPVModuleSupportCostB.key]: {
      type: PV.InitPVModuleSupportCostB.type,
      minimum: PV.InitPVModuleSupportCostB.min
    },
    [PV.InitPVInverterCostA.key]: {
      type: PV.InitPVInverterCostA.type,
      minimum: PV.InitPVInverterCostA.min
    },
    [PV.InitPVInverterCostB.key]: {
      type: PV.InitPVInverterCostB.type,
      minimum: PV.InitPVInverterCostB.min
    },
    [ITES.InitItesCostA.key]: {
      type: ITES.InitItesCostA.type,
      minimum: ITES.InitItesCostA.min
    },
    [ITES.InitItesCostB.key]: {
      type: ITES.InitItesCostB.type,
      minimum: ITES.InitItesCostB.min
    },
    [HYDRAULIC.InitCapexHydraulicMargin.key]: {
      type: HYDRAULIC.InitCapexHydraulicMargin.type,
      minimum: HYDRAULIC.InitCapexHydraulicMargin.min,
      maximum: HYDRAULIC.InitCapexHydraulicMargin.max
    },
    [ELEC_CAPEX.InitCapexLotElecA.key]: {
      type: ELEC_CAPEX.InitCapexLotElecA.type,
      minimum: ELEC_CAPEX.InitCapexLotElecA.min
    },
    [ELEC_CAPEX.InitCapexLotElecB.key]: {
      type: ELEC_CAPEX.InitCapexLotElecB.type,
      minimum: ELEC_CAPEX.InitCapexLotElecB.min
    },
    [ELEC_OPEX.InitElecPriceEvolMethod.key]: {
      type: ELEC_OPEX.InitElecPriceEvolMethod.type
    },
    [ELEC_OPEX.InitElecPriceEvol.key]: {
      type: ELEC_OPEX.InitElecPriceEvol.type,
      minimum: ELEC_OPEX.InitElecPriceEvol.min,
      maximum: ELEC_OPEX.InitElecPriceEvol.max
    },
    [ELEC_OPEX.InitPVElecToNetworkPriceEvol.key]: {
      type: ELEC_OPEX.InitPVElecToNetworkPriceEvol.type,
      minimum: ELEC_OPEX.InitPVElecToNetworkPriceEvol.min,
      maximum: ELEC_OPEX.InitPVElecToNetworkPriceEvol.max
    },
    [ELEC_OPEX.InitPVElecToProjectPriceEvol.key]: {
      type: ELEC_OPEX.InitPVElecToProjectPriceEvol.type,
      minimum: ELEC_OPEX.InitPVElecToProjectPriceEvol.min,
      maximum: ELEC_OPEX.InitPVElecToProjectPriceEvol.max
    },
    [GAS_OPEX.InitGasPriceEvolMethod.key]: {
      type: GAS_OPEX.InitGasPriceEvolMethod.type
    },
    [GAS_OPEX.InitGasPriceEvol.key]: {
      type: GAS_OPEX.InitGasPriceEvol.type,
      minimum: GAS_OPEX.InitGasPriceEvol.min,
      maximum: GAS_OPEX.InitGasPriceEvol.max
    },
    [GAS_OPEX.InitGasPriceSubscription.key]: {
      type: GAS_OPEX.InitGasPriceSubscription.type,
      minimum: GAS_OPEX.InitGasPriceSubscription.min
    },
    [GAS_OPEX.InitGasPrice.key]: {
      type: GAS_OPEX.InitGasPrice.type,
      minimum: GAS_OPEX.InitGasPrice.min,
      maximum: GAS_OPEX.InitGasPrice.max
    },
    [OTHER_CAPEX.InitMOEBtesMargin.key]: {
      type: OTHER_CAPEX.InitMOEBtesMargin.type,
      minimum: OTHER_CAPEX.InitMOEBtesMargin.min
    },
    [OTHER_CAPEX.InitMOEMargin.key]: {
      type: OTHER_CAPEX.InitMOEMargin.type,
      minimum: OTHER_CAPEX.InitMOEMargin.min
    },
    [OTHER_CAPEX.InitIntegrationBtesMargin.key]: {
      type: OTHER_CAPEX.InitIntegrationBtesMargin.type,
      minimum: OTHER_CAPEX.InitIntegrationBtesMargin.min
    },
    [OTHER_CAPEX.InitIntegrationMargin.key]: {
      type: OTHER_CAPEX.InitIntegrationMargin.type,
      minimum: OTHER_CAPEX.InitIntegrationMargin.min
    },
    [OTHER_CAPEX.InitCapexOther.key]: {
      type: OTHER_CAPEX.InitCapexOther.type,
      minimum: OTHER_CAPEX.InitCapexOther.min
    },
    [SUBSIDIES.InitSubventionVersion.key]: {
      type: SUBSIDIES.InitSubventionVersion.type,
      minimum: SUBSIDIES.InitSubventionVersion.min
    },
    [OTHER_OPEX_ACCENTA.InitOpexGaPerf.key]: {
      type: OTHER_OPEX_ACCENTA.InitOpexGaPerf.type,
      minimum: OTHER_OPEX_ACCENTA.InitOpexGaPerf.min
    },
    [OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol.key]: {
      type: OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol.type,
      minimum: OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol.min,
      maximum: OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol.max
    },
    [OTHER_OPEX_ACCENTA.InitOpexMaint.key]: {
      type: OTHER_OPEX_ACCENTA.InitOpexMaint.type,
      minimum: OTHER_OPEX_ACCENTA.InitOpexMaint.min
    },
    [OTHER_OPEX_ACCENTA.InitOpexMaintEvol.key]: {
      type: OTHER_OPEX_ACCENTA.InitOpexMaintEvol.type,
      minimum: OTHER_OPEX_ACCENTA.InitOpexMaintEvol.min,
      maximum: OTHER_OPEX_ACCENTA.InitOpexMaintEvol.max
    },
    [OTHER_OPEX_ACCENTA.InitOpexOther.key]: {
      type: OTHER_OPEX_ACCENTA.InitOpexOther.type,
      minimum: OTHER_OPEX_ACCENTA.InitOpexOther.min
    },
    [OTHER_OPEX_ACCENTA.InitOpexOtherEvol.key]: {
      type: OTHER_OPEX_ACCENTA.InitOpexOtherEvol.type,
      minimum: OTHER_OPEX_ACCENTA.InitOpexOtherEvol.min,
      maximum: OTHER_OPEX_ACCENTA.InitOpexOtherEvol.max
    },
    [OTHER_OPEX_ACCENTA.InitYearsBeforeStart.key]: {
      type: OTHER_OPEX_ACCENTA.InitYearsBeforeStart.type,
      minimum: OTHER_OPEX_ACCENTA.InitYearsBeforeStart.min
    },
    [OTHER_OPEX_REF.InitRefOpexGaPerf.key]: {
      type: OTHER_OPEX_REF.InitRefOpexGaPerf.type,
      minimum: OTHER_OPEX_REF.InitRefOpexGaPerf.min
    },
    [OTHER_OPEX_REF.InitRefOpexGaPerfEvol.key]: {
      type: OTHER_OPEX_REF.InitRefOpexGaPerfEvol.type,
      minimum: OTHER_OPEX_REF.InitRefOpexGaPerfEvol.min,
      maximum: OTHER_OPEX_REF.InitRefOpexGaPerfEvol.max
    },
    [OTHER_OPEX_REF.InitRefOpexMaint.key]: {
      type: OTHER_OPEX_REF.InitRefOpexMaint.type,
      minimum: OTHER_OPEX_REF.InitRefOpexMaint.min
    },
    [OTHER_OPEX_REF.InitRefOpexMaintEvol.key]: {
      type: OTHER_OPEX_REF.InitRefOpexMaintEvol.type,
      minimum: OTHER_OPEX_REF.InitRefOpexMaintEvol.min,
      maximum: OTHER_OPEX_REF.InitRefOpexMaintEvol.max
    },
    [OTHER_OPEX_REF.InitRefOpexOther.key]: {
      type: OTHER_OPEX_REF.InitRefOpexOther.type,
      minimum: OTHER_OPEX_REF.InitRefOpexOther.min
    },
    [OTHER_OPEX_REF.InitRefOpexOtherEvol.key]: {
      type: OTHER_OPEX_REF.InitRefOpexOtherEvol.type,
      minimum: OTHER_OPEX_REF.InitRefOpexOtherEvol.min,
      maximum: OTHER_OPEX_REF.InitRefOpexOtherEvol.max
    }
  },
  required: [
    btesParams.InitBtesCostA.key,
    btesParams.InitBtesCostB.key,
    btesParams.InitBtesDiscountDict.key,
    HPG.InitHPGCost.key,
    HPA.InitHPACostHeat.key,
    HPA.InitHPACostHeatCold.key,
    GAS.InitCapexGas.key,
    WATERTANK_HOT.InitWaterTankHotCostA.key,
    WATERTANK_HOT.InitWaterTankHotCostB.key,
    WATERTANK_COLD.InitWaterTankColdCostA.key,
    WATERTANK_COLD.InitWaterTankColdCostB.key,
    WATERTANK_COLD.InitWaterTankColdExchangerCostA.key,
    WATERTANK_COLD.InitWaterTankColdExchangerCostB.key,
    WATERTANK_COLD.InitWaterTankColdPumpCostA.key,
    WATERTANK_COLD.InitWaterTankColdPumpCostB.key,
    WATERTANK_HOTWATER.InitWaterTankHotwaterCostA.key,
    WATERTANK_HOTWATER.InitWaterTankHotwaterCostB.key,
    WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA.key,
    WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB.key,
    WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA.key,
    WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB.key,
    INJECTION.InitInjectionExchangerCostA.key,
    INJECTION.InitInjectionExchangerCostB.key,
    INJECTION.InitInjectionPumpCostA.key,
    INJECTION.InitInjectionPumpCostB.key,
    SOLAR.InitSolarThermalPanelCostA.key,
    SOLAR.InitSolarThermalVanneCost.key,
    SOLAR.InitSolarThermalPumpCostA.key,
    SOLAR.InitSolarThermalPumpCostB.key,
    PV.InitPVModuleCostA.key,
    PV.InitPVModuleCostB.key,
    PV.InitPVModuleSupportCostA.key,
    PV.InitPVModuleSupportCostB.key,
    PV.InitPVInverterCostA.key,
    PV.InitPVInverterCostB.key,
    ITES.InitItesCostA.key,
    ITES.InitItesCostB.key,
    HYDRAULIC.InitCapexHydraulicMargin.key,
    ELEC_CAPEX.InitCapexLotElecA.key,
    ELEC_CAPEX.InitCapexLotElecB.key,
    ELEC_OPEX.InitElecPriceEvolMethod.key,
    ELEC_OPEX.InitElecPriceEvol.key,
    ELEC_OPEX.InitPVElecToNetworkPriceEvol.key,
    ELEC_OPEX.InitPVElecToProjectPriceEvol.key,
    GAS_OPEX.InitGasPriceEvolMethod.key,
    GAS_OPEX.InitGasPriceEvol.key,
    GAS_OPEX.InitGasPriceSubscription.key,
    GAS_OPEX.InitGasPrice.key,
    OTHER_CAPEX.InitMOEBtesMargin.key,
    OTHER_CAPEX.InitMOEMargin.key,
    OTHER_CAPEX.InitIntegrationBtesMargin.key,
    OTHER_CAPEX.InitIntegrationMargin.key,
    OTHER_CAPEX.InitCapexOther.key,
    SUBSIDIES.InitSubventionVersion.key,
    OTHER_OPEX_ACCENTA.InitOpexGaPerf.key,
    OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol.key,
    OTHER_OPEX_ACCENTA.InitOpexMaint.key,
    OTHER_OPEX_ACCENTA.InitOpexMaintEvol.key,
    OTHER_OPEX_ACCENTA.InitOpexOther.key,
    OTHER_OPEX_ACCENTA.InitOpexOtherEvol.key,
    OTHER_OPEX_ACCENTA.InitYearsBeforeStart.key,
    OTHER_OPEX_REF.InitRefOpexGaPerf.key,
    OTHER_OPEX_REF.InitRefOpexGaPerfEvol.key,
    OTHER_OPEX_REF.InitRefOpexMaint.key,
    OTHER_OPEX_REF.InitRefOpexMaintEvol.key,
    OTHER_OPEX_REF.InitRefOpexOther.key,
    OTHER_OPEX_REF.InitRefOpexOtherEvol.key
  ],
  additionalProperties: false
};

module.exports = { COST_SCHEMA };
