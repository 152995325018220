import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  HOURS_PER_YEAR,
  MILLISECONDS_PER_HOUR
} from '../../../../../../../../server/constants';
import { DETAILED_RESULTS_THERMAL_CAPACITY } from '../../../../../../../../server/models/design/result.model';
import LinesChart from '../../../../../../components/Chart/LinesChart';
import { CHART_MODE, CHART_TYPE } from '../../../../../../constants';
import { DETAILED_RESULTS_SST_COLORS } from '../../../../../../styles/colors';

const SubstationsNeedsChart = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { project } = useOutletContext();
  //#endregion

  //#region [memos]
  const chart = useMemo(() => {
    if (!result.details) return null;
    const data = [];
    const keys = Object.assign(
      {},
      ...Object.values(DETAILED_RESULTS_THERMAL_CAPACITY).map(
        (obj) => obj.total
      )
    );
    const x = new Array(HOURS_PER_YEAR);
    let timestamp = new Date(new Date().getFullYear(), 0, 0).getTime();
    for (let i = 0; i < HOURS_PER_YEAR; ++i) {
      timestamp += MILLISECONDS_PER_HOUR;
      x[i] = new Date(timestamp).toISOString();
    }
    Object.keys(keys).forEach((key) => {
      const y = new Array(HOURS_PER_YEAR).fill(0);
      result.ComputeResult.inp.substations
        .filter((sst) => {
          const sstKey = `${sst.InitStationName}_${key}`;
          return sstKey in result.details;
        })
        .forEach((sst) => {
          const sstKey = `${sst.InitStationName}_${key}`;
          const sstNeedsPerKey = result.details[sstKey];
          for (let i = 0; i < HOURS_PER_YEAR; ++i) {
            y[i] += sstNeedsPerKey[i];
          }
        });
      if (y.some((value) => value !== 0)) {
        data.push({
          x,
          y,
          line: { color: DETAILED_RESULTS_SST_COLORS[key] },
          name: i18n._(`detailed.key.${key}`),
          mode: CHART_MODE.LINES,
          type: CHART_TYPE.SCATTER
        });
      }
    });
    return {
      data,
      layout: {
        xaxis: {
          tickformat: '%b',
          dtick: 'M1'
        },
        yaxis: {
          title: {
            text: i18n._('results.energyAndCarbon.needsChart.yaxis.title')
          }
        },
        showlegend: true
      }
    };
  }, [result.details]);
  //#endregion

  //#region [render]
  const title = i18n._(
    'results.energyAndCarbon.needsChart.dynamicThermalSimu',
    { project: project.AhsName }
  );
  return chart ? (
    <LinesChart
      data={chart.data}
      layout={chart.layout}
      title={title}
      filename={i18n._('plotly.filename', {
        projectName:
          result.descriptionName ??
          i18n._('description', { index: result.descriptionIndex + 1 }),
        title
      })}
    />
  ) : null;
  //#endregion
};

export default SubstationsNeedsChart;
