import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  EQUIPMENT_FAMILY,
  UNIT
} from '../../../../../../../../server/constants';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import {
  getTableValue,
  isArrNullOrEmpty
} from '../../../../../../utils/data.utils';
import SubEquipmentRow from '../../components/SubEquipmentRow';
import './EquipmentListSection.css';

const EquipmentListSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [states]
  const [subEquipementList, setSubEquipementList] = useState([]);
  //#endregion

  //#region [effects]
  useEffect(() => {
    return setSubEquipementList([...setAllSubEquipmentRows()]);
  }, []);
  //#endregion

  //#region [methods]
  const setAllSubEquipmentRows = () => {
    const { heatpumps } = config.ConfigsSst[0].Data;
    const rows = [];
    // on crée la liste des lignes dépliables dans le tableau
    heatpumps.list.forEach((hp) => {
      if (hp.lowerModule) {
        rows.push(`${hp.position}_${hp.lowerModule.name}`);
      }
      rows.push(`${hp.position}_${hp.upperModule.name}`);
    });
    return rows;
  };

  const handleShowSubEquipment = (name) => {
    return setSubEquipementList(
      subEquipementList.includes(name)
        ? subEquipementList.filter((currentEq) => currentEq !== name)
        : subEquipementList.concat(name),
      [...subEquipementList]
    );
  };

  const handleShowAllSubEquipment = () => {
    if (subEquipementList.length > 0) {
      setSubEquipementList([]);
    } else {
      setSubEquipementList([...setAllSubEquipmentRows()]);
    }
  };
  //#endregion

  //#region [render]
  const { heatpumps, geostorage } = config.ConfigsSst[0].Data;
  // on crée la liste des modules géostockage
  const geostorageModules = [
    geostorage?.lowerModules?.capture?.name,
    geostorage?.lowerModules?.injection?.name,
    geostorage?.upperModules?.injection?.name
  ].filter((geoModule) => !!geoModule);

  return (
    <Section title={i18n._('config.equipmentList.title')} level={2} open>
      <p>{i18n._('config.equipmentList.description.text')}</p>
      <div className='custom-table-wrapper'>
        <table className='equipment-list-table'>
          <thead>
            <tr>
              <th colSpan={2}></th>
              <th colSpan={2}>
                {i18n._('config.equipmentList.hydraulicEquipment.accenta')}
              </th>
              <th colSpan={5}>
                {i18n._('config.equipmentList.modules.manufacturing')}
              </th>
              <th></th>
            </tr>
            <tr>
              <th>
                <FontAwesomeIcon
                  className='equipment-list-chevron'
                  onClick={handleShowAllSubEquipment}
                  icon={
                    subEquipementList.length > 0
                      ? faChevronDown
                      : faChevronRight
                  }
                />
              </th>
              <th className='equipment-list-equipment-name'>
                {i18n._('config.equipmentList.equipment')}
              </th>
              <th></th>
              <th>{i18n._('config.equipmentList.total')}</th>
              <th>{i18n._('config.equipmentList.total')}</th>
              <th>{i18n._('config.equipmentList.hydraulicAndStructure')}</th>
              <th>{i18n._('config.equipmentList.factoryInsulation')}</th>
              <th>{i18n._('config.equipmentList.siteInsulation')}</th>
              <th>{i18n._('config.equipmentList.config')}</th>
              <th>{i18n._('config.equipmentList.total')}</th>
            </tr>
          </thead>
          <tbody>
            {/* PACS ET LEURS MODULES */}
            {heatpumps.list.map((hp, index) => {
              const lowerModuleRef = `${hp.position}_${hp?.lowerModule?.name}`;
              const upperModuleRef = `${hp.position}_${hp.upperModule.name}`;
              const displayedLowerModuleEquipment =
                hp?.lowerModule?.equipment.filter(
                  (equipment) => equipment.family === EQUIPMENT_FAMILY.CLIMATIC
                );
              const prices = hp?.lowerModule?.prices;
              return (
                <Fragment key={`${index}_${hp.model}_eq`}>
                  {/* Pac */}
                  <tr className={!!index ? 'equipment-list-separator' : ''}>
                    <td></td>
                    <td className='bold'>{hp.model}</td>

                    <td colSpan={2} className='bold'>
                      {getTableValue(i18n, hp.price, UNIT.EURO, 0)}
                    </td>
                    <td colSpan={5}></td>
                    <td className='bold'></td>
                  </tr>
                  {/* Module bas */}
                  <tr>
                    <td>
                      {
                        <FontAwesomeIcon
                          className='equipment-list-chevron'
                          onClick={() => handleShowSubEquipment(lowerModuleRef)}
                          icon={
                            subEquipementList.includes(lowerModuleRef)
                              ? faChevronDown
                              : faChevronRight
                          }
                        />
                      }
                    </td>
                    <td className='bold'>{hp.lowerModule.name}</td>
                    <td colSpan={2} className='bold'>
                      {getTableValue(
                        i18n,
                        prices.moduleEquipmentTotal,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td className='bold'>
                      {getTableValue(
                        i18n,
                        prices.manufacturingTotal,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td>
                      {getTableValue(
                        i18n,
                        prices.hydraulicAndStructure,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td>
                      {getTableValue(
                        i18n,
                        prices.factoryInsulation,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td>
                      {getTableValue(i18n, prices.siteInsulation, UNIT.EURO, 0)}
                    </td>
                    <td>
                      {getTableValue(
                        i18n,
                        prices.equipmentConfig,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td className='bold'>
                      {getTableValue(i18n, prices.total, UNIT.EURO, 0)}
                    </td>
                  </tr>
                  {subEquipementList.includes(lowerModuleRef) && (
                    <SubEquipmentRow list={displayedLowerModuleEquipment} />
                  )}
                  {/* Module haut */}
                  <tr>
                    <td>
                      {!isArrNullOrEmpty(hp.upperModule.equipment) && (
                        <FontAwesomeIcon
                          className='equipment-list-chevron'
                          onClick={() => handleShowSubEquipment(upperModuleRef)}
                          icon={
                            subEquipementList.includes(upperModuleRef)
                              ? faChevronDown
                              : faChevronRight
                          }
                        />
                      )}
                    </td>
                    <td className='bold'>{hp.upperModule.name}</td>
                    <td colSpan={2} className='bold'>
                      {getTableValue(
                        i18n,
                        hp.upperModule.prices.moduleEquipmentTotal,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td>
                      {getTableValue(
                        i18n,
                        hp.upperModule.prices.manufacturingTotal,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td>
                      {getTableValue(
                        i18n,
                        hp.upperModule.prices.hydraulicAndStructure,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='bold'>
                      {getTableValue(
                        i18n,
                        hp.upperModule.prices.total,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                  </tr>
                  {subEquipementList.includes(upperModuleRef) && (
                    <SubEquipmentRow list={hp.upperModule.equipment} />
                  )}
                </Fragment>
              );
            })}
            {/* MODULES CAPTAGE ET INJECTION */}
            {geostorageModules.map((geoModule, index) => {
              if (!geoModule) return;
              return (
                <tr
                  key={`geoModule_${geoModule}`}
                  className={`bold ${
                    (index === 0 || index === 1) && 'equipment-list-separator' // 0 = module captage, 1 = module injection
                  }`}
                >
                  <td></td>
                  <td colSpan={9}>{geoModule}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //endregion
};
export default EquipmentListSection;
