import React from 'react';

export function Switch({ checked, onClick, children = [] }) {
  return (
    <div {...{ onClick }}>
      <svg width={24} height={12}>
        <rect
          width={18}
          height={12}
          rx={6}
          stroke='gray'
          fill={checked ? 'lime' : 'darkred'}
        />
        <circle cx={checked ? 15 : 5} cy={6} r={5} stroke='#000' fill='#ddd' />
      </svg>
      <span style={{ cursor: 'pointer' }}>{children}</span>
    </div>
  );
}

