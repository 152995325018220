import { PacG1 } from '../components/PacG1.js';
import { PacR1, PacR2, PacF1, PacC1, PacM1 } from '../components/PacR1.js';

const Pac=h=>(props)=>{
    let {X, scope, showAll}=props;
    while(X<8 && scope[`Cxx_syst_Pair_Hp0${X}_Hp0${X+1}`]>0) {
        X++;
    }
    let Pac=PacG1;
    const present = x => scope[`Cxx_syst_${x}_Presente`]>0;
    if(scope[`Cxx_syst_Hp0${X}_Type`]==2){ // 2=Pac air Pac+=R1|R2|F1|C1|M1 ?
        if(present(`ExHp0${X}`)) {
            Pac=scope[`Cxx_syst_ExHp0${X}_Position`]>0 ? PacR2:PacR1
        }else{
            const froidseul=['Chaud', 'Ecs', 'Piscine', 'Surinjection'].reduce((acc, suffix)=>acc && scope[`Cxx_syst_Hp0${X}_NbLevelAutorise${suffix}`]===0, true);
            const chaudseul=['Froid', 'StockFroid'].reduce((acc, suffix)=>acc && scope[`Cxx_syst_Hp0${X}_NbLevelAutorise${suffix}`]===0, true);
            if(froidseul) {
                Pac=PacF1;
            }else if(chaudseul) { // C1 ou M1
                Pac=PacC1;
            }else{
                Pac=PacM1; // Direct Ballon froid
            }
        }
    }
    return h(Pac, {...props,key:''});
}

export default Pac;