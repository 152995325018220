// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ref-td,
.accenta-td {
  text-align: end;
  padding: 5px;
}

.ref-td p,
.accenta-td p {
  margin: 0;
}

.ref-td p:nth-of-type(2),
.accenta-td p:nth-of-type(2) {
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
}

.ref-td p:nth-of-type(2) {
  visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/TemplateTables/AccentaVsRefTable/AccentaVsRefTable.css"],"names":[],"mappings":"AAAA;;EAEE,eAAe;EACf,YAAY;AACd;;AAEA;;EAEE,SAAS;AACX;;AAEA;;EAEE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".ref-td,\n.accenta-td {\n  text-align: end;\n  padding: 5px;\n}\n\n.ref-td p,\n.accenta-td p {\n  margin: 0;\n}\n\n.ref-td p:nth-of-type(2),\n.accenta-td p:nth-of-type(2) {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 15px;\n}\n\n.ref-td p:nth-of-type(2) {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
