import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { COMPUTE_TYPE } from '../../../../../../../server/constants';
import { getParamsConstraints } from '../../../../../../../server/models/design/calculationData.model';
import { getSimuSchema } from '../../../../../../../server/validation/calculationData.validation';
import { fetchResultsForNextCompute } from '../../../../../api/project.api';
import ResultSelect from '../../../../../components/ResultSelect/ResultSelect';
import AjvContext from '../../../../../contexts/AjvContext';
import PopupContext from '../../../../../contexts/PopupContext';
import {
  getDefaultSimuCalculationData,
  getSimuCalculationDataByResult,
  isCalculationDataParamPresent,
  validateSstParams
} from '../../../../../utils/calculationData.utils';
import {
  isArrNullOrEmpty,
  isObjNullOrEmpty
} from '../../../../../utils/data.utils';
import { IS_DEV } from '../../../../../utils/env.utils';
import { getProjectIndex } from '../../../../../utils/project.utils';
import CalculationForm from '../components/CalculationForm/CalculationForm';
import './SimulationForm.css';
import SimuGeneralSection from './sections/SimuGeneralSection';
import SimuGoalsSection from './sections/SimuGoalsSection/SimuGoalsSection';
import SimuSizingSection from './sections/SimuSizingSection/SimuSizingSection';

const SimulationForm = ({ project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { ajv } = useContext(AjvContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [calculationData, setCalculationData] = useState();
  const [groupedResults, setGroupedResults] = useState([]);
  const [selectedResultIndexes, setSelectedResultIndexes] = useState([0, 0]);
  //#endregion

  //#region [methods]
  const refreshResults = async () => {
    try {
      const groups = await fetchResultsForNextCompute(
        projectId,
        COMPUTE_TYPE.SIMU
      );
      setGroupedResults(() => groups);
      if (selectedResultIndexes[0] !== 0 || selectedResultIndexes[1] !== 0) {
        setSelectedResultIndexes(() => [0, 0]);
      }
      const lastResult = groups[0]?.results[0];
      if (!lastResult && calculationData) return;
      if (lastResult) {
        const projectAndChildren = [project, ...project.children];
        const data = getSimuCalculationDataByResult(
          projectAndChildren[groups[0].descriptionIndex],
          groups[0].descriptionIndex,
          lastResult
        );
        setCalculationData(() => data);
      } else {
        setCalculationData(() => getDefaultSimuCalculationData(project, 0));
      }
    } catch (err) {
      throw err;
    }
  };

  const handleSelectedProjectChange = (index) => {
    const projectAndChildren = [project, ...project.children];
    const selectedProject = projectAndChildren[index];
    const selectedResult =
      groupedResults[selectedResultIndexes[0]]?.results[
        selectedResultIndexes[1]
      ];
    if (selectedResult) {
      const data = getSimuCalculationDataByResult(
        selectedProject,
        index,
        selectedResult
      );
      setCalculationData(() => data);
    } else {
      setCalculationData(() =>
        getDefaultSimuCalculationData(selectedProject, index)
      );
    }
  };

  const handleResultChange = async (indexes) => {
    try {
      setSelectedResultIndexes(() => indexes);
      const selectedResult = groupedResults[indexes[0]].results[indexes[1]];
      let projectIndex = getProjectIndex(project, selectedResult.ProjectID);
      if (projectIndex === -1) projectIndex = 0;
      const projectAndChildren = [project, ...project.children];
      const data = getSimuCalculationDataByResult(
        projectAndChildren[projectIndex],
        projectIndex,
        selectedResult
      );
      setCalculationData(() => data);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleInpConstraintChange = (param, value) => {
    setCalculationData((data) => {
      data.constraints.inp[param.key] = value;
      return { ...data };
    });
  };

  const handleParamsConstraintChange = (param, value) => {
    setCalculationData((data) => {
      data.constraints.params[param.key] = value;
      return { ...data };
    });
  };

  const handleSstConstraintChange = (param, value, sstIndex) => {
    setCalculationData((data) => {
      data.constraints.params[param.key][sstIndex] = value;
      return { ...data };
    });
  };

  const handleBtesLengthValueChange = (value) => {
    const constraints = getParamsConstraints();
    setCalculationData((data) => {
      data.constraints.params.BtesLength = value;
      if (
        isCalculationDataParamPresent(constraints.QItesMax.key, calculationData)
      ) {
        data.constraints.params.QItesMax = 0;
      }
      return { ...data };
    });
  };

  const handleSTSurfaceValueChange = (value) => {
    const constraints = getParamsConstraints();
    setCalculationData((data) => {
      data.constraints.params.SolarThermalSurface = value;
      if (
        isCalculationDataParamPresent(
          constraints.KiloWattCretePV.key,
          calculationData
        )
      ) {
        data.constraints.params.KiloWattCretePV = 0;
      }
      return { ...data };
    });
  };

  const handleHpgEnabledCheck = (evt) => {
    const { checked } = evt.target;
    setCalculationData((data) => {
      data.constraints.inp.LbHpgPartTargetEnabled = checked;
      if (!checked) data.constraints.inp.InitConstraintHpg = 0;
      return { ...data };
    });
  };
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        await refreshResults();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [render]
  if (isObjNullOrEmpty(calculationData)) return null;
  const { InitYearsSimulations } = calculationData.constraints.inp;
  const projectAndChildren = [project, ...project.children];
  const selectedProject =
    projectAndChildren[calculationData.selectedDescriptionsIndexes[0]];
  const schema = getSimuSchema(InitYearsSimulations, selectedProject);
  const validate = ajv.compile(schema);
  validate(calculationData);
  const { errors: sstParamsErrors } = validateSstParams(
    i18n,
    selectedProject,
    calculationData
  );
  const isFormValid = !validate.errors && !sstParamsErrors;
  if (IS_DEV && !isFormValid) {
    if (validate.errors) console.log(validate.errors);
    if (sstParamsErrors) console.log(sstParamsErrors);
  }
  return (
    <CalculationForm
      project={project}
      calculationData={calculationData}
      isFormValid={isFormValid}
      refreshResults={refreshResults}
      className='simulation-form'
    >
      {!isArrNullOrEmpty(groupedResults) && (
        <ResultSelect
          onResultChange={handleResultChange}
          groupedResults={groupedResults}
          selectedResultIndexes={selectedResultIndexes}
        />
      )}
      <SimuGeneralSection
        project={project}
        calculationData={calculationData}
        onConstraintChange={handleInpConstraintChange}
        onSelectedProjectChange={handleSelectedProjectChange}
      />
      <SimuSizingSection
        project={project}
        calculationData={calculationData}
        selectedProject={selectedProject}
        onConstraintChange={handleParamsConstraintChange}
        onSstConstraintChange={handleSstConstraintChange}
        onBtesLengthValueChange={handleBtesLengthValueChange}
        onSTSurfaceValueChange={handleSTSurfaceValueChange}
      />
      <SimuGoalsSection
        calculationData={calculationData}
        onConstraintChange={handleInpConstraintChange}
        onHpgEnabledCheck={handleHpgEnabledCheck}
      />
    </CalculationForm>
  );
  //#endregion
};

export default SimulationForm;
