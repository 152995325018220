import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { HP_CE } from '../../../../../../../../server/constants';
import { getHpCollectorsEquipmentParams } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';

import FormSelect from '../../../../../../components/Form/FormSelect';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { getSortedNeeds } from '../../../../../../utils/config.utils';
import { isNull } from '../../../../../../utils/data.utils';
import { getHpUpperModuleName } from '../../../../../../utils/modules.utils';
import NeedBadge from '../components/NeedBadge';

const HpsCollectorsEquipmentSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const checkForEquipmentWarning = (checked, defaultValue) =>
    isNull(defaultValue) ? false : defaultValue !== checked;

  const handleEquipmentChange = (evt, hpId) => {
    try {
      const { services, heatpumps } = config.ConfigsSst[0].Data;
      const { list } = heatpumps;
      const { checked, value } = evt.target;
      const index = list.findIndex((hp) => hp.id === hpId);
      list[index].collectorsEquipment[value] = checked;
      // on met à jour le nom du module avec les nouveaux équipements
      list[index].upperModule.name = getHpUpperModuleName(
        list[index],
        services
      );
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleUpperTappingChange = (value, param, hpId) => {
    try {
      const { services, heatpumps } = config.ConfigsSst[0].Data;
      const { list } = heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      list[index].collectorsEquipment[param.key] = value;
      // on met à jour le nom du module avec les nouveaux équipements
      list[index].upperModule.name = getHpUpperModuleName(
        list[index],
        services
      );
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  const columns = !config.IsModular
    ? Object.values(HP_CE).filter(
        (equipment) => equipment === HP_CE.VDC || equipment === HP_CE.VDE
      )
    : Object.values(HP_CE);
  return (
    <Section
      title={
        config.IsModular
          ? i18n._('config.hps.hydraulic.upperModules.collectors.equipment')
          : i18n._('config.hps.hydraulic.collectors.equipment')
      }
      level={2}
      open
    >
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.hps.position')}</th>
              <th>{i18n._('config.hps.model')}</th>
              <th>{i18n._('config.hps.type')}</th>
              <th>{i18n._('config.hps.needs')}</th>
              {config.IsModular && <th>{i18n._('config.hps.upperModule')}</th>}
              {columns.map((colName) => (
                <th key={'th_ce_' + colName}>
                  {i18n._(`hydraulicConfig.table.th.${colName}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list
              .sort((hp1, hp2) => hp1.position - hp2.position)
              .map((hp, index) => {
                const sortedNeeds = getSortedNeeds(hp.needs);
                const params = getHpCollectorsEquipmentParams(
                  hp,
                  config.IsModular
                );
                return (
                  <tr key={'row_ce_' + index}>
                    <td>{hp.position}</td>
                    <td>{hp.model}</td>
                    <td>{i18n._(`heatpump.type.${hp.type}`)}</td>
                    <td>
                      <div className='equipment-table-badges'>
                        {sortedNeeds.map((need) => (
                          <NeedBadge
                            key={'badge_' + index + need}
                            need={need}
                          />
                        ))}
                      </div>
                    </td>
                    {config.IsModular && <td>{hp.upperModule.name}</td>}
                    {Object.values(params).map((param) => {
                      const checked = hp.collectorsEquipment?.[param.key];
                      const warning = checkForEquipmentWarning(
                        checked,
                        param.default
                      );
                      const checkboxId = 'hp_ce_' + index + param.key;
                      return param.key === HP_CE.VDE ||
                        param.key === HP_CE.VDC ? (
                        // VDE ET VDC
                        <td key={'col_ce_' + param.key}>
                          <div
                            data-tooltip-content={
                              warning
                                ? checked
                                  ? i18n._('equipment.notChecked.warning')
                                  : i18n._('equipment.checked.warning')
                                : null
                            }
                            data-tooltip-id={'error_' + checkboxId}
                            style={{ width: 'fit-content' }}
                          >
                            <Form.Check
                              type='checkbox'
                              label={i18n._(
                                `hydraulicConfig.table.td.${param.key}`,
                                {
                                  position: hp.position
                                }
                              )}
                              value={param.key}
                              onChange={(evt) =>
                                handleEquipmentChange(evt, hp.id)
                              }
                              checked={!!checked}
                              className={warning ? 'warning' : ''}
                              id={'hps_ce_' + param.key}
                            />
                            {warning &&
                              createPortal(
                                <Tooltip
                                  id={'error_' + checkboxId}
                                  place='bottom'
                                  className='error-tooltip'
                                  arrowColor='#f04460'
                                  opacity={1}
                                />,
                                document.body
                              )}
                          </div>
                        </td>
                      ) : (
                        // PIQUAGES HAUT
                        <td key={'col_ce_' + param.key}>
                          <FormSelect
                            param={param}
                            value={hp.collectorsEquipment[param.key]}
                            onChange={(value, event) =>
                              handleUpperTappingChange(value, param, hp.id)
                            }
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //#endregion
};

export default HpsCollectorsEquipmentSection;
