'use strict';
import { h, useContext } from './RR.js';
import {
  Junction,
  Pump,
  Valve,
  Car,
  Tap,
  Et,
  Exch,
  Translate,
  Rotate,
  Pipe,
  Pr,
  SchemaContext,
  CRect,
  CText,
  presentInScope
} from '../components/MiscComponents.js';
import { defaultItemWidth, Spread } from './Utils.js';
import { blockname } from './blocknames.js';

// TODO Cxx_syst_Ht_NbBallon(Finisseur)?
// TODO Cxx_syst_Pr[123]00[as]_NumeroBallon

const addunit = (x, u) => (x ? Math.round(x) + ' ' + u : '');

/** Gas is a svg group with animateTransform scale  */
function Gas({ children, ...attrs }) {
  return h(
    'g',
    attrs,
    h('g', null, [
      ...children
      //h('animateTransform',{attributeName:'transform',attributeType:'XML','type':'scale',from:0.2,to:1,dur:'1s'})
    ])
  );
}

export function FroidPrimairePlaceHolder(props) {
  const { on, gc, width, position } = props,
    xmax = width * 0.5;
  return h(
    'g',
    { transform: `translate(${xmax} ${100 + (position - 1) * 150})` },
    [
      // BallonMixte
      h(Pipe, {
        d: `M ${xmax} -15 L ${xmax} -25 L ${xmax + 25} -25`,
        stroke: on || gc ? 'blue' : 'black'
      }),
      h(Pipe, {
        d: `M ${xmax + 45} 25 L ${xmax + 30} 25 M ${
          xmax + 20
        } 25 L ${xmax} 25 L ${xmax} 15`,
        stroke: on ? '#0aa' : 'black'
      }),
      [-15, 15].map((y) => h(Junction, { x: xmax, y })),
      h(CText, { x: xmax, style: 'stroke-width:1' }, 'FP')
    ]
  );
}

export function FroidPrimaireGauche(props) {
  const { scope, on, svcOn, xV700, width, showAll, showBlockNames } = props;
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  const xmax = width * 0.5;
  const gc = svcOn.GeoCooling;
  const hpsdbf = Object.keys(scope)
      .filter(
        (k) =>
          /^Cxx_syst_Hp0\d_ConnectDirectBallonFroid$/i.test(k) &&
          scope[k] > 0 &&
          scope[k.substr(0, 13) + '_Presente'] == 1
      )
      .map((k) => k.substr(9, 4)),
    dbf = hpsdbf.length > 0,
    pacsModeFroid = Object.keys(scope)
      .filter(
        (k) =>
          /^Pxx_gene_Hp0\d_mode$/i.test(k) && Math.floor(scope[k] / 100) == 2
      )
      .map((k) => k.substr(9, 4)),
    alldbf = pacsModeFroid.reduce(
      (acc, hp) => acc && scope[`Cxx_syst_${hp}_ConnectDirectBallonFroid`] > 0,
      true
    ),
    anydbf = pacsModeFroid.reduce(
      (acc, hp) => acc || scope[`Cxx_syst_${hp}_ConnectDirectBallonFroid`] > 0,
      false
    ),
    position = scope[`Cxx_syst_PositionFroid`],
    xPu220 = xmax * -1.05,
    xPr220 = xmax * -0.85,
    xB = xmax * -0.8,
    xEt200 = xB + 100;

  if (!scope[`Cxx_syst_serviceFroid`] || !scope[`Cxx_syst_PositionFroid`]) '';

  return h(
    Gas,
    { transform: `translate(${xmax} ${100 + (position - 1) * 150})` },
    [
      h('rect', {
        x: -xmax,
        y: -100,
        width: width,
        height: 150,
        className: (showAll ? ' showAll ' : '') + 'dotted'
      }),
      showBlockNames
        ? h(
            'text',
            {
              'dominant-baseline': 'text-bottom',
              'text-anchor': 'middle',
              stroke: '#800',
              y: -85,
              className: 'block-label'
            },
            blockname(scope, 'schema_Cd_BlockName', 'Froid')
          )
        : '',

      h('g', { className: 'hline1', transform: `translate(0 -25)` }, [
        h(Pipe, {
          name: 'LH1',
          d: `M ${-xmax - 25} 0 L ${xB + 10} 0`,
          stroke: on || gc ? 'blue' : 'black'
        }),
        h(Pipe, {
          name: 'MH1',
          d: `M ${xB + 60} 0 L ${xB + 75} 0`,
          stroke: on || gc ? 'blue' : 'black'
        }),
        h(Pipe, {
          name: 'RH1',
          d: `M ${xB + 75} 0 L ${xmax + 25} 0`,
          stroke: (on && !alldbf) || gc ? 'blue' : 'black'
        }),

        ...[[xPr220, 'Pr220']].map(Pr(scope, showAll)),
        !present(`Pu220`)
          ? ''
          : h(Pump, {
              scope,
              label: 'Pu220',
              double: scope[`Cxx_regu_Pu220_Double`],
              rotate: 180,
              x: xPu220,
              on: on || gc
            })
      ]),
      h('g', { className: 'hline2', transform: `translate(0 25)` }, [
        h(Junction, { x: 245 }),
        h(Pipe, {
          name: 'RH24',
          d: `M 245 0 h -15 m -10 0 h -25 h ${xV700 + 5}`,
          stroke: on && !alldbf ? '#0aa' : 'black'
        }),
        h(Pipe, {
          name: 'RH23',
          d: `M ${200 + xV700} 0 L ${xB + 75} 0`,
          stroke: (on && !alldbf) || gc ? '#0aa' : 'black'
        }),
        h(Pipe, {
          name: 'RH22',
          d: `M ${xB + 75} 0 L ${xB + 60} 0`,
          stroke: on || gc ? '#0aa' : 'black'
        }),
        h(Pipe, {
          name: 'LH2',
          d: `M ${xB + 10} 0 L ${-xmax - 25} 0`,
          stroke: on || gc ? '#0aa' : 'black'
        }),
        h(Car, { rotate: 180, x: xmax * -0.1 }),
        ...[[xPr220, 'Pr221']].map(Pr(scope, showAll, true)),
        !present(`V200`)
          ? ''
          : h(Valve, {
              label: 'V200',
              rotate: -90,
              x: xmax * 0.9,
              on: on && !alldbf
            }),
        !present(`V700`)
          ? ''
          : h('g', { transform: `translate(160 0)` }, [
              h(Junction),
              h(Pipe, { d: `M 0 0 v 25`, stroke: gc ? '#0aa' : 'black' }),
              h(Junction, { y: 25 }),
              h(CText, { y: 37, style: 'stroke-width:.8' }, 'G')
            ]),
        h(Ballon, {
          scope,
          probes: ['Pr200a', 'Pr200s'],
          cold: true,
          x: xB - 12.5,
          y: -25,
          fill: 'url(#GradCold)'
        }),
        !present(`Et200`)
          ? ''
          : h(Et, { label: 'Et200', x: xEt200, fill: 'url(#GradCold)' }),
        !present(`S220`) ? '' : h(Tap, { label: 'S220', x: xmax * -1.05 })
      ]),
      //spread !present(`Ex200`) ? '' :
      //spread h('use',{href:'#exch',x:xmax*.3}),
      showAll || dbf
        ? h('g', { transform: `translate(${xmax * -0.55 + 75} 0)` }, [
            h(Pipe, { d: `M 0 -15 v -10`, stroke: anydbf ? 'red' : 'black' }),
            h(Pipe, { d: `M 0 15 v 10`, stroke: anydbf ? '#0aa' : 'black' }),
            h(CText, { y: 1, style: 'stroke-width:1' }, 'F'),
            [-15, 15].map((y) => h(Junction, { y })),
            [-25, 25].map((y) => h(Junction, { y }))
          ])
        : '',

      h(Spread, {
        x2: 0,
        x1: xmax - 40,
        y1: -25,
        y2: 25,
        scope,
        showAll,
        on,
        items: [
          ['Pu200', 'S200'],
          ['Pr213', 'Pr214'],
          ['Ex200'],
          ['Pr211', 'Pr212']
        ].reverse(),
        Pu200props: { on: (on && !alldbf) || gc }
      }),
      //Regime et Puissance
      [
        [-6, scope[`schema_Cd_Regime`]],
        [6, addunit(scope[`schema_Cd_Puissance`], 'kW')]
      ].map(([y, t]) => [h(CText, { x: 0, y, bgopacity: 0.8 }, t)])
    ]
  );
}

export function positionFroidDroite(scope) {
  if (scope[`Cxx_syst_serviceFroid`] == 0) return 0;
  const occupiedPositionC = [
    scope[`Cxx_syst_ServiceChauffage`] ? scope['Cxx_syst_PositionChaud'] : 0,
    scope[`Cxx_syst_serviceECS`] ? scope['Cxx_syst_PositionEcs'] : 0,
    scope[`Cxx_syst_ServicePiscine`] ? scope['Cxx_syst_PositionPiscine'] : 0,
    scope['Cxx_syst_PositionEcs'] == scope['Cxx_syst_PositionChaud']
      ? scope['Cxx_syst_PositionChaud'] + 1
      : 0,
    scope['Cxx_syst_PositionPiscine'] == scope['Cxx_syst_PositionChaud']
      ? scope['Cxx_syst_PositionChaud'] + 1
      : 0
  ].filter((p) => p > 0);
  const position =
    /* au dessus de chauffage si possible sinon position libre*/
    scope[`Cxx_syst_ServiceChauffage`] &&
    scope['Cxx_syst_PositionChaud'] > 1 &&
    occupiedPositionC.indexOf(scope['Cxx_syst_PositionChaud'] - 1) < 0
      ? scope['Cxx_syst_PositionChaud'] - 1
      : [1, 2, 3, 4, 5, 6, 7, 8, 9].filter(
          (p) => occupiedPositionC.indexOf(p) < 0
        )[0];
  return position;
}

export function FroidPrimaireDroite(props) {
  const {
      scope,
      _on,
      svcOn,
      xV700,
      width,
      showAll,
      showBlockNames,
      position,
      BMSmoothConnection
    } = props,
    present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0,
    xmax = width * 0.5,
    on =
      svcOn.Froid ||
      Object.entries(scope).filter(
        ([k, v]) =>
          /^Pxx_gene_Hp0\d_mode$/i.test(k) && Math.floor(scope[k] / 100) == 2
      ).length == 1,
    gc = svcOn.GeoCooling,
    hpsdbf = Object.keys(scope)
      .filter(
        (k) =>
          /^Cxx_syst_Hp0\d_ConnectDirectBallonFroid$/i.test(k) &&
          scope[k] > 0 &&
          scope[k.substr(0, 13) + '_Presente'] == 1
      )
      .map((k) => k.substr(9, 4)),
    dbf = hpsdbf.length > 0,
    pacsModeFroid = Object.keys(scope)
      .filter(
        (k) =>
          /^Pxx_gene_Hp0\d_mode$/i.test(k) && Math.floor(scope[k] / 100) == 2
      )
      .map((k) => k.substr(9, 4)),
    alldbf = pacsModeFroid.reduce(
      (acc, hp) => acc && scope[`Cxx_syst_${hp}_ConnectDirectBallonFroid`] > 0,
      true
    ),
    anydbf = pacsModeFroid.reduce(
      (acc, hp) => acc || scope[`Cxx_syst_${hp}_ConnectDirectBallonFroid`] > 0,
      false
    ),
    BM = scope[`Cxx_syst_BallonMixte_ChaudFroid`] > 0,
    x7 = -xmax + 40,
    xDBF = xmax * -0.2,
    xEt200 = xDBF + (dbf ? 35 : 25),
    xB = xEt200 + 25,
    xPr220 = xB + 85,
    xPu220 = xPr220 + 40;
  if (
    !scope[`Cxx_syst_serviceFroid`] ||
    !scope[`Cxx_syst_PositionFroid`] ||
    BM != 1
  )
    '';

  return h(
    Gas,
    { transform: `translate(${xmax - 75 + 10} ${100 + (position - 1) * 150})` },
    [
      h('rect', {
        x: -xmax,
        y: -100,
        width: BM ? xB - -xmax : width,
        height: 150,
        className: (showAll ? ' showAll ' : '') + 'dotted'
      }),
      showBlockNames
        ? h(
            CText,
            {
              x: -xmax * 0.5 + (BM ? xB : xmax) * 0.5,
              stroke: '#800',
              y: -75,
              className: 'block-label m'
            },
            blockname(scope, 'schema_Cd_BlockName', 'Froid')
          )
        : '',
      h(CText, { x: -xmax, style: 'stroke-width:1' }, 'FP'),
      h('g', { className: 'hline1', transform: `translate(0 -25)` }, [
        h(Pipe, {
          name: 'RH1',
          d: `M ${BM ? xDBF : xB} 0 L ${xDBF} 0`,
          stroke: on || gc ? 'blue' : 'black'
        }),
        h(Pipe, {
          name: 'MH1',
          d: `M ${xDBF} 0 L ${-xmax} 0`,
          stroke: (on && !alldbf) || gc ? 'blue' : 'black'
        }),
        [-xmax].map((x) => h(Junction, { x }))
      ]),
      h('g', { className: 'hline2', transform: `translate(0 25)` }, [
        h(Pipe, {
          name: 'LH2',
          d: `M ${-xmax} 0 L ${x7} 0`,
          stroke: on && !alldbf ? '#0aa' : 'black'
        }),
        h(Pipe, {
          name: 'MH2',
          d: `M ${x7} 0 L ${xDBF} 0`,
          stroke: (on && !alldbf) || gc ? '#0aa' : 'black'
        }),
        h(Pipe, {
          name: 'RH2',
          d: `M ${xDBF} 0 L ${BM ? xDBF : xB} 0`,
          stroke: on || gc ? '#0aa' : 'black'
        }),
        present(`V200`)
          ? h(Valve, {
              x: -xmax * 0.66 + x7 * 0.33,
              label: 'V200',
              rotate: 90,
              on: on && !alldbf
            })
          : '',
        present(`V700`)
          ? [
              h(Pipe, {
                name: 'V700',
                d: `M ${x7} 25 L ${x7} 0`,
                stroke: gc ? '#0aa' : 'black'
              }),
              //h(Valve,{x:x7,y:27,label:'V700',rotate:180}),
              h(Junction, { x: x7 }),
              h(Junction, { x: x7, y: 25 }),
              h(CText, { x: x7, y: 35, style: 'stroke-width:.8' }, 'G')
            ]
          : '',
        h(Junction, { x: -xmax })
      ]),
      dbf || (BM && !BMSmoothConnection)
        ? h('g', { transform: `translate(${xDBF} 0)` }, [
            h(Pipe, {
              d: `M 0 -15 v -10`,
              stroke: anydbf || ((on || gc) && BM) ? 'blue' : 'black'
            }),
            h(Pipe, {
              d: `M 0 15 v 10`,
              stroke: anydbf || ((on || gc) && BM) ? '#0aa' : 'black'
            }),
            h(CText, { y: 1, style: 'stroke-width:1' }, 'F'),
            [-15, 15].map((y) => h(Junction, { y })),
            [-25, 25].map((y) => h(Junction, { y }))
          ])
        : '',

      h(Spread, {
        x1: x7 + 5,
        x2: xDBF,
        y1: -25,
        y2: 25,
        scope,
        showAll,
        on,
        debug: true,
        items: [
          ['Pr211', 'Pr212'],
          ['Ex200'],
          ['Pr213', 'Pr214'],
          ['Pu200', 'S200']
        ],
        Pu200props: { rotate: 0, on: (on && !alldbf) || gc }
      }),
      BM
        ? [
            h(Translate, { y: -25 }, [
              h(Pipe, {
                d: `M ${xDBF + 3} 0 H ${xDBF + 50}`,
                stroke: on || gc ? 'blue' : 'black'
              })
            ]),
            h(Translate, { y: 25 }, [
              h(Pipe, {
                d: `M ${xDBF + 50} 0 H ${xDBF + 3}`,
                stroke: on || gc ? '#0aa' : 'black'
              }),
              present(`Et200`) ? h(Et, { x: xEt200, label: 'Et200' }) : ''
            ])
          ]
        : [
            h(Translate, { y: -25 }, [
              h(Pipe, {
                d: `M ${xmax} 0 L ${xB + 50} 0`,
                stroke: on || gc ? 'blue' : 'black'
              }),

              h(Ballon, {
                scope,
                probes: ['Pr200a', 'Pr200s'],
                fill: 'url(#GradCold)',
                cold: true,
                x: xB - 25,
                y: 25
              }),
              Pr(scope, showAll, false)([xPr220, 'Pr220']),
              !present(`Pu220`)
                ? ''
                : h(Pump, {
                    scope,
                    label: 'Pu220',
                    rotate: 0,
                    double: scope[`Cxx_regu_Pu220_Double`],
                    x: xPu220,
                    on: on || gc
                  }),
              h(Junction, { x: xmax })
            ]),
            h(Translate, { y: 25 }, [
              h(Pipe, {
                d: `M ${xB + 50} 0 L ${xmax} 0`,
                stroke: on || gc ? '#0aa' : 'black'
              }),
              Pr(scope, showAll, true)([xPr220, 'Pr221']),
              h(Junction, { x: xmax }),
              present(`Et200`) ? h(Et, { x: xEt200, label: 'Et200' }) : ''
            ]),
            //Regime et Puissance
            [
              [-6, scope[`schema_Cd_Regime`]],
              [6, addunit(scope[`schema_Cd_Puissance`], 'kW')]
            ].map(([y, t]) => [
              h(CText, { x: BM ? xEt200 + 25 : xmax, y, bgopacity: 0.8 }, t)
            ])
          ]
    ]
  );
}

export function ChaudPrimaire(props) {
  let {
    x = 25,
    scope,
    on,
    svcOn,
    width,
    showAll,
    showBlockNames,
    BMSmoothConnection
  } = props;
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0,
    onShared =
      on ||
      (scope[`Cxx_syst_PositionChaud`] == scope[`Cxx_syst_PositionEcs`] &&
        svcOn.ECS) ||
      (scope[`Cxx_syst_PositionChaud`] == scope[`Cxx_syst_PositionPiscine`] &&
        svcOn.Piscine),
    BM = scope[`Cxx_syst_BallonMixte_ChaudFroid`] > 0,
    position = scope[`Cxx_syst_PositionChaud`],
    xmax = width / 2,
    xB = xmax + (BM ? 0 : 10),
    xR = BM ? xB : xmax * 2.5,
    xPr120 = xB + 80,
    xPu120 = xPr120 + 25,
    xVm120 = xPu120 + 20;
  if (!scope[`Cxx_syst_PositionChaud`]) return '';
  return h(
    Gas,
    { transform: `translate(${x} ${100 + (position - 1) * 150})` },
    [
      h('rect', {
        x: -xmax,
        y: -100,
        width: xmax + xR,
        height: 150,
        className: (showAll ? ' showAll ' : '') + 'dotted'
      }),
      showBlockNames
        ? h(
            CText,
            { stroke: '#800', y: -75, className: 'block-label m' },
            blockname(scope, 'schema_Ht_BlockName', 'Chauffage')
          )
        : '',
      //h('path',{d:`M0 -65 v 65`,strokeWidth:4}),
      h('g', { className: 'hline1', transform: `translate(0 -25)` }, [
        h(Junction, { x: -xmax - 25 }),

        h(Pipe, {
          name: 'LH1',
          d: `M ${-xmax - 25} 0 H ${-xmax + 25}`,
          stroke: onShared ? 'orange' : 'black'
        }),
        h(Pipe, {
          name: 'MH1',
          d: `M ${-xmax + 25} 0 H ${xB}`,
          stroke: on ? 'orange' : 'black'
        })
        //Appoint chaud/Car
      ]),
      h('g', { className: 'hline2', transform: `translate(0 25)` }, [
        h(Pipe, {
          name: 'MH2',
          d: `M ${xB} 0 H ${-xmax}`,
          stroke: on ? 'blue' : 'black'
        }),
        h(Pipe, {
          name: 'LH2',
          d: `M ${-xmax + 5} 0 h -25  m -10 0 h -15`,
          stroke: onShared ? 'blue' : 'black'
        })
      ]),
      //showAll ? h('text',{y:-70,stroke:'#800','text-anchor':'middle'},'show all'):'',

      h(Spread, {
        x1: xmax * -0.9,
        x2: xmax,
        y1: -25,
        y2: 25,
        scope,
        showAll,
        on,
        debug: true,
        itemWidth: (item) =>
          /^(Pu|Car)/.test(item)
            ? 15
            : /^Ex/.test(item)
            ? 25
            : defaultItemWidth(item),
        items: [
          ['V100', 'S100'],
          ['Et100', ''],
          [present('Ex100') ? 'Car' : '', ''],
          ['Pr112', 'Pr111'],
          ['Ex100', ''],
          ['Pr114', 'Pr113'],
          ['', present('Pu100') ? 'Pu100' : ''],
          ['', 'S120']
        ]
      }),
      BM
        ? [
            // BallonMixte_ChaudFroid
            // Connection C
            BMSmoothConnection
              ? [
                  // h(Pipe,{d:`M ${xB+10} -25 H ${xB+60}`,stroke: on ? 'orange' : 'black'}),
                  // h(Pipe,{d:`M ${xB+60} 25 H ${xB+10}`,stroke: on ? 'blue' : 'black'}),
                ]
              : h(Translate, { x: xB + 25 }, [
                  h(Pipe, {
                    d: `M -25,-25 L 0 -25 L 0 -15`,
                    stroke: on ? 'orange' : 'black'
                  }),
                  h(Pipe, {
                    d: `M 0 15 L 0 25 L -25 25`,
                    stroke: on ? 'blue' : 'black'
                  }),
                  [-25, -15, 15, 25].map((y) => h(Junction, { y })),
                  h(CText, { style: 'stroke-width: 1' }, 'C')
                ])
          ]
        : [
            // Ballon Chaud
            h(Ballon, {
              scope,
              probes: ['Pr100a', 'Pr100s'],
              x: xB - 12.5 - 10,
              fill: 'url(#GradChauffage)',
              showAll
            }),
            h(Translate, { y: -25 }, [
              h(Pipe, {
                name: 'RH1',
                d: `M ${xB + 50} 0 L ${xmax * 2.5} 0`,
                stroke: on ? 'orange' : 'black'
              }),
              Pr(scope, showAll)([xPr120, 'Pr121']),
              present(`Vm120`)
                ? [
                    h(Valve, {
                      label: 'Vm120',
                      ways: 3,
                      rotate: -90,
                      x: xVm120,
                      on
                    }),
                    h(Pipe, {
                      d: `M ${xVm120} 50 v -50`,
                      stroke: false ? 'red' : 'black'
                    })
                  ]
                : ''
            ]),
            h(Translate, { y: 25 }, [
              h(Pipe, {
                name: 'RH2',
                d: `M ${xmax * 2.5} 0 L ${xB + 50} 0`,
                stroke: on ? 'blue' : 'black'
              }),
              Pr(scope, showAll, true)([xPr120, 'Pr120']),
              !present(`Pu120`)
                ? ''
                : h(Pump, {
                    scope,
                    label: 'Pu120',
                    double: scope[`Cxx_regu_Pu120_Double`],
                    x: xPu120,
                    on
                  })
            ]),
            //Regime et Puissance
            [
              [-6, scope[`schema_Ht_Regime`]],
              [6, addunit(scope[`schema_Ht_Puissance`], 'kW')]
            ].map(([y, t]) => [
              h(
                CText,
                { x: BM ? xmax * 0.9 : xmax * 2.5, y, bgopacity: 0.8 },
                t
              )
            ])
          ]
    ]
  );
}

function SmoothConnection(props) {
  const { x1, x2, y1, y2, dy1, dy2, stroke1, stroke2, rev } = props;
  const xm = (x1 + x2) * 0.5;
  const esse = ({ x1, x2, y1, y2, stroke }) => {
    const ym = (y1 + y2) * 0.5,
      d = `M ${x1} ${y1} C ${x2} ${y1} ${x1} ${y2} ${x2} ${y2}`,
      _ = '';
    return h(Pipe, { d, stroke });
  };
  return h('g', {}, [
    //h(Pipe,{d:`M ${x1} ${y1-dy1} L ${x2} ${y2-dy2}`,stroke:stroke1}),
    esse({ x1, x2, y1: y1 - dy1, y2: y2 - dy2, stroke: stroke1 }),
    esse({ x1: x2, x2: x1, y1: y2 + dy2, y2: y1 + dy1, stroke: stroke2 })
  ]);
}

export function BallonMixte_ChaudFroid(props) {
  const {
      x,
      y,
      width,
      scope,
      svcOn,
      showAll,
      posFroidDroite,
      BMSmoothConnection
    } = Object.assign({ x: 0, y: 0 }, props),
    { showBlockNames } = useContext(SchemaContext),
    present = presentInScope(scope, showAll),
    BM = scope[`Cxx_syst_BallonMixte_ChaudFroid`] > 0;
  if (!BM) return '';
  const itemsC = [
      //  ['V100','S100']//,['Pr112','Pr111'],['Ex100'],['Pr114','Pr113'],['','Pu100'],['','S120']
    ]
      .map((a) => a.map((item) => (item > '' && present(item) ? item : '')))
      .filter((a) => a.reduce((acc, item) => acc || item != ''), false),
    itemsF = [
      //  ['S200','']
      //    ['','V200'],['Pr211','Pr212'],['Ex200'],['Pr213','Pr214'],['S200','Pu200']
    ]
      .map((a) => a.map((item) => (item > '' && present(item) ? item : '')))
      .filter((a) => a.reduce((acc, item) => acc || item != ''), false),
    itemWidth = defaultItemWidth,
    widthsC = itemsC.map((a) =>
      a.reduce((acc, _, i) => Math.max(acc, itemWidth(a[i])), 0)
    ),
    widthC = widthsC.reduce((acc, w) => acc + w, 0) * 1.5,
    widthsF = itemsF.map((a) =>
      a.reduce((acc, _, i) => Math.max(acc, itemWidth(a[i])), 0)
    ),
    widthF = widthsF.reduce((acc, w) => acc + w, 0) * 1.5,
    widthCF = Math.max(widthC, widthF),
    posChaud = scope[`Cxx_syst_PositionChaud`],
    position = posChaud + (posFroidDroite == posChaud - 1 ? -0.4 : 0),
    xmax = width * 0.5,
    xL = xmax * -1.79,
    xB = xL + widthCF + 15,
    xR = xB + xmax * 1.2 + xmax * 1.2,
    xyChaud = { x: xL - 22, y: (posChaud - position) * 150 },
    xyFroid = { x: xL - 36 + 10, y: (posFroidDroite - position) * 150 },
    BMSmoothConnection2 = BMSmoothConnection && posFroidDroite == posChaud - 1;

  const onC = svcOn.Chauffage,
    onF = svcOn.Froid || svcOn.GeoCooling;
  return !position
    ? ''
    : h(Gas, { transform: `translate(350 ${100 + (position - 1) * 150})` }, [
        h('rect', {
          x: xL,
          y: -100,
          width: xR - xL,
          height: 150,
          className: (showAll ? ' showAll ' : '') + 'dotted'
        }),
        h(
          CText,
          {
            stroke: '#800',
            x: xL * 0.5 + xR * 0.5,
            y: -90,
            className: 'block-label m'
          },
          showBlockNames
            ? blockname(scope, 'schema_BallonMixte_BlockName', 'Ballon Mixte')
            : ''
        ),

        //h(CText,xyChaud,'BranchementChaud'),
        //h(CText,xyFroid,'BranchementFroid'),
        // connexion froid
        BMSmoothConnection2
          ? h(SmoothConnection, {
              x1: xL,
              x2: xyFroid.x,
              y1: -9 - 60 + 12.5,
              y2: xyFroid.y,
              dy1: 12.5,
              dy2: 25,
              stroke1: onF ? 'blue' : 'black',
              stroke2: onF ? '#0aa' : 'black',
              rev: true
            })
          : '',
        h(Translate, { y: -9 }, [
          h('g', { className: 'F1', transform: `translate(0 -60)` }, [
            h(Pipe, {
              d: `M ${xB} 0 L ${xL} 0`,
              stroke: onF ? 'blue' : 'black'
            }),
            //spread present(`S200`) ? h(Tap,{x:xmax*-1.6,label:'S200',rotate:180}) :'',
            BMSmoothConnection2 ? '' : h(Junction, { x: -xmax * 1.79 }),
            h('use', { href: '#arrowleftblue', x: xB - 10 })
          ]),
          h('g', { className: 'F2', transform: `translate(0 -35)` }, [
            h(Pipe, {
              d: `M ${xL} 0 L ${xB} 0`,
              stroke: onF ? '#0aa' : 'black'
            }),
            BMSmoothConnection2
              ? ''
              : [
                  h(Junction, { x: xL }),
                  h(
                    'text',
                    {
                      x: xL,
                      'text-anchor': 'middle',
                      style: 'stroke-width:1',
                      y: -7
                    },
                    'F'
                  )
                ],
            h('use', { href: '#arrowrightaqua', x: xB - 10 })
          ]),
          h(Spread, {
            x1: xL,
            x2: xB - 10,
            y1: -60,
            y2: -35,
            scope,
            showAll,
            on: onF,
            items: itemsF,
            Pu200props: { rotate: 180, text: { y: 24 } }
          })
        ]),
        // connexion chaud
        //console.log({x1:xL-35,x2:xL,y1:posChaud*150-50,y2:position*150-50+9+12.5,dy1:25,dy2:12.5,stroke1:onC?'orange':'black',stroke2:onC?'blue':'black'}),
        BMSmoothConnection
          ? h(SmoothConnection, {
              x1: xyChaud.x,
              x2: xL,
              y1: xyChaud.y,
              y2: 9 + 12.5,
              dy1: 25,
              dy2: 12.5,
              stroke1: onC ? 'orange' : 'black',
              stroke2: onC ? 'blue' : 'black'
            })
          : '',
        h(Translate, { y: 9 }, [
          h('g', { className: 'C1', transform: `translate(0 0)` }, [
            h(Pipe, {
              d: `M ${xL} 0 L ${xB} 0`,
              stroke: onC ? 'orange' : 'black'
            }),
            //spread present(`V100`) ? h(Valve,{x:xmax*-1.6,label:'V100',rotate:-90}) :'',
            BMSmoothConnection ? '' : h(Junction, { x: xL }),
            h('use', { href: '#arrowrightred', x: xB - 10 })
          ]),
          h('g', { className: 'C2', transform: `translate(0 25)` }, [
            h(Pipe, {
              d: `M ${xB} 0 L ${xmax * -1.79} 0`,
              stroke: onC ? 'blue' : 'black'
            }),
            //spread present(`S100`) ? h(Tap,{x:xmax*-1.6,label:'S100'}) :'',
            BMSmoothConnection
              ? ''
              : [
                  h(Junction, { x: xL }),
                  h(
                    'text',
                    {
                      x: xL,
                      'text-anchor': 'middle',
                      style: 'stroke-width:1',
                      y: -7
                    },
                    'C'
                  )
                ],
            h('use', { href: '#arrowleftblue', x: xB - 10 })
          ]),
          h(Spread, {
            x1: xL,
            x2: xB - 10,
            y1: 0,
            y2: 25,
            scope,
            showAll,
            on: onC,
            items: itemsC
          })
        ]),
        h('g', { transform: `translate(${xB - xmax * -1.2} 0)` }, [
          h(Ballon, {
            scope,
            probes: [],
            x: xmax * -1.2 - 25,
            y: -10,
            height: 130,
            fill: 'url(#GradChauffage)'
          }),
          [
            [-25, 'Pr200'],
            [-10, 'Pr100']
          ].map(([y, probe]) => [
            h('use', {
              href: '#probe',
              transform: `translate(${xmax * -1.2} ${y}) rotate(-90)`
            }),
            h(
              'text',
              { x: xmax * -1.2 + 5, y, 'dominant-baseline': 'middle' },
              probe
            )
          ]),
          h('g', { className: 'Rtop', transform: `translate(0 -60)` }, [
            h(Pipe, {
              name: 'RH11',
              d: `M ${xmax * -1.2 + 50} 0 L ${xmax * -0.3} 0`,
              stroke: onF ? 'blue' : onC ? 'orange' : 'black',
              invertedFlow: onF
            }),
            h(Pipe, {
              name: 'RH12',
              d: `M ${xmax * -0.3} 0 L ${xmax * -0.1} 0`,
              stroke: onC ? 'orange' : 'black'
            }),
            h(Pipe, {
              name: 'RH13',
              d: `M ${xmax * -0.1} 0 L ${xmax * 1.2} 0`,
              stroke: onC ? 'orange' : onF ? 'blue' : 'black'
            }),
            present(`V01`)
              ? h(Valve, {
                  rotate: -90,
                  label: 'V01',
                  x: xmax * -0.1,
                  ways: 3,
                  on: onF || onC
                })
              : '',
            Pr(scope, showAll, false)([xmax * 0.2, 'Pr121'])
          ]),
          h('g', { className: 'Rmid', transform: `translate(0 0)` }, [
            h(Pipe, {
              name: 'RH2',
              d: `M ${xmax * 1.2} 0 L ${xmax * -0.3} 0`,
              stroke: onF || onC ? 'blue' : 'black'
            }),
            h(Pipe, {
              name: 'V020',
              d: `M ${xmax * -0.3} -60 L ${xmax * -0.3} 0`,
              stroke: onF ? 'blue' : 'black',
              invertedFlow: onF
            }),
            h(Pipe, {
              name: 'V021',
              d: `M ${xmax * -0.3} 0 L ${xmax * -0.3} 25`,
              stroke: onC ? 'blue' : 'black'
            }),
            h(Valve, {
              rotate: 180,
              label: 'V02',
              ways: 3,
              on: onF || onC,
              x: xmax * -0.3
            }),
            Pr(scope, showAll, true)([xmax * 0.1, 'Pr120']),
            present(`Et120`) ? h(Et, { x: xmax * 0.5, label: 'Et120' }) : '',
            present('Pu120')
              ? h(Pump, {
                  x: xmax * 0.9,
                  scope,
                  label: 'Pu120',
                  double: scope[`Cxx_regu_Pu120_Double`]
                })
              : ''
          ]),
          h('g', { className: 'Rbottom', transform: `translate(0 25)` }, [
            h(Pipe, {
              d: `M ${xmax * -0.1} -85 L ${xmax * -0.1} -30 M ${
                xmax * -0.1
              } -20 L ${xmax * -0.1} 0 L ${xmax * -0.3} 0`,
              stroke: onF ? 'blue' : 'black',
              invertedFlow: onF
            }),
            h(Pipe, {
              d: `M ${xmax * -0.1} 0 L  ${xmax * -0.3} 0`,
              stroke: onF ? 'blue' : 'black',
              invertedFlow: onF
            }),
            h(Pipe, {
              d: `M ${xmax * -0.3} 0 L ${xmax * -1.2 + 50} 0`,
              stroke: onF || onC ? 'blue' : 'black',
              invertedFlow: onF
            })
          ])
        ]),
        //Regime et Puissance
        [
          [-6, scope[`schema_Cd_Regime`]],
          [6, addunit(scope[`schema_Cd_Puissance`], 'kW')]
        ].map(([y, t]) => [
          h(CText, { x: -200, y: y - 90, bgopacity: 0.8 }, t)
        ]),
        [
          [-6, scope[`schema_Ht_Regime`]],
          [6, addunit(scope[`schema_Ht_Puissance`], 'kW')]
        ].map(([y, t]) => [h(CText, { x: -200, y: y + 60, bgopacity: 0.8 }, t)])
      ]);
}

export function EcsPrimaire(props) {
  const { scope, on, width, showAll, showBlockNames } = props,
    present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0,
    xmax = width * 0.5,
    onShared = on,
    position = scope[`Cxx_syst_PositionEcs`],
    posChaud =
      scope[`Cxx_syst_ServiceChauffage`] > 0
        ? scope[`Cxx_syst_PositionChaud`]
        : 0,
    SI = scope[`Cxx_syst_S310_Presente`] > 0, //SI='semi instantanée'=>ballon av exch
    xL = -xmax - 25,
    xEx300 = 0 + (SI ? 160 : 0),
    xB = xmax * (SI ? -0.1 : 1.2),
    xEt300 = xB - 10,
    x1 = xB + 12.5,
    x2 = x1 + 50,
    x3 = xmax * 2.8,
    xPu300 = SI ? xB + 110 : xmax * 0.45,
    BF = scope[`Cxx_syst_Hw_NbBallonFinisseur`] > 0;
  if ((scope['Cxx_syst_serviceECS'] || 0) == 0) {
    return [];
  }
  if ((scope['Cxx_syst_PositionEcs'] || 0) == 0) {
    return [];
  }
  return h(
    Gas,
    {
      transform: `translate(0 ${
        100 + (position - 1 + (position == posChaud ? 1 : 0)) * 150
      })`
    },
    [
      h('rect', {
        x: -xmax,
        y: -100,
        width: -xL + x3,
        height: 150,
        className: (showAll ? ' showAll ' : '') + 'dotted'
      }),
      showBlockNames
        ? h(
            'text',
            {
              'dominant-baseline': 'text-bottom',
              'text-anchor': 'middle',
              stroke: '#800',
              x: xL * 0.5 + x3 * 0.5,
              y: -85,
              className: 'block-label'
            },
            blockname(scope, 'schema_Hw_BlockName', 'ECS')
          )
        : '',
      // +' '+'TypeMontage:'+scope['Cxx_syst_Hw_TypeMontageHydraulique']

      h('g', { className: 'hline1', transform: `translate(0 -25)` }, [
        h(Junction, { x: -xmax - 25 }),
        position != posChaud
          ? h(Pipe, {
              name: 'LH1',
              d: `M ${-xmax - 25} 0 h 50`,
              stroke: onShared ? 'red' : 'black'
            })
          : '',
        position == posChaud
          ? [
              h(Junction, { name: 'LH1', x: -xmax + 25, y: -150 }),
              h(Pipe, {
                d: `M ${-xmax + 25} -150 v 45 m 0 10 v 95 h 50`,
                stroke: onShared ? 'red' : 'black'
              })
            ]
          : '',
        h(Pipe, {
          name: 'MH1',
          d: `M ${-xmax + 25} 0 L ${x1} 0`,
          stroke: on ? 'red' : 'black'
        }),
        !present(`V300`)
          ? ''
          : h(Valve, { label: 'V300', rotate: -90, x: xmax * -0.55, on }),
        ...[
          [xEx300 - 20, 'Pr312'],
          [xEx300 + 20, 'Pr314'],
          [xB + 80, 'Pr321']
        ].map(Pr(scope, showAll)),
        //AppointECS/Car
        h(Pipe, {
          name: 'RH1',
          d: `M ${x2} 0 L ${x3} 0`,
          stroke: on ? 'red' : 'black'
        })
        //Pr(scope,showAll)([xmax*1.4,'Pr312']),
      ]),

      h('g', { className: 'hline2', transform: `translate(0 25)` }, [
        position != posChaud
          ? [
              h(Junction, { x: -xmax - 45 }),
              h(Pipe, {
                name: 'RH2',
                d: `M ${-xmax + 5} 0 h -25  m -10 0 h -15`,
                stroke: onShared ? 'blue' : 'black'
              })
            ]
          : '',
        position == posChaud
          ? [
              h(Junction, { x: -xmax + 5, y: -150 }),
              h(Pipe, {
                name: 'RH2',
                d: `M ${-xmax + 5} 0 v -130 m 0 -15 v 5`,
                stroke: onShared ? 'blue' : 'black'
              })
            ]
          : '',
        h(Pipe, {
          name: 'MH2',
          d: `M ${x1} 0 L ${-xmax + 5} 0`,
          stroke: on ? 'blue' : 'black'
        }),
        ...[
          [xEx300 - 20, 'Pr311'],
          [xEx300 + 20, 'Pr313']
        ].map(Pr(scope, showAll, true)),
        !present(`Pu300`)
          ? ''
          : h(Pump, {
              scope,
              label: 'Pu300',
              rotate: 0,
              double: scope[`Cxx_regu_Pu300_Double`] > 0,
              x: xPu300,
              on
            }),
        !present(`S300`)
          ? ''
          : h(Tap, { label: 'S300', x: SI ? xmax * -0.55 : xmax * 0.7 }),
        h(Pipe, {
          name: 'RH2',
          d: `M ${x3} 0 L ${x2} 0`,
          stroke: on ? 'blue' : 'black'
        }),
        present(`S310`) ? h(Tap, { x: xB + 70, label: 'S310' }) : '',
        present('Et300') ? h(Et, { label: 'Et300', x: xEt300 }) : '',
        BF ? '' : present('Et320') ? h(Et, { label: 'Et320', x: xmax * 2 }) : []
      ]),
      showAll
        ? h(
            'text',
            { y: -70, stroke: '#800', 'text-anchor': 'middle' },
            'show all'
          )
        : '',
      !present(`Ex300`) ? '' : h(Exch, { x: xEx300, label: 'Ex300' }),
      h(Ballon, {
        scope,
        probes: ['Pr300a', 'Pr300s'],
        x: xB - 12.5,
        fill: 'url(#GradEcs)',
        showAll
      }),
      //Regime et Puissance
      [
        [-6, scope[`schema_Hw_Regime`]],
        [6, addunit(scope[`schema_Hw_Puissance`], 'kW')]
      ].map(([y, t]) => [h(CText, { x: xmax * 2, y }, t)]),

      BF ? h(EcsBallonFinisseur, { scope, on, showAll }) : []
    ]
  );

  // TODO Cxx_syst_Hw_TypeMontageHydraulique,Cxx_syst_Hw_BallonFinisseur,Cxx_syst_Hw_Mitigeur_Present,Cxx_syst_Hw_NbBallon
}

function EcsBallonFinisseur(props) {
  const { scope, showAll, on } = props,
    { showBlockNames } = useContext(SchemaContext),
    present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0,
    xL = 280 + 48,
    xR = xL + 150,
    xM = xL * 0.5 + (xR - 20) * 0.5,
    dx = 15,
    dy = 15;
  return h(
    Gas,
    {
      className: 'EcsBallonFinisseur',
      key: 'EcsBallonFinisseur',
      transform: `translate(${dx},${dy})`
    },
    [
      h(
        CText,
        { x: xM, y: -107, className: 'block-title m' },
        showBlockNames
          ? blockname(
              scope,
              'schema_HwBallonFinisseur_BlockName',
              'ECS Ballon Finiseur'
            )
          : ''
      ),
      h(Ballon, {
        scope,
        probes: ['Pr340'],
        x: xL - 73,
        fill: 'url(#GradEcs)',
        height: 100,
        y: -50,
        showAll
      }),
      h(Translate, { y: -75 }, [
        present('Gaz01')
          ? [
              h(
                Translate,
                { x: xL - 143, y: -25 },
                h('use', { href: '#chaudiere_gaz', transform: `scale(.8)` })
              ),
              h(Pipe, {
                d: `M ${xL - 110} 0 L ${xL - 50} 0`,
                stroke: on ? 'red' : 'black'
              })
            ]
          : '',
        h(Pipe, { d: `M ${xL} 0 L ${xR} 0`, stroke: on ? 'red' : 'black' })
      ]),
      h(Translate, { y: -50 }, [
        present('Gaz01') || present(`Et340`)
          ? [
              h(Pipe, {
                d: `M ${xL - 50} -5 L ${xL - 110} -5`,
                stroke: on ? 'blue' : 'black'
              }),
              present(`Et340`)
                ? h(Et, {
                    label: 'Et340',
                    dy: 20,
                    x: xL - 80,
                    y: -5,
                    labeldy: 5
                  })
                : ''
            ]
          : '',
        h(Pipe, { d: `M ${xR} 0 L ${xL} 0`, stroke: on ? 'red' : 'black' })
      ]),
      h(SmoothConnection, {
        x1: xL - 50 - dx,
        x2: xL - 50,
        y1: -dy,
        y2: -dy,
        dy1: 25,
        dy2: 25,
        stroke1: on ? 'red' : 'black',
        stroke2: on ? 'blue' : 'black'
      }),

      h(Translate, { y: 10 }, [
        //
        h(Pipe, {
          d: `M ${xR} 0 L ${xL - 50} 0`,
          stroke: on ? 'blue' : 'black'
        }),
        h(Pipe, {
          d: `M ${xM} 0 L ${xM} -70 M ${xM} -80 L ${xM} -100`,
          stroke: on ? 'blue' : 'black'
        }),
        present(`Et320`) ? h(Et, { x: xR - 20, label: 'Et320', dy: 85 }) : ''
      ]),
      h(Spread, {
        scope,
        on,
        showAll,
        x1: xL,
        x2: xR - 20,
        y1: -75,
        y2: -50,
        items: [
          ['Pr351', 'Pr350'],
          ['Vmitigeur', ''],
          ['Pr352', 'Pu350']
        ],
        Vmitigeurprops: { x: xM, ways: 3, label: 'mitig.' }
      })
    ]
  );
}

export function ModulePiscine(props) {
  let { scope, on, width, showAll, showBlockNames } = props;
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  const xmax = width * 0.5;
  const onShared = on;
  if ((scope['Cxx_syst_ServicePiscine'] || 0) == 0) {
    return '';
  }
  if ((scope['Cxx_syst_PositionPiscine'] || 0) == 0) {
    return '';
  }
  let position = scope[`Cxx_syst_PositionPiscine`],
    posChaud =
      scope[`Cxx_syst_ServiceChauffage`] > 0
        ? scope[`Cxx_syst_PositionChaud`]
        : 0;
  const { showFrames } = useContext(SchemaContext);
  return h(
    Gas,
    {
      transform: `translate(0 ${
        100 + (position - 1 + (position == posChaud ? 1 : 0)) * 150
      })`
    },
    [
      h('rect', {
        x: -xmax,
        y: -100,
        width: width,
        height: 150,
        className: (showAll ? ' showAll ' : '') + 'dotted'
      }),
      showBlockNames
        ? h(
            'text',
            {
              'dominant-baseline': 'text-bottom',
              'text-anchor': 'middle',
              stroke: '#800',
              y: -85,
              className: 'block-label'
            },
            blockname(scope, 'schema_Po_BlockName', 'Piscine')
          )
        : '',
      h('g', { className: 'hline1', transform: `translate(0 -25)` }, [
        h(Junction, { x: -xmax - 25 }),
        position != posChaud
          ? h(Pipe, {
              d: `M ${-xmax - 25} 0 h 50`,
              stroke: onShared ? 'red' : 'black'
            })
          : '',
        position == posChaud
          ? [
              h(Junction, { x: -xmax + 25, y: -150 }),
              h(Pipe, {
                d: `M ${-xmax + 25} -150 v 45 m 0 10 v 95 h 50`,
                stroke: onShared ? 'red' : 'black'
              })
            ]
          : '',
        h(Pipe, {
          d: `M ${-xmax + 25} 0 L ${xmax} 0`,
          stroke: on ? 'red' : 'black'
        }),
        !present(`V800`)
          ? ''
          : h(Valve, { label: 'V800', rotate: -90, x: -xmax * 0.55, on }),
        ...[
          [-xmax * 0.2, 'Pr812'],
          [xmax * 0.2, 'Pr814']
        ].map(Pr(scope, showAll))
      ]),
      h('g', { className: 'hline2', transform: `translate(0 25)` }, [
        h(Pipe, {
          d: `M ${xmax} 0 h ${-xmax - 45 - (xmax - 50)}`,
          stroke: on ? 'blue' : 'black'
        }),
        position != posChaud
          ? [
              h(Junction, { x: -xmax - 45 }),
              h(Pipe, {
                d: `M ${-xmax + 5} 0 h -25  m -10 0 h -15`,
                stroke: onShared ? 'blue' : 'black'
              })
            ]
          : '',
        position == posChaud
          ? [
              h(Junction, { x: -xmax + 5, y: -150 }),
              h(Pipe, {
                d: `M ${-xmax + 5} 0 v -130 m 0 -15 v 5`,
                stroke: onShared ? 'blue' : 'black'
              })
            ]
          : '',
        ...[
          [-xmax * 0.2, 'Pr811'],
          [xmax * 0.2, 'Pr813']
        ].map(Pr(scope, showAll, true)),
        !present(`Pu800`)
          ? ''
          : h(Pump, {
              scope,
              label: 'Pu800',
              rotate: 0,
              double: scope[`Cxx_regu_Pu800_Double`] > 0,
              x: xmax * 0.4
            }),
        !present(`S800`) ? '' : h(Tap, { label: 'S800', x: xmax * 0.6 })
        // !present(`Ex800`) ? '':
        // h(Et,{label:'Et800',x:xmax*1.8}),
      ]),
      showAll
        ? h(
            'text',
            { y: -70, stroke: '#800', 'text-anchor': 'middle' },
            'show all'
          )
        : '',
      !present(`Ex800`) ? '' : h('use', { href: '#exch', x: 0 }),
      //Regime et Puissance
      [
        [-6, scope[`schema_Po_Regime`]],
        [6, addunit(scope[`schema_Po_Puissance`], 'kW')]
      ].map(([y, t]) => [h(CText, { x: xmax, y }, t)])
      //h(Ballon,{scope,probes:['Pr800a','Pr800s'],x:xmax*.7,showAll}),
    ]
  );
}

function Tank(props) {
  const { fill, x, y, width, height } = Object.assign(
      { fill: '#ddd', x: 0, y: 0, width: 40, height: 100 },
      props
    ),
    transform = `translate(${x || 0} ${y || 0})`;
  return h('g', { transform }, [
    //h('rect',{width:40,height:80,fill}),
    h('g', { transform: `scale(${width / 40} ${(height || 100) / 80})` }, [
      h(
        Translate,
        { x: -10, y: -40 },
        h('rect', {
          width: 40,
          height: 80,
          stroke: 'none',
          fill,
          mask: 'url(#MTank)'
        })
      )
      //h('use',{href:'#Tank'}),
    ])
    //h('path',{className:'Tank',d:'M -20 -40 A 80 80 30 0 1 20 -40 L 20 40 A 80 80 0 0 1 -20 40 z',stroke:'black','stroke-width':1,fill})
  ]);
}

function Ballon(props) {
  const { scope, probes, cold, x, y, height, fill, showAll } = Object.assign(
    { x: 0, y: 0, height: 80, fill: '#e3e3e3' },
    props
  );
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;

  const transform = x || y ? `translate(${x || 0} ${y || 0})` : '';
  return h('g', { className: 'tank', transform }, [
    //h('rect',{x:10,y:-75,width:50,height:120,fill}),
    h(Tank, { x: 36, y: -3, width: 50, height, fill }),
    probes.map((label, i) =>
      !present(`${label}`)
        ? ''
        : h(Translate, { x: 8 }, [
            h('g', cold ? { transform: `translate(80 0) scale(-1 1)` } : {}, [
              h('path', { d: `M 0 ${-50 + 15 * i} l 20 ${15 * (1 - i)}` }),
              h('circle', {
                cx: 0,
                cy: -50 + 15 * i,
                r: 3,
                fill: cold ? 'blue' : 'darkorange'
              })
            ]),
            h('text', { x: 18, y: -30 + 15 * i }, label)
          ])
    )
  ]);
}

export function ModuleSolaire(props) {
  const { scope, svcOn, inj, showAll, showBlockNames } = props;
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  const on = svcOn.SolaireThermique;

  if (!scope[`Cxx_syst_PositionSolaire`]) return;
  let position = scope[`Cxx_syst_PositionSolaire`];
  return h('g', { transform: `translate(300 ${100 + (position - 1) * 150})` }, [
    h('rect', {
      x: -150,
      y: -75,
      width: 250,
      height: 150,
      className: (showAll ? ' showAll ' : '') + 'dotted'
    }),
    showBlockNames
      ? h(
          CText,
          { stroke: '#800', y: -60, className: 'block-label m' },
          blockname(scope, 'schema_Solar_BlockName', 'Solaire')
        )
      : '',
    // +' TypeMontage:'+scope['Cxx_syst_So_TypeMontageHydraulique']

    h('g', { className: 'hline1', transform: `translate(0 0)` }, [
      h(Pipe, { d: `M -150 0 L -25 0`, stroke: on ? 'red' : 'black' }),
      h(Pipe, {
        d: `M -25 0 L 100 0 h20 m 10 0 h 15`,
        stroke: on && inj ? 'red' : 'black'
      }),
      h(Pipe, { d: `M 145 0 v25`, stroke: on && inj ? 'white' : 'none' }),
      h(Pipe, { d: `M 145 0 v25`, stroke: on && inj ? 'red' : 'none' }),
      h(Junction, { x: 145 }),
      present(`Vm500`)
        ? [
            h(Valve, { label: 'Vm500', ways: 3, rotate: -90, x: -120, on }),
            h(Pipe, { d: `M -120 50 v -50`, stroke: false ? 'red' : 'black' })
          ]
        : '',
      Pr(scope, showAll)([-75, 'Pr501']),
      present(`Vm502`)
        ? [
            h(Pipe, {
              d: `M -25 0 v -35 h 50 v10 h 75 h25`,
              stroke: on && !inj ? 'red' : 'black'
            }),
            h(Car, { rotate: 0, x: 50, y: -25 }),
            [
              { x: -25, y: 0 },
              { x: 125, y: -25 }
            ].map((props) => Junction(props))
          ]
        : '',
      present(`Pu500`)
        ? h(Pump, {
            scope,
            label: 'Pu500',
            rotate: 180,
            double: scope[`Cxx_regu_Pu500_Double`] > 0,
            on
          })
        : '',
      h(Car, { x: 50, rotate: 0 })
    ]),
    h('g', { className: 'hline2', transform: `translate(0 50)` }, [
      h(Pipe, { d: `M 125 0 L 5 0`, stroke: on && inj ? '#0aa' : 'black' }),
      h(Junction, { x: 125 }),
      h(Pipe, { d: 'M 0 0 L -120 0', stroke: on ? '#0aa' : 'black' }),
      h(Pipe, { d: 'M -120 0 L -150 0', stroke: on ? '#0aa' : 'black' }),
      Pr(scope, showAll, true)([-75, 'Pr500']),
      present(`Et500`) ? h(Et, { label: 'Et500', x: -30 }) : '',
      present(`V501`)
        ? h(Valve, { label: 'V501', rotate: -90, x: 50, on })
        : '',
      present(`Vm502`)
        ? [
            h(Pipe, {
              d: `M 145 -25 h -15 m -10 0 h -20 h -100 v 25`,
              stroke: on && !inj ? '#0aa' : 'black'
            }),
            h(Valve, { label: 'Vm502', rotate: -90, x: 75, y: -25, on }),
            h(Junction, { x: 145, y: -25 })
          ]
        : '',
      [-120, 0].map((x) => Junction({ x }))
    ]),
    h(BallonSolaire, { scope, x: -350, y: 25, on, showAll })
  ]);
}

function BallonSolaire(props) {
  const { scope, x, y, on, showAll } = Object.assign(
    { x: 0, y: 0, on: false },
    props
  );
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  const transform = x || y ? `translate(${x || 0} ${y || 0})` : '';

  return h('g', { transform }, [
    h('rect', {
      x: -50,
      y: -100,
      width: 250,
      height: 150,
      className: (showAll ? ' showAll ' : '') + 'dotted'
    }),
    h(
      'text',
      {
        'dominant-baseline': 'text-bottom',
        'text-anchor': 'middle',
        stroke: '#800',
        x: 75,
        y: -85,
        className: 'block-label'
      },
      blockname(scope, 'schema_BallonSolaire_BlockName', 'Ballon Solaire')
    ),
    h('g', { className: 'hline1', transform: `translate(0 -25)` }, [
      //h('rect',{x:150,y:-25,width:50,height:100,fill:'aqua'}),
      h(Tank, { x: 175 - 12.5, y: 25, width: 50, fill: 'aqua' }),
      h('use', { href: '#sun', x: 175 - 40, y: -20 }),
      h(Pipe, { d: `M 0 0 m 20 0 h 130`, stroke: on ? 'red' : 'black' }),
      Pr(scope, showAll, false)([70, 'Pr522']),
      h('path', {
        d: `M -20 70 l 50 -90 h 10 l -50 90 z`,
        fill: 'url(#GradEch)'
      }),
      /*h(Translate,{x:-20,y:-20},[
                h('circle',{r:10,stroke:'yellow',fill:'yellow'}),
                [-135,-90,-45,0,45,90,135,180].map(angle=>h(Rotate,{deg:angle},h('path',{d:'M 20 0 h 10',stroke:'gold'}))),
            ]),*/
      h('use', { href: '#sun', x: -50, y: -70 })
    ]),
    h('g', { className: 'hline2', transform: `translate(0 25)` }, [
      h(Pipe, { d: `M 150 0 h -150`, stroke: on ? '#0aa' : 'black' }),
      h(Et, { label: 'Et520', x: 120 }),
      present(`Pu520`)
        ? h(Pump, {
            scope,
            label: 'Pu520',
            x: 50,
            rotate: 0,
            double: scope[`Cxx_regu_Pu520_Double`] > 0
          })
        : '',
      Pr(scope, showAll, true)([70, 'Pr523'])
    ])
  ]);
}
