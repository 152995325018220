import React, { useState } from 'react';
import { h } from './RR';
import { Switch } from './Switch';
import { Foldable } from './Foldable';

/**
 * SvcSwitches component renders a list of switches based on the provided scope and service states.
 * It allows toggling of service states and updates the parent component via callbacks.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.id='SvcSwitches'] - The id for the root div element.
 * @param {Object} props.scope - The scope object containing various variables.
 * @param {Object} [props.svcOn={}] - The current state of services.
 * @param {Function} [props.setSvcOn=(dict) => {}] - Callback to update the service state.
 * @param {Function} props.setValue - Callback to set the value of scope variables.
 *
 * @returns {JSX.Element} The rendered SvcSwitches component.
 */

export function SvcSwitches({
  id = 'SvcSwitches',
  scope,
  svcOn = {},
  setSvcOn = (dict) => {},
  setValue
}) {
  const [display, setDisplay] = useState(true);
  //const services=Object.fromEntries(Object.keys(scope).filter(k=>/^Cxx_syst_service/i.test(k)).map(k=>[k.substr(16),scope[k]>0]));
  const Hps = Object.fromEntries(
      Object.keys(scope)
        .filter((k) => /^Cxx_syst_Hp0\d_Presente/i.test(k) && scope[k] > 0)
        .map((k) => [k.substr(9, 4), true])
    ),
    hpsChaud = Object.keys(Hps).filter(
      (hp) => scope[`Cxx_syst_${hp}_NbLevelAutoriseChaud`] > 0
    ),
    hpsFroid = Object.keys(Hps).filter(
      (hp) => scope[`Cxx_syst_${hp}_NbLevelAutoriseFroid`] > 0
    ),
    hpsEcs = Object.keys(Hps).filter(
      (hp) => scope[`Cxx_syst_${hp}_NbLevelAutoriseEcs`] > 0
    );

  return (
    <div id={id}>
      <div>
        <Foldable title='animate...' open={display} setOpen={setDisplay}>
          <ul
            style={{
              listStyle: 'none',
              background: '#ddd',
              padding: '4px',
              margin: 0
            }}
          >
            {/* inter general chaud */}
            <li>
              {hpsChaud.length > 0 ? (
                <Switch
                  checked={
                    hpsChaud.filter(
                      (hp) => scope[`Pxx_gene_${hp}_ModeUniteCond`] == 1
                    ).length == hpsChaud.length
                  }
                  onClick={() => {
                    let wasChecked =
                      hpsChaud.filter(
                        (hp) => scope[`Pxx_gene_${hp}_ModeUniteCond`] == 1
                      ).length == hpsChaud.length;
                    setValue(
                      hpsChaud.reduce(
                        (acc, hp) =>
                          Object.assign(
                            acc,
                            mode2dic(hp, wasChecked ? 0 : 111)
                          ),
                        {}
                      )
                    );
                  }}
                >
                  Général Chaud
                </Switch>
              ) : (
                ''
              )}
            </li>

            {/* inter general froid */}
            <li>
              {hpsFroid.length > 0 ? (
                <Switch
                  checked={
                    hpsFroid.filter(
                      (hp) => scope[`Pxx_gene_${hp}_ModeCentaineEvap`] == 2
                    ).length == hpsFroid.length
                  }
                  onClick={() => {
                    let wasChecked =
                      hpsFroid.filter(
                        (hp) => scope[`Pxx_gene_${hp}_ModeCentaineEvap`] == 2
                      ).length == hpsFroid.length;
                    setValue(
                      hpsFroid.reduce(
                        (acc, hp) =>
                          Object.assign(
                            acc,
                            mode2dic(hp, wasChecked ? 0 : 214)
                          ),
                        {}
                      )
                    );
                  }}
                >
                  Général Froid
                </Switch>
              ) : (
                ''
              )}
            </li>

            {/* inter general Ecs */}
            <li>
              {hpsEcs.length > 0 ? (
                <Switch
                  checked={
                    hpsEcs.filter(
                      (hp) => scope[`Pxx_gene_${hp}_ModeUniteCond`] == 2
                    ).length == hpsEcs.length
                  }
                  onClick={() => {
                    let wasChecked =
                      hpsEcs.filter(
                        (hp) => scope[`Pxx_gene_${hp}_ModeUniteCond`] == 2
                      ).length == hpsEcs.length;
                    setValue(
                      hpsEcs.reduce(
                        (acc, hp) =>
                          Object.assign(
                            acc,
                            mode2dic(hp, wasChecked ? 0 : 112)
                          ),
                        {}
                      )
                    );
                  }}
                >
                  Général Ecs
                </Switch>
              ) : (
                ''
              )}
            </li>

            {/* inter service solaire */}
            {Object.keys(scope)
              .filter((k) => /^Cxx_syst_ServiceSolaire/i.test(k) && scope[k])
              .map((k) => k.substr(16))
              .map((svc) => (
                <li key={svc}>
                  <Switch
                    checked={!!svcOn[svc]}
                    onClick={() => {
                      setSvcOn({ ...svcOn, [svc]: !svcOn[svc] });
                    }}
                  >
                    {svc}
                  </Switch>
                </li>
              ))}

            {/* inter par pac*/}
            {Object.keys(Hps).map((hp) =>
              h('li', { key: hp }, [
                h(
                  Switch,
                  {
                    key: 'switch' + hp,
                    checked: digit(scope[`Pxx_gene_${hp}_mode`], 10) > 0,
                    onClick: () => {
                      const on = digit(scope[`Pxx_gene_${hp}_mode`], 10) == 0;
                      if (setValue && /^Hp0\d/.test(hp)) {
                        setValue({
                          [`Pxx_gene_${hp}_RunOK`]: on ? 1 : 0,
                          [`Pxx_gene_${hp}_ModeCentaineEvap`]: 0,
                          [`Pxx_gene_${hp}_ModeDizaine`]: on ? 1 : 0,
                          [`Pxx_gene_${hp}_ModeUniteCond`]: 0,
                          [`Pxx_gene_${hp}_mode`]: on ? 10 : 0
                          //[`Pxx_gene_${hp}_mode`]:(scope[`Pxx_gene_${hp}_ModeCentaineEvap`]||0)*100+(on?1:0)*10+(scope[`Pxx_gene_${hp}_ModeUniteCond`]||0),
                        });
                      }
                    }
                  },
                  hp
                ),
                h(
                  'ul',
                  { key: 'ulhps' + hp },
                  /^Hp0\d/.test(hp) &&
                    digit(scope[`Pxx_gene_${hp}_mode`], 10) > 0
                    ? [
                        'Chaud',
                        'Froid',
                        'Ecs',
                        'BtesExtract',
                        'StockFroid',
                        'BtesCond',
                        'GeoCooling',
                        'Piscine',
                        'Solaire',
                        'Surinjection'
                      ]
                        .filter(
                          (svc) =>
                            scope[`Cxx_syst_${hp}_NbLevelAutorise${svc}`] > 0
                        )
                        .map((svc) => [
                          h(
                            'li',
                            { key: svc },
                            h(
                              Switch,
                              {
                                key: 'switch',
                                checked: {
                                  BtesCond:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 1) == 4,
                                  BtesExtract:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 100) ==
                                    1,
                                  Chaud:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 1) == 1,
                                  Ecs:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 1) == 2,
                                  Froid:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 100) ==
                                    2,
                                  GeoCooling:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 100) ==
                                    5,
                                  Piscine:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 1) == 3,
                                  Solaire:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 100) ==
                                    7,
                                  StockFroid:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 100) ==
                                    4,
                                  Surinjection:
                                    digit(scope[`Pxx_gene_${hp}_mode`], 1) == 4
                                }[svc],
                                onClick: () => {
                                  let dic = {
                                    [`Pxx_gene_${hp}_ModeCentaineEvap`]: digit(
                                      scope[`Pxx_gene_${hp}_mode`],
                                      100
                                    ),
                                    [`Pxx_gene_${hp}_ModeDizaine`]: digit(
                                      scope[`Pxx_gene_${hp}_mode`],
                                      10
                                    ),
                                    [`Pxx_gene_${hp}_ModeUniteCond`]: digit(
                                      scope[`Pxx_gene_${hp}_mode`],
                                      1
                                    )
                                  };
                                  const toggle = (hp, v) =>
                                    (dic[hp] = dic[hp] == v ? 0 : v);
                                  ({
                                    BtesCond: () =>
                                      toggle(`Pxx_gene_${hp}_ModeUniteCond`, 4),
                                    BtesExtract: () =>
                                      toggle(
                                        `Pxx_gene_${hp}_ModeCentaineEvap`,
                                        1
                                      ),
                                    Chaud: () =>
                                      toggle(`Pxx_gene_${hp}_ModeUniteCond`, 1),
                                    Ecs: () =>
                                      toggle(`Pxx_gene_${hp}_ModeUniteCond`, 2),
                                    Froid: () =>
                                      toggle(
                                        `Pxx_gene_${hp}_ModeCentaineEvap`,
                                        2
                                      ),
                                    GeoCooling: () =>
                                      toggle(
                                        `Pxx_gene_${hp}_ModeCentaineEvap`,
                                        5
                                      ),
                                    Piscine: () =>
                                      toggle(`Pxx_gene_${hp}_ModeUniteCond`, 3),
                                    Solaire: () =>
                                      toggle(
                                        `Pxx_gene_${hp}_ModeCentaineEvap`,
                                        7
                                      ),
                                    StockFroid: () =>
                                      toggle(
                                        `Pxx_gene_${hp}_ModeCentaineEvap`,
                                        4
                                      ),
                                    Surinjection: () =>
                                      toggle(`Pxx_gene_${hp}_ModeUniteCond`, 4)
                                  })[svc]();

                                  if (scope[`Cxx_syst_${hp}_Type`] < 2) {
                                    if (
                                      dic[`Pxx_gene_${hp}_ModeCentaineEvap`] ==
                                        0 &&
                                      dic[`Pxx_gene_${hp}_ModeUniteCond`]
                                    ) {
                                      dic[
                                        `Pxx_gene_${hp}_ModeCentaineEvap`
                                      ] = 1;
                                    }
                                    if (
                                      dic[`Pxx_gene_${hp}_ModeUniteCond`] ==
                                        0 &&
                                      dic[`Pxx_gene_${hp}_ModeCentaineEvap`] ==
                                        2
                                    ) {
                                      dic[`Pxx_gene_${hp}_ModeUniteCond`] = 4;
                                    }
                                  }
                                  if (scope[`Cxx_syst_${hp}_Type`] >= 2) {
                                    // pac air rev pas de chaud+froid
                                    if (
                                      ['BtesExtract', 'Froid'].indexOf(svc) >=
                                        0 &&
                                      dic[`Pxx_gene_${hp}_ModeUniteCond`] > 0
                                    )
                                      dic[`Pxx_gene_${hp}_ModeUniteCond`] = 0;
                                    if (
                                      ['Chaud', 'Ecs', 'Piscine'].indexOf(
                                        svc
                                      ) >= 0 &&
                                      dic[`Pxx_gene_${hp}_ModeCentaineEvap`] > 0
                                    )
                                      dic[
                                        `Pxx_gene_${hp}_ModeCentaineEvap`
                                      ] = 0;
                                  }

                                  dic[`Pxx_gene_${hp}_mode`] =
                                    (dic[`Pxx_gene_${hp}_ModeCentaineEvap`] ||
                                      0) *
                                      100 +
                                    (dic[`Pxx_gene_${hp}_ModeDizaine`] || 0) *
                                      10 +
                                    (dic[`Pxx_gene_${hp}_ModeUniteCond`] || 0);
                                  setValue(dic);
                                }
                              },
                              svc
                            )
                          )
                        ])
                    : ''
                )
              ])
            )}
          </ul>
        </Foldable>
      </div>
    </div>
  );
}

const digit = (mode, d) => Math.floor((mode || 0) / d) % 10;

function mode2dic(hp, mode) {
  const c = digit(mode, 100),
    d = digit(mode, 10),
    u = digit(mode, 1);
  return {
    [`Pxx_gene_${hp}_RunOK`]: mode ? 1 : 0,
    [`Pxx_gene_${hp}_ModeCentaineEvap`]: c,
    [`Pxx_gene_${hp}_ModeDizaine`]: d,
    [`Pxx_gene_${hp}_ModeUniteCond`]: u,
    [`Pxx_gene_${hp}_mode`]: mode
  };
}
