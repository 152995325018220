import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import SchemaContext from '../../../../../contexts/SchemaContext';
import { Foldable } from '../components/Foldable';

const ShowInfoSwitches = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { showFrames, setShowFrames, showBlockNames, setShowBlockNames } =
    useContext(SchemaContext);
  //#endregion

  //#region [states]
  const [display, setDisplay] = useState(true);
  //#endregion

  //#region [methods]
  const handleFramesChange = (evt) => {
    const { checked } = evt.target;
    setShowFrames(checked);
  };

  const handleBlockNamesChange = (evt) => {
    const { checked } = evt.target;
    setShowBlockNames(checked);
  };
  //#endregion

  //#region [render]
  return (
    <Foldable title='Show...' open={display} setOpen={setDisplay}>
      <div>
        <Form.Check
          type='switch'
          label={i18n._('config.diagram.showFrames')}
          checked={showFrames}
          onChange={handleFramesChange}
        />
        <Form.Check
          type='switch'
          label={i18n._('config.diagram.showBlockNames')}
          checked={showBlockNames}
          onChange={handleBlockNamesChange}
        />
      </div>
    </Foldable>
  );
  //endregion
};
export default ShowInfoSwitches;
