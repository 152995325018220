import {
  ELEC_CAPEX,
  ELEC_OPEX,
  GAS_OPEX,
  HYDRAULIC,
  INJECTION,
  ITES,
  OTHER_CAPEX,
  OTHER_OPEX_ACCENTA,
  OTHER_OPEX_REF,
  PV,
  SOLAR,
  WATERTANK_COLD,
  WATERTANK_HOT,
  WATERTANK_HOTWATER,
  getBtesParams
} from '../../../../../../../../server/models/design/cost.model';
import { roundNumber } from '../../../../../../utils/data.utils';

export const btesTemplate = (i18n, project) => ({
  title: i18n._('cost.btes'),
  rows: [
    {
      label: i18n._('cost.InitBtesCostA'),
      value: project.otherValues.InitBtesCostA,
      unit: getBtesParams().InitBtesCostA.unit
    },
    {
      label: i18n._('cost.InitBtesCostB'),
      value: project.otherValues.InitBtesCostB,
      unit: getBtesParams().InitBtesCostB.unit
    }
  ]
});

export const capexTemplates = (i18n, project) => [
  {
    title: i18n._('cost.waterTankHot'),
    rows: [
      {
        label: i18n._('cost.InitWaterTankHotCostA'),
        value: project.otherValues.InitWaterTankHotCostA,
        unit: WATERTANK_HOT.InitWaterTankHotCostA.unit
      },
      {
        label: i18n._('cost.InitWaterTankHotCostB'),
        value: project.otherValues.InitWaterTankHotCostB,
        unit: WATERTANK_HOT.InitWaterTankHotCostB.unit
      }
    ]
  },
  {
    title: i18n._('cost.waterTankCold'),
    rows: [
      {
        label: i18n._('cost.InitWaterTankColdCostA'),
        value: project.otherValues.InitWaterTankColdCostA,
        unit: WATERTANK_COLD.InitWaterTankColdCostA.unit
      },
      {
        label: i18n._('cost.InitWaterTankColdCostB'),
        value: project.otherValues.InitWaterTankColdCostB,
        unit: WATERTANK_COLD.InitWaterTankColdCostB.unit
      },
      {
        label: i18n._('cost.InitWaterTankColdExchangerCostA'),
        value: project.otherValues.InitWaterTankColdExchangerCostA,
        unit: WATERTANK_COLD.InitWaterTankColdExchangerCostA.unit
      },
      {
        label: i18n._('cost.InitWaterTankColdExchangerCostB'),
        value: project.otherValues.InitWaterTankColdExchangerCostB,
        unit: WATERTANK_COLD.InitWaterTankColdExchangerCostB.unit
      },
      {
        label: i18n._('cost.InitWaterTankColdPumpCostA'),
        value: project.otherValues.InitWaterTankColdPumpCostA,
        unit: WATERTANK_COLD.InitWaterTankColdPumpCostA.unit
      },
      {
        label: i18n._('cost.InitWaterTankColdPumpCostB'),
        value: project.otherValues.InitWaterTankColdPumpCostB,
        unit: WATERTANK_COLD.InitWaterTankColdPumpCostB.unit
      }
    ]
  },
  {
    title: i18n._('cost.waterTankHotwater'),
    rows: [
      {
        label: i18n._('cost.InitWaterTankHotwaterCostA'),
        value: project.otherValues.InitWaterTankHotwaterCostA,
        unit: WATERTANK_HOTWATER.InitWaterTankHotwaterCostA.unit
      },
      {
        label: i18n._('cost.InitWaterTankHotwaterCostB'),
        value: project.otherValues.InitWaterTankHotwaterCostB,
        unit: WATERTANK_HOTWATER.InitWaterTankHotwaterCostB.unit
      },
      {
        label: i18n._('cost.InitWaterTankHotwaterExchangerCostA'),
        value: project.otherValues.InitWaterTankHotwaterExchangerCostA,
        unit: WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA.unit
      },
      {
        label: i18n._('cost.InitWaterTankHotwaterExchangerCostB'),
        value: project.otherValues.InitWaterTankHotwaterExchangerCostB,
        unit: WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB.unit
      },
      {
        label: i18n._('cost.InitWaterTankHotwaterPumpCostA'),
        value: project.otherValues.InitWaterTankHotwaterPumpCostA,
        unit: WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA.unit
      },
      {
        label: i18n._('cost.InitWaterTankHotwaterPumpCostB'),
        value: project.otherValues.InitWaterTankHotwaterPumpCostB,
        unit: WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB.unit
      }
    ]
  },
  {
    title: i18n._('cost.injection'),
    rows: [
      {
        label: i18n._('cost.InitInjectionExchangerCostA'),
        value: project.otherValues.InitInjectionExchangerCostA,
        unit: INJECTION.InitInjectionExchangerCostA.unit
      },
      {
        label: i18n._('cost.InitInjectionExchangerCostB'),
        value: project.otherValues.InitInjectionExchangerCostB,
        unit: INJECTION.InitInjectionExchangerCostB.unit
      },
      {
        label: i18n._('cost.InitInjectionPumpCostA'),
        value: project.otherValues.InitInjectionPumpCostA,
        unit: INJECTION.InitInjectionPumpCostA.unit
      },
      {
        label: i18n._('cost.InitInjectionPumpCostB'),
        value: project.otherValues.InitInjectionPumpCostB,
        unit: INJECTION.InitInjectionPumpCostB.unit
      }
    ]
  },
  {
    title: i18n._('cost.solarThermal'),
    rows: [
      {
        label: i18n._('cost.InitSolarThermalPanelCostA'),
        value: project.otherValues.InitSolarThermalPanelCostA,
        unit: SOLAR.InitSolarThermalPanelCostA.unit
      },
      {
        label: i18n._('cost.InitSolarThermalVanneCost'),
        value: project.otherValues.InitSolarThermalVanneCost,
        unit: SOLAR.InitSolarThermalVanneCost.unit
      },
      {
        label: i18n._('cost.InitSolarThermalPumpCostA'),
        value: project.otherValues.InitSolarThermalPumpCostA,
        unit: SOLAR.InitSolarThermalPumpCostA.unit
      },
      {
        label: i18n._('cost.InitSolarThermalPumpCostB'),
        value: project.otherValues.InitSolarThermalPumpCostB,
        unit: SOLAR.InitSolarThermalPumpCostB.unit
      }
    ]
  },
  {
    title: i18n._('cost.photovoltaic'),
    rows: [
      {
        label: i18n._('cost.InitPVModuleCostA'),
        value: project.otherValues.InitPVModuleCostA,
        unit: PV.InitPVModuleCostA.unit
      },
      {
        label: i18n._('cost.InitPVModuleCostB'),
        value: project.otherValues.InitPVModuleCostB,
        unit: PV.InitPVModuleCostB.unit
      },
      {
        label: i18n._('cost.InitPVModuleSupportCostA'),
        value: project.otherValues.InitPVModuleSupportCostA,
        unit: PV.InitPVModuleSupportCostA.unit
      },
      {
        label: i18n._('cost.InitPVModuleSupportCostB'),
        value: project.otherValues.InitPVModuleSupportCostB,
        unit: PV.InitPVModuleSupportCostB.unit
      },
      {
        label: i18n._('cost.InitPVInverterCostA'),
        value: project.otherValues.InitPVInverterCostA,
        unit: PV.InitPVInverterCostA.unit
      },
      {
        label: i18n._('cost.InitPVInverterCostB'),
        value: project.otherValues.InitPVInverterCostB,
        unit: PV.InitPVInverterCostB.unit
      }
    ]
  },
  {
    title: i18n._('cost.ites'),
    rows: [
      {
        label: i18n._('cost.InitItesCostA'),
        value: project.otherValues.InitItesCostA,
        unit: ITES.InitItesCostA.unit
      },
      {
        label: i18n._('cost.InitItesCostB'),
        value: project.otherValues.InitItesCostB,
        unit: ITES.InitItesCostB.unit
      }
    ]
  },
  {
    title: i18n._('cost.hydraulicLot'),
    rows: [
      {
        label: i18n._('cost.InitCapexHydraulicMargin'),
        value: roundNumber(
          project.otherValues.InitCapexHydraulicMargin * 100,
          4
        ),
        unit: HYDRAULIC.InitCapexHydraulicMargin.unit
      }
    ]
  },
  {
    title: i18n._('cost.elecLot'),
    rows: [
      {
        label: i18n._('cost.InitCapexLotElecA'),
        value: project.otherValues.InitCapexLotElecA,
        unit: ELEC_CAPEX.InitCapexLotElecA.unit
      },
      {
        label: i18n._('cost.InitCapexLotElecB'),
        value: project.otherValues.InitCapexLotElecB,
        unit: ELEC_CAPEX.InitCapexLotElecB.unit
      }
    ]
  },
  {
    title: i18n._('cost.otherCapex'),
    rows: [
      {
        label: i18n._('cost.InitMOEBtesMargin'),
        value: project.otherValues.InitMOEBtesMargin,
        unit: OTHER_CAPEX.InitMOEBtesMargin.unit
      },
      {
        label: i18n._('cost.InitMOEMargin'),
        value: project.otherValues.InitMOEMargin,
        unit: OTHER_CAPEX.InitMOEMargin.unit
      },
      {
        label: i18n._('cost.InitIntegrationBtesMargin'),
        value: project.otherValues.InitIntegrationBtesMargin,
        unit: OTHER_CAPEX.InitIntegrationBtesMargin.unit
      },
      {
        label: i18n._('cost.InitIntegrationMargin'),
        value: project.otherValues.InitIntegrationMargin,
        unit: OTHER_CAPEX.InitIntegrationMargin.unit
      },
      {
        label: i18n._('cost.InitCapexOther'),
        value: project.otherValues.InitCapexOther,
        unit: OTHER_CAPEX.InitCapexOther.unit
      }
    ]
  },
  {
    title: i18n._('cost.subsidies'),
    rows: [
      {
        label: i18n._('cost.subsidies.InitSubventionVersion'),
        value: i18n._(`subsidies.${project.otherValues.InitSubventionVersion}`)
      }
    ]
  }
];

export const opexTemplates = (i18n, project) => [
  {
    title: i18n._('cost.elec'),
    rows: [
      {
        label: i18n._('cost.InitElecPriceEvolMethod'),
        value: i18n._(
          `elecEvolMethod.${project.otherValues.InitElecPriceEvolMethod}`
        ),
        unit: ELEC_OPEX.InitElecPriceEvolMethod.unit
      },
      {
        label: i18n._('cost.InitElecPriceEvol'),
        value: project.otherValues.InitElecPriceEvol,
        unit: ELEC_OPEX.InitElecPriceEvol.unit
      },
      {
        label: i18n._('cost.InitPVElecToNetworkPriceEvol'),
        value: project.otherValues.InitPVElecToNetworkPriceEvol,
        unit: ELEC_OPEX.InitPVElecToNetworkPriceEvol.unit
      },
      {
        label: i18n._('cost.InitPVElecToProjectPriceEvol'),
        value: project.otherValues.InitPVElecToProjectPriceEvol,
        unit: ELEC_OPEX.InitPVElecToProjectPriceEvol.unit
      }
    ]
  },
  {
    title: i18n._('cost.gas'),
    rows: [
      {
        label: i18n._('cost.InitGasPriceEvolMethod'),
        value: i18n._(
          `gasEvolMethod.${project.otherValues.InitGasPriceEvolMethod}`
        ),
        unit: GAS_OPEX.InitGasPriceEvolMethod.unit
      },
      {
        label: i18n._('cost.InitGasPriceSubscription'),
        value: project.otherValues.InitGasPriceSubscription,
        unit: GAS_OPEX.InitGasPriceSubscription.unit
      },
      {
        label: i18n._('cost.InitGasPrice'),
        value: project.otherValues.InitGasPrice,
        unit: GAS_OPEX.InitGasPrice.unit
      },
      {
        label: i18n._('cost.InitGasPriceEvol'),
        value: project.otherValues.InitGasPriceEvol,
        unit: GAS_OPEX.InitGasPriceEvol.unit
      }
    ]
  },
  {
    title: i18n._('cost.solutionOtherOpex'),
    rows: [
      {
        label: i18n._('cost.InitOpexGaPerf'),
        value: project.otherValues.InitOpexGaPerf,
        unit: OTHER_OPEX_ACCENTA.InitOpexGaPerf.unit
      },
      {
        label: i18n._('cost.InitOpexGaPerfEvol'),
        value: project.otherValues.InitOpexGaPerfEvol,
        unit: OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol.unit
      },
      {
        label: i18n._('cost.InitOpexMaint'),
        value: project.otherValues.InitOpexMaint,
        unit: OTHER_OPEX_ACCENTA.InitOpexMaint.unit
      },
      {
        label: i18n._('cost.InitOpexMaintEvol'),
        value: project.otherValues.InitOpexMaintEvol,
        unit: OTHER_OPEX_ACCENTA.InitOpexMaintEvol.unit
      },
      {
        label: i18n._('cost.InitOpexOther'),
        value: project.otherValues.InitOpexOther,
        unit: OTHER_OPEX_ACCENTA.InitOpexOther.unit
      },
      {
        label: i18n._('cost.InitOpexOtherEvol'),
        value: project.otherValues.InitOpexOtherEvol,
        unit: OTHER_OPEX_ACCENTA.InitOpexOtherEvol.unit
      },
      {
        label: i18n._('cost.InitYearsBeforeStart'),
        value: project.otherValues.InitYearsBeforeStart,
        unit: OTHER_OPEX_ACCENTA.InitYearsBeforeStart.unit
      }
    ]
  },
  {
    title: i18n._('cost.referenceOtherOpex'),
    rows: [
      {
        label: i18n._('cost.InitRefOpexGaPerf'),
        value: project.otherValues.InitRefOpexGaPerf,
        unit: OTHER_OPEX_REF.InitRefOpexGaPerf.unit
      },
      {
        label: i18n._('cost.InitRefOpexGaPerfEvol'),
        value: project.otherValues.InitRefOpexGaPerfEvol,
        unit: OTHER_OPEX_REF.InitRefOpexGaPerfEvol.unit
      },
      {
        label: i18n._('cost.InitRefOpexMaint'),
        value: project.otherValues.InitRefOpexMaint,
        unit: OTHER_OPEX_REF.InitRefOpexMaint.unit
      },
      {
        label: i18n._('cost.InitRefOpexMaintEvol'),
        value: project.otherValues.InitRefOpexMaintEvol,
        unit: OTHER_OPEX_REF.InitRefOpexMaintEvol.unit
      },
      {
        label: i18n._('cost.InitRefOpexOther'),
        value: project.otherValues.InitRefOpexOther,
        unit: OTHER_OPEX_REF.InitRefOpexOther.unit
      },
      {
        label: i18n._('cost.InitRefOpexOtherEvol'),
        value: project.otherValues.InitRefOpexOtherEvol,
        unit: OTHER_OPEX_REF.InitRefOpexOtherEvol.unit
      }
    ]
  }
];
