import { useLingui } from '@lingui/react';
import React from 'react';
import { NEED } from '../../../../../../../server/constants.js';
import ResultsExpandableSection from '../../../../../components/ExpandableSection/ResultsExpandableSection.jsx';
import ResultsCards from '../../../../../components/ResultsCards/ResultsCards.jsx';
import ResultsElements from '../../../../../components/ResultsElements/ResultsElements.jsx';
import Section from '../../../../../components/Section/Section.jsx';
import AccentaVsRefTable from '../../../../../components/TemplateTables/AccentaVsRefTable/AccentaVsRefTable.jsx';
import ResultsExpandableTable from '../../../../../components/TemplateTables/ResultsExpandableTable/ResultsExpandableTable.jsx';
import ResultsTable from '../../../../../components/TemplateTables/ResultsTable/ResultsTable.jsx';
import ConsumptionTable from './components/ConsumptionTable.jsx';
import EnergyCarbonGoalAlert from './components/EnergyCarbonGoalAlert.jsx';
import EnergyCarbonSubstationTable from './components/EnergyCarbonSubstationTable.jsx';
import PowerLoadCurveTotal from './components/PowerLoadCurve/PowerLoadCurveTotal.jsx';
import SubstationsNeedsChart from './components/SubstationsNeedsChart.jsx';
import COPEERPerYearLinesChart from './components/heatpumps/COPEERPerYearLinesChart.jsx';
import COPEERPerYearTable from './components/heatpumps/COPEERPerYearTable.jsx';
import COPsDistributionTable from './components/heatpumps/COPsDistributionTable.jsx';
import EnergyConsumedDistributionTable from './components/heatpumps/EnergyConsumedDistributionTable.jsx';
import EnergyProducedDistributionPieChart from './components/heatpumps/EnergyProducedDistributionPieChart/EnergyProducedDistributionPieChart.jsx';
import EnergyProducedDistributionTable from './components/heatpumps/EnergyProducedDistributionTable.jsx';
import PVEnergyDistributionPieChart from './components/photovoltaïc/PVEnergyDistributionPieChart.jsx';
import PVEnergyTable from './components/photovoltaïc/PVEnergyTable.jsx';
import {
  stdsTemplate,
  temperedLoopTemplate,
  totalByGazTemplate,
  totalCarbonYieldTemplate,
  totalColdTemplate,
  totalEnergyNeedsTemplates,
  totalEnergyYieldTemplate,
  totalHeatTemplate,
  totalHotwaterTemplate,
  totalPVTemplate
} from './templates.js';

const EnergyCarbonSection = ({
  selectedResult,
  comparedResults,
  loadDetails
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const { substations } = selectedResult.ComputeResult.inp;
  if (!substations) return null;
  return (
    <Section title={i18n._('results.energyAndCarbon')} open>
      <h2>{i18n._('results.energyAndCarbon.goals')}</h2>
      <ResultsElements
        element={EnergyCarbonGoalAlert}
        comparedResults={comparedResults}
      />
      {substations.length > 1 &&
        substations.map((sst, sstIndex) => (
          <EnergyCarbonSubstationTable
            sstName={sst.InitStationName}
            sstIndex={sstIndex}
            comparedResults={comparedResults}
            loadDetails={loadDetails}
            key={'energyCarbon_section_sst' + sstIndex}
          />
        ))}
      {substations.length > 1 && (
        <ResultsTable
          level={2}
          comparedResults={comparedResults}
          template={temperedLoopTemplate(i18n, comparedResults)}
        />
      )}
      <Section
        title={
          substations.length > 1
            ? i18n._('totalProject')
            : i18n._('totalProjectBySst', {
                sst: substations[0].InitStationName
              })
        }
        level={2}
        open
      >
        <ResultsExpandableTable
          level={3}
          comparedResults={comparedResults}
          template={stdsTemplate(i18n, comparedResults)}
          onOpen={loadDetails}
        />
        <ResultsExpandableSection comparedResults={comparedResults}>
          <ResultsCards
            element={SubstationsNeedsChart}
            comparedResults={comparedResults}
            title={i18n._('results.energyAndCarbon.needsChart.title')}
            onOpen={loadDetails}
          />
        </ResultsExpandableSection>
        <ResultsTable
          level={3}
          comparedResults={comparedResults}
          template={totalHeatTemplate(i18n, comparedResults)}
        />
        <ResultsExpandableSection comparedResults={comparedResults}>
          <ResultsCards
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.HEAT, sorted: true }}
            comparedResults={comparedResults}
            title={i18n._('results.energyAndCarbon.powerLoadCurve')}
            onOpen={loadDetails}
          />
          <ResultsCards
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.HEAT }}
            comparedResults={comparedResults}
            title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
            onOpen={loadDetails}
          />
        </ResultsExpandableSection>
        <ResultsTable
          level={3}
          comparedResults={comparedResults}
          template={totalColdTemplate(i18n, comparedResults)}
        />
        <ResultsExpandableSection comparedResults={comparedResults}>
          <ResultsCards
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.COLD, sorted: true }}
            comparedResults={comparedResults}
            title={i18n._('results.energyAndCarbon.powerLoadCurve')}
            onOpen={loadDetails}
          />
          <ResultsCards
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.COLD }}
            comparedResults={comparedResults}
            title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
            onOpen={loadDetails}
          />
        </ResultsExpandableSection>
        <ResultsTable
          level={3}
          comparedResults={comparedResults}
          template={totalHotwaterTemplate(i18n, comparedResults)}
        />
        <ResultsExpandableSection comparedResults={comparedResults}>
          <ResultsCards
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.DHW, sorted: true }}
            comparedResults={comparedResults}
            title={i18n._('results.energyAndCarbon.powerLoadCurve')}
            onOpen={loadDetails}
          />
          <ResultsCards
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.DHW }}
            comparedResults={comparedResults}
            title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
            onOpen={loadDetails}
          />
        </ResultsExpandableSection>
        {totalEnergyNeedsTemplates(i18n, comparedResults).map(
          (template, index) => (
            <ResultsTable
              level={3}
              template={template}
              comparedResults={comparedResults}
              key={'energyAndCarbon_energyNeedsTotal_template_' + index}
            />
          )
        )}
        <ResultsExpandableSection comparedResults={comparedResults}>
          <ResultsCards
            element={ConsumptionTable}
            elementProps={{
              consumptionKey: 'Elec',
              verticalTitle: i18n._(
                'results.energyAndCarbon.total.energySource.consumptionTable.elec'
              )
            }}
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.total.energySource.consumptionTable.elec.title'
            )}
          />
        </ResultsExpandableSection>
        <ResultsTable
          template={totalByGazTemplate(i18n, comparedResults)}
          comparedResults={comparedResults}
        />
        <ResultsExpandableSection comparedResults={comparedResults}>
          <ResultsCards
            element={ConsumptionTable}
            elementProps={{
              consumptionKey: 'Gas',
              verticalTitle: i18n._(
                'results.energyAndCarbon.total.energySource.consumptionTable.gas'
              )
            }}
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.total.energySource.consumptionTable.gas.title'
            )}
          />
        </ResultsExpandableSection>
        <ResultsExpandableSection
          level={3}
          comparedResults={comparedResults}
          title={i18n._('results.energyAndCarbon.energyBalance')}
        >
          <ResultsCards
            element={EnergyProducedDistributionPieChart}
            open
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.energyBalance.energyProducedDistributionPieChart.title'
            )}
          />
          <ResultsCards
            element={EnergyProducedDistributionTable}
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.energyBalance.productionsTable.title'
            )}
          />
          <ResultsCards
            element={EnergyConsumedDistributionTable}
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.energyBalance.consumptionsTable.title'
            )}
          />
          <ResultsCards
            element={COPsDistributionTable}
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.energyBalance.copsTable.title'
            )}
          />
          <ResultsCards
            element={COPEERPerYearTable}
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.energyBalance.copEerPerYearTable.title'
            )}
          />
          <ResultsCards
            element={COPEERPerYearLinesChart}
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.energyBalance.copEerPerYearLinesChart.title'
            )}
          />
        </ResultsExpandableSection>
        <ResultsTable
          level={3}
          comparedResults={comparedResults}
          template={totalPVTemplate(i18n, comparedResults)}
        />
        <ResultsExpandableSection comparedResults={comparedResults}>
          <ResultsCards
            element={PVEnergyDistributionPieChart}
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.total.pv.pvEnergyDistributionPieChart.title'
            )}
          />
          <ResultsCards
            element={PVEnergyTable}
            comparedResults={comparedResults}
            title={i18n._(
              'results.energyAndCarbon.total.pv.pvEnergyTable.title'
            )}
          />
        </ResultsExpandableSection>
        <h3>{i18n._('results.energyAndCarbon.energyYield')}</h3>
        <AccentaVsRefTable
          template={totalEnergyYieldTemplate(i18n, comparedResults)}
          comparedResults={comparedResults}
        />
        <h3>{i18n._('results.energyAndCarbon.carbonYield')}</h3>
        <AccentaVsRefTable
          template={totalCarbonYieldTemplate(i18n, comparedResults)}
          comparedResults={comparedResults}
        />
      </Section>
    </Section>
  );
  //#endregion
};

export default React.memo(EnergyCarbonSection);
