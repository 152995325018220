import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { getSourceType } from '../../../../../../../../../server/models/config/thermalProduction/geostorage/settings.model';
import FormSelect from '../../../../../../../components/Form/FormSelect';
import Section from '../../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import './SourceSection.css';

const SourceSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleSourceChange = (value) => {
    config.ConfigsSst[0].Data.geostorage.settings.source.type = value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { source } = config.ConfigsSst[0].Data.geostorage.settings;
  const PARAMS = getSourceType(config.IsModular);
  return (
    <Section
      title={i18n._('config.geostorage.settings.sourceType')}
      level={2}
      open
    >
      <div className='config-form-row'>
        <div className='config-form-input'>
          <span className='form-input-label'>
            {i18n._('config.geostorage.settings.exchange')}
          </span>
          <div className='src-section-exchange-container'>
            <span>
              {i18n._('config.geostorage.settings.capture')}
              <FontAwesomeIcon
                icon={source.capture ? 'fa-check' : 'fa-xmark'}
              />
            </span>
            <span>
              {i18n._('config.geostorage.settings.injection')}
              <FontAwesomeIcon
                icon={source.injection ? 'fa-check' : 'fa-xmark'}
              />
            </span>
          </div>
        </div>
        <FormSelect
          label={i18n._('config.geostorage.settings.sourceType')}
          param={PARAMS.type}
          bottomText={i18n._('param.defaultValue', {
            value: i18n._(`geostorage.sourceType.${PARAMS.type.default}`)
          })}
          value={source.type}
          onChange={(value) => handleSourceChange(value)}
          className='config-form-input'
        />
      </div>
    </Section>
  );
  //#endregion
};

export default SourceSection;
