import { useLingui } from '@lingui/react';
import { merge } from 'lodash';
import React from 'react';
import Plot from 'react-plotly.js';
import './Chart.css';

const LinesChart = ({ data, layout, title, filename }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  if (!data) return null;
  const defaultLayout = {
    margin: { b: 50, t: 50, pad: 10 },
    responsive: true,
    automargin: true
  };
  const chartLayout = layout ? merge({}, defaultLayout, layout) : defaultLayout;
  if (!chartLayout.autosize) {
    if (!chartLayout.width) chartLayout.width = 800;
    if (!chartLayout.height) chartLayout.height = 500;
  }
  return !data ? null : (
    <div className='chart'>
      {title && <p>{title}</p>}
      <Plot
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
        data={data}
        layout={chartLayout}
        config={{
          displaylogo: false,
          displayModeBar: true,
          locale: i18n.locale,
          modeBarButtons: [
            [
              {
                name: i18n._('plotly.dl'),
                icon: Plotly.Icons.camera,
                click: (gd) => {
                  gd.layout.title = {
                    font: { size: 16 },
                    text: title ?? ''
                  };
                  Plotly.downloadImage(gd, {
                    filename: filename ?? title,
                    format: 'png',
                    width: 600,
                    height: 500
                  });
                }
              }
            ]
          ]
        }}
      />
    </div>
  );
  //#endregion
};

export default LinesChart;
