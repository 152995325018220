import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchProjectHeader } from '../../api/company.api';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PopupContext from '../../contexts/PopupContext';
import './ProjectPage.css';
import ProjectHeader from './components/ProjectHeader/ProjectHeader';

const ProjectPage = () => {
  //#region [router]
  const { companyId, projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [project, setProject] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const project = await fetchProjectHeader(companyId, projectId);
        setProject(() => project);
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/not-found');
        } else {
          openErrorToast(err);
        }
      }
    })();
  }, [location]);
  //#endregion

  //#region [render]
  if (!project) return null;
  const pathArr = location.pathname.split('/');
  const designPathIndex = pathArr.findIndex((path) => path === 'design');
  const hasHeader =
    pathArr[designPathIndex + 1].includes('compute') ||
    pathArr[designPathIndex + 1].includes('results');
  return (
    <div className='project-page'>
      <Breadcrumb companyName={project.CoName} projectName={project.AhsName} />
      {hasHeader && (
        <div className='project-header-wrapper'>
          <ProjectHeader project={project} />
        </div>
      )}
      <Outlet context={{ project }} />
    </div>
  );

  //#endregion
};

export default ProjectPage;
