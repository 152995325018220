'use strict';
import SchemaContext from '../../../../../contexts/SchemaContext.jsx';
import { Captage } from './Captage.js';
import defs from './defs.js';
import _Pac from './Pac.js';
import { h, memo, useContext, useMemo } from './RR.js';
import './Services.js';
import {
  BallonMixte_ChaudFroid,
  ChaudPrimaire,
  EcsPrimaire,
  FroidPrimaireDroite,
  FroidPrimaireGauche,
  FroidPrimairePlaceHolder,
  ModulePiscine,
  ModuleSolaire,
  positionFroidDroite
} from './Services.js';
import { mergeSvcOn } from './Utils.js';
const Pac = _Pac(h);

export const SVGMain = memo(_SVGMain);
export const VERSION = 'v1.08'; // "Basé sur PID modulaire ..."

function _SVGMain(props) {
  const { scope, id, style, showAll } = props;
  const svcOn = mergeSvcOn(scope, props.svcOn);
  if (!scope) return h('text', {}, 'scope is undefined');
  const npacs = Object.keys(scope).filter(
    (k) => /^Cxx_syst_Hp\d+_Presente/i.test(k) && scope[k] == 1
  ).length;
  const posCaptage = [
    npacs + 1
    //  (scope[`Cxx_syst_serviceFroid`]?scope[`Cxx_syst_PositionFroid`]+1:0),
    //  (scope[`Cxx_syst_ServiceSolaireThermique`]?scope[`Cxx_syst_PositionSolaire`]+1:0),
  ].reduce((acc, x) => (x > acc ? x : acc));

  const { showFrames, showBlockNames } = useContext(SchemaContext);
  const expensive = (scope, svcOn, showAll) => {
    //console.log('expensive');
    const FroidADroite = scope[`Cxx_syst_ServiceSolaireThermique`] == 0;
    const xpac =
      -200 +
      ((scope['Cxx_syst_serviceFroid'] == 0 ||
        scope[`Cxx_syst_BallonMixte_ChaudFroid`] ||
        FroidADroite) &&
      scope['Cxx_syst_ServiceSolaireThermique'] == 0
        ? -400
        : 0);
    const xV700 = -40;
    const anyHpGOn =
      Object.keys(svcOn).filter(
        (k) => /^Hp\d+/.test(k) && svcOn[k] && scope[`Cxx_syst_${k}_Type`] < 2
      ).length > 0;
    const posFroidDroite = positionFroidDroite(scope),
      BMSmoothConnection = true;
    return [
      defs(h),
      h(
        'style',
        { type: 'text/css' },
        `
        text {font-family:Arial,  helvetica,  sans-serif; font-size: 10pt; stroke-width:.1;}
        text.dbm {dominant-baseline: middle;}
        text.tam {text-anchor: middle;}
        text.m {dominant-baseline: middle;text-anchor: middle;}
        .block-label {visibility: inherit;}
        .showAll {fill: rgb(255,192,203,.5) !important}
        .dotted {stroke:${showFrames ? '#800' : 'none'}; fill:${
          showAll ? 'rgb(255,192,203,.5)' : 'none'
        }; stroke-dasharray: 8 2;stroke-dashoffset:-1;}
        `
      ),
      //h(CText,{y:10},VERSION),
      h('g', { transform: `translate(0 0)` }, [
        h('g', { transform: `translate(${xpac}, 0)` }, [
          ...Object.keys(scope)
            .filter(
              (k) => /^Cxx_syst_Hp\d\d_Presente$/i.test(k) && scope[k] > 0
            )
            .map((k, i) => {
              const X = +k.substr(12, 1);
              const position = i + 1;
              return h(Pac, {
                X,
                scope,
                position,
                npacs,
                pacbefore: i > 0,
                on: svcOn[`Hp0${X}`],
                svcOn,
                showAll,
                showBlockNames
              });
            }),
          h('g', { transform: `translate(0 ${posCaptage * 150 - 75})` }, [
            h(Captage, { scope, svcOn, position: posCaptage, xV700, showAll })
          ])
        ]),
        scope[`Cxx_syst_serviceFroid`] > 0
          ? h('g', { transform: `translate(${xpac - 400}, 0)` }, [
              FroidADroite
                ? h(FroidPrimairePlaceHolder, {
                    on: svcOn.Froid,
                    gc: svcOn.GeoCooling,
                    width: 400,
                    position: scope[`Cxx_syst_PositionFroid`]
                  })
                : h(FroidPrimaireGauche, {
                    scope,
                    on: svcOn.Froid,
                    svcOn,
                    npacs,
                    posCaptage,
                    xV700,
                    width: 400,
                    showAll,
                    showBlockNames
                  })
            ])
          : '',
        +scope[`Cxx_syst_ServiceSolaireThermique`] > 0
          ? h('g', { transform: `translate(${xpac - 400}, 0)` }, [
              h(ModuleSolaire, {
                scope,
                svcOn,
                inj: svcOn.SolaireThermique && !anyHpGOn,
                showAll,
                showBlockNames
              })
            ])
          : '',
        h('g', { transform: `translate(${xpac + 400 + 200 / 2} 0)` }, [
          scope[`Cxx_syst_serviceFroid`] > 0 && FroidADroite
            ? h(FroidPrimaireDroite, {
                scope,
                on: svcOn.Froid,
                svcOn,
                npacs,
                posCaptage,
                xV700,
                width: 400,
                showAll,
                showBlockNames,
                position: posFroidDroite,
                BMSmoothConnection
              })
            : '',
          h(ChaudPrimaire, {
            scope,
            on: svcOn.Chauffage,
            svcOn,
            x: 25,
            width: 250,
            showAll,
            showBlockNames,
            BMSmoothConnection
          }),
          h(EcsPrimaire, {
            scope,
            on: svcOn.ECS,
            width: 200,
            showAll,
            showBlockNames
          }),
          h(ModulePiscine, {
            scope,
            on: svcOn.Piscine,
            width: 200,
            showAll,
            showBlockNames
          }),
          h(BallonMixte_ChaudFroid, {
            scope,
            svcOn,
            x: 100,
            y: 700,
            width: 200,
            showAll,
            posFroidDroite,
            BMSmoothConnection
          })
        ])
      ])
    ];
  };
  const memoized = useMemo(
    () => expensive(scope, svcOn, showAll),
    [scope, svcOn, showAll]
  );

  return h(
    'svg',
    {
      id,
      xmlns: 'http://www.w3.org/2000/svg',
      //'xmlns:link':"http://www.w3.org/1999/xlink",
      width: '100%',
      height: '1600',
      stroke: 'black',
      fill: 'gray',
      viewBox: '-700 -100 1045 1600',
      style
    },
    memoized
  );
}
