const {
  HP_CE,
  HP_PE,
  UPPER_TAPPING_ND,
  HP_ELEC_METER,
  HP_STATUS,
  HP_TYPE,
  NEED,
  COLD_MANAGEMENT
} = require('../../../../constants');
const {
  hasColdAndHeatOrDhw,
  getColdManagementSelectValues,
  hasColdOnly
} = require('../../../../utils/heatpump.utils');
const { getDefaultValues } = require('../../../../utils/param.utils');

const NEEDS = Object.freeze({ key: 'needs', default: [], required: true });

const getPositionParam = (hpsCount) => ({
  key: 'position',
  required: true,
  default: null,
  values: new Array(hpsCount).fill(0).map((_, index) => index + 1)
});

const STATUS = Object.freeze({
  key: 'status',
  required: true,
  default: HP_STATUS.MAIN,
  values: Object.values(HP_STATUS),
  translateValuesIds: Object.values(HP_STATUS).map(
    (status) => `heatpump.status.${status.toLowerCase()}`
  ) // TODO côté front
});

const getSizingParams = (value) => {
  return {
    levels: {
      key: 'levels',
      default: value,
      min: !value ? 0 : 1,
      max: value
    }
  };
};

const ELEC_METER = Object.freeze({
  Ec0: {
    key: HP_ELEC_METER.EC0,
    default: true
  }
});

const getHpLowerModuleParams = (ndList) => {
  return {
    nominalDiameter: {
      key: 'nominalDiameter',
      default: 50,
      values: ndList
    }
  };
};

const getColdManagementParams = (hp, isModular) => {
  const hasColdManagement =
    hp.type === HP_TYPE.AERO && hp.needs.includes(NEED.COLD);
  const isAeroExchanger = hp.type === HP_TYPE.AERO && hasColdAndHeatOrDhw(hp);
  const coldManagementSelectValues = getColdManagementSelectValues(
    hp,
    isModular
  );
  const disabled = isModular && hp.type === HP_TYPE.AERO && hasColdOnly(hp);
  return {
    coldManagement: {
      key: HP_PE.COLD_MANAGEMENT,
      values: coldManagementSelectValues,
      default: isAeroExchanger
        ? COLD_MANAGEMENT.HEAT_SIDE
        : COLD_MANAGEMENT.ABSENT,
      enabled: !disabled,
      hidden: !hasColdManagement,
      translateValuesIds: coldManagementSelectValues.map(
        (position) =>
          `heatpump.coldManagement.${position}${
            position === COLD_MANAGEMENT.COLD_SIDE ||
            position === COLD_MANAGEMENT.HEAT_SIDE
              ? hp.position
              : ''
          }`
      ) // TODO côté front
    }
  };
};

const getHpPipingEquipmentParams = (hp, isModular) => {
  // gestion froid : réglage actuel de la PAC
  const currentColdManagement = hp.pipingEquipment.coldManagement;
  // gestion froid : params du selecteur
  const coldManagementParams = getColdManagementParams(
    hp,
    isModular
  ).coldManagement;
  // règle d'activation des V2V Vex et V2V Vcx
  const hasVexOrVcx =
    currentColdManagement === COLD_MANAGEMENT.HEAT_SIDE ||
    currentColdManagement === COLD_MANAGEMENT.COLD_SIDE ||
    currentColdManagement === COLD_MANAGEMENT.COLD_WATER_TANK;
  // règle d'activation des V2V VexBis et V2V VcxBis
  const hasVexBisOrVcxBis =
    hasColdAndHeatOrDhw(hp) &&
    currentColdManagement === COLD_MANAGEMENT.COLD_WATER_TANK;
  // PAC aéro avec échangeur
  const isAeroExchanger = hp.type === HP_TYPE.AERO && hasColdAndHeatOrDhw(hp);
  return {
    PuC: {
      key: HP_PE.PUC,
      default: true,
      enabled: false
    },
    coldManagement: coldManagementParams,
    PuE: {
      key: HP_PE.PUE,
      default:
        hp.type === HP_TYPE.GEO ||
        (HP_TYPE.AERO &&
          (currentColdManagement === COLD_MANAGEMENT.HEAT_SIDE ||
            currentColdManagement === COLD_MANAGEMENT.COLD_SIDE)),
      enabled: !isModular && (hp.type === HP_TYPE.GEO || isAeroExchanger)
    },
    Ve: {
      key: HP_PE.VE,
      default: hasVexOrVcx,
      hidden: !isAeroExchanger,
      enabled: !isModular
    },
    Vc: {
      key: HP_PE.VC,
      default: hasVexOrVcx,
      hidden: !isAeroExchanger,
      enabled: !isModular
    },
    VeBis: {
      key: HP_PE.VE_BIS,
      default: hasVexBisOrVcxBis,
      hidden: !isAeroExchanger,
      enabled: !isModular
    },
    VcBis: {
      key: HP_PE.VC_BIS,
      default: hasVexBisOrVcxBis,
      hidden: !isAeroExchanger,
      enabled: !isModular
    },
    Hp0_Pr01: {
      key: HP_PE.HP0_PR01,
      default:
        HP_TYPE.AERO &&
        (currentColdManagement === COLD_MANAGEMENT.HEAT_SIDE ||
          currentColdManagement === COLD_MANAGEMENT.COLD_SIDE),
      hidden: !isAeroExchanger,
      enabled: !isModular
    },
    Hp0_Pr02: {
      key: HP_PE.HP0_PR02,
      default:
        HP_TYPE.AERO &&
        (currentColdManagement === COLD_MANAGEMENT.HEAT_SIDE ||
          currentColdManagement === COLD_MANAGEMENT.COLD_SIDE),
      hidden: !isAeroExchanger,
      enabled: !isModular
    }
  };
};

const getHpCollectorsEquipmentParams = (hp, isModular) => {
  const isCombined = hasColdAndHeatOrDhw(hp);
  const params = {
    Vde: {
      key: HP_CE.VDE,
      default:
        hp.type === HP_TYPE.GEO || (hp.type === HP_TYPE.AERO && isCombined)
    },
    Vdc: {
      key: HP_CE.VDC,
      default:
        hp.type === HP_TYPE.GEO || (hp.type === HP_TYPE.AERO && isCombined)
    },
    upperEvapTapping: {
      key: HP_CE.UPPER_EVAP_TAPPING,
      default: UPPER_TAPPING_ND.ABSENT,
      values: Object.values(UPPER_TAPPING_ND),
      translateValuesIds: Object.values(UPPER_TAPPING_ND).map(
        (nominalDiameter) => `upperTapping.${nominalDiameter}`
      ) // TODO côté front
    },
    upperCondTapping: {
      key: HP_CE.UPPER_COND_TAPPING,
      default: UPPER_TAPPING_ND.ABSENT,
      values: Object.values(UPPER_TAPPING_ND),
      translateValuesIds: Object.values(UPPER_TAPPING_ND).map(
        (nominalDiameter) => `upperTapping.${nominalDiameter}`
      ) // TODO côté front
    }
  };
  if (!isModular) {
    delete params.upperEvapTapping;
    delete params.upperCondTapping;
  }
  return params;
};

const getDefaultSelectedHpValues = (hp, hpsAlreadySelectedCount) => {
  hp.levels = hp.compressors;
  hp.position = hpsAlreadySelectedCount + 1;
  Object.keys(hp.capacity).forEach((need) => {
    hp.capacity[need] = {
      ...hp.capacity[need],
      ...getDefaultValues(getSizingParams(hp.levels))
    };
    delete hp.capacity[need].compressors;
  });
  return {
    id: crypto.randomUUID(),
    needs: NEEDS.default,
    status: STATUS.default,
    ...hp
  };
};

const getDefaultHpHydraulicConfigValues = (hp, isModular) => {
  hp.Ec0 = ELEC_METER.Ec0.default;
  hp.pipingEquipment = {
    ...getDefaultValues(getColdManagementParams(hp, isModular))
  };
  hp.pipingEquipment = {
    ...hp.pipingEquipment,
    ...getDefaultValues(getHpPipingEquipmentParams(hp, isModular))
  };
  hp.collectorsEquipment = {
    ...getDefaultValues(getHpCollectorsEquipmentParams(hp, isModular))
  };
  return hp;
};

module.exports = {
  STATUS,
  getPositionParam,
  getSizingParams,
  ELEC_METER,
  getHpLowerModuleParams,
  getColdManagementParams,
  getHpPipingEquipmentParams,
  getHpCollectorsEquipmentParams,
  getDefaultSelectedHpValues,
  getDefaultHpHydraulicConfigValues
};
