import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useLingui } from '@lingui/react';
import React, { useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  BTES_TEMP_KEYS,
  HOURS_PER_YEAR,
  MONTHS
} from '../../../../../../../../../server/constants';
import {
  DETAILED_RESULTS,
  DETAILED_RESULTS_CONSUMPTION_BY_SOURCES,
  DETAILED_RESULTS_THERMAL_CAPACITY_BY_SOURCES
} from '../../../../../../../../../server/models/design/result.model.js';
import LinesChart from '../../../../../../../components/Chart/LinesChart.jsx';
import { CHART_MODE } from '../../../../../../../constants.js';
import { DETAILED_RESULTS_CHART_COLORS } from '../../../../../../../styles/colors.js';
import { getResultFullName } from '../../../../../../../utils/compute.utils.js';
import { formatValue } from '../../../../../../../utils/data.utils';
import { getHoursInterval } from '../../../../../../../utils/date.utils';
import { downloadFile } from '../../../../../../../utils/file.utils';
import './DetailedResults.css';
import HeaderCell from './HeaderCell.jsx';
import ValueCell from './ValueCell.jsx';

const DetailedResults = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [selectedDate, setSelectedDate] = useState({
    month: MONTHS[0],
    year: 0,
    interval: getHoursInterval(MONTHS[0], 0)
  });
  const [columns, setColumns] = useState(() => {
    const cols = [{ name: 'Hour', unit: null, precision: 0 }];
    const resultDetailsArr = Object.entries(result.details);
    Object.entries(DETAILED_RESULTS).forEach(([key, { unit, precision }]) => {
      if (resultDetailsArr.some(([colName]) => colName === key)) {
        cols.push({ name: key, unit, precision });
      }
    });
    const { substations } = result.ComputeResult.inp;
    const addSstCols = (sst, detailedResultsModel) => {
      for (const sources of Object.values(detailedResultsModel)) {
        console.log(sources);
        Object.entries(sources).forEach(([sstKey, { unit, precision }]) => {
          sstKey = sst.InitStationName + '_' + sstKey;
          if (resultDetailsArr.some(([colName]) => colName === sstKey)) {
            cols.push({ name: sstKey, unit, precision });
          }
        });
      }
    };
    substations.forEach((sst) => {
      addSstCols(sst, DETAILED_RESULTS_CONSUMPTION_BY_SOURCES);
      addSstCols(sst, DETAILED_RESULTS_THERMAL_CAPACITY_BY_SOURCES);
    });
    return cols.map((col) => {
      const fullName = col.unit
        ? i18n._('result.detailed.headerWithUnit', {
            key: col.name,
            unit: i18n._(`unit.${col.unit}`)
          })
        : col.name;
      return {
        ...col,
        isSelected: BTES_TEMP_KEYS.includes(col.name),
        fullName,
        defaultWidth: (fullName.length > 15 ? 130 : 80) + fullName.length * 3
      };
    });
  });
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const start = selectedDate.year * HOURS_PER_YEAR;
    const end = (selectedDate.year + 1) * HOURS_PER_YEAR;
    if (!result.details || columns.length === 1) return [];
    const oneYearArr = new Array(HOURS_PER_YEAR).fill(0);
    return columns
      .filter((col) => col.isSelected)
      .map((col) => ({
        x: oneYearArr.map((_, i) => selectedDate.year * HOURS_PER_YEAR + i),
        y: result.details[col.name].slice(start, end),
        name: col.name,
        line: { color: DETAILED_RESULTS_CHART_COLORS[col.name] },
        mode: CHART_MODE.LINES
      }));
  }, [result.details, columns, selectedDate]);

  const rows = useMemo(() => {
    const newRows = [];
    const hourStart = selectedDate.interval[0] - 1;
    const hourEnd = selectedDate.interval[1] - 1;
    for (let i = hourStart; i < hourEnd; ++i) {
      const row = { Hour: i + 1 };
      columns
        .filter((col) => col.name !== 'Hour')
        .forEach((col) => {
          row[col.name] = formatValue(
            result.details[col.name][i],
            col.precision
          );
        });
      newRows.push(row);
    }
    return newRows;
  }, [result.details, columns, selectedDate]);
  //#endregion

  //#region [methods]
  const handleDownloadClick = () => {
    let csvContent = `${columns.map((col) => col.fullName).join(';')}\n`;
    const nbRows =
      HOURS_PER_YEAR * result.ComputeResult.inp.InitYearsSimulations;
    for (let i = 0; i < nbRows; ++i) {
      columns.forEach((col) => {
        csvContent +=
          col.name === 'Hour'
            ? i + 1
            : formatValue(result.details[col.name][i], col.precision);
        csvContent += ';';
      });
      csvContent += i < nbRows - 1 ? '\n' : '';
    }
    downloadFile(
      getResultFullName(i18n, result).replaceAll('.', '_'),
      new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    );
  };

  const handleMonthChange = (evt) => {
    setSelectedDate((date) => ({
      ...date,
      interval: getHoursInterval(evt.target.value, date.year),
      month: evt.target.value
    }));
  };

  const handleYearChange = (evt) => {
    setSelectedDate((date) => ({
      ...date,
      interval: getHoursInterval(date.month, evt.target.value),
      year: evt.target.value
    }));
  };

  const handleHeaderClick = (key) => {
    setColumns((cols) => {
      const colIndex = cols.findIndex((col) => col.name === key);
      if (colIndex === -1) return cols;
      cols[colIndex].isSelected = !cols[colIndex].isSelected;
      return [].concat(cols);
    });
  };
  //#endregion

  //#region [render]
  const years = result.ComputeResult.inp.InitYearsSimulations;
  const cols = columns.map((col) => ({
    ...col,
    header: () => (
      <HeaderCell
        name={col.fullName}
        isSelected={col.isSelected}
        isSelectable={col.name !== 'Hour'}
        onHeaderClick={() => handleHeaderClick(col.name)}
      />
    ),
    render: ValueCell
  }));
  return (
    <div>
      <Button
        variant='primary'
        className='detailed-btn-dl'
        onClick={handleDownloadClick}
      >
        {i18n._('results.simulationParams.detailed.dl')}
      </Button>
      <div className='detailed-select-body'>
        <span>{i18n._('results.simulationParams.detailed.selectPeriod')}</span>
        <Form.Select
          className='detailed-select'
          onChange={handleMonthChange}
          name='detailed-results-month-select'
        >
          {MONTHS.map((month, index) => (
            <option key={'detailed_month_' + index} value={month}>
              {i18n._(`month.${month}`)}
            </option>
          ))}
        </Form.Select>
        <Form.Select
          className='detailed-select'
          onChange={handleYearChange}
          name='detailed-results-year-select'
        >
          {new Array(years).fill(0).map((_, index) => (
            <option key={'detailed_year_' + index} value={index}>
              {i18n._('results.year', { year: index + 1 })}
            </option>
          ))}
        </Form.Select>
      </div>
      <span className='detailed-instructions'>
        {i18n._('results.simulationParams.detailed.table.instructions')}
      </span>
      <ReactDataGrid
        columns={cols}
        dataSource={rows}
        className='detailed-grid'
        showColumnMenuTool={false}
        sortable={false}
        reorderColumns={false}
        enableColumnAutosize={true}
      />
      <LinesChart
        data={data}
        layout={{ xaxis: { title: i18n._('date.hours') } }}
        title={i18n._('results.year', {
          year: parseInt(selectedDate.year) + 1
        })}
        filename={i18n._('results.simulationParams.detailed.filename', {
          result: getResultFullName(i18n, result),
          year: parseInt(selectedDate.year) + 1
        })}
      />
    </div>
  );
};

export default DetailedResults;
