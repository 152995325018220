import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MODAL_COLORS } from '../../styles/colors';
import './Toast.css';

const Toast = ({ title, body, level, simpleToast }) => {
  //#region [render]
  return (
    <div className='accenta-toast'>
      {simpleToast ? (
        <p style={{ color: MODAL_COLORS[level] }}>{body}</p>
      ) : (
        <>
          <p>
            <FontAwesomeIcon
              icon={level === 'danger' ? 'xmark' : 'check'}
              style={{ color: MODAL_COLORS[level] }}
            />
            {title}
          </p>
          <hr />
          <p>{body}</p>
        </>
      )}
    </div>
  );
  //#endregion
};

export default Toast;
