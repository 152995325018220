import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import {
  HP,
  HP_COLS
} from '../../../../../../server/models/design/performance.model';
import {
  fetchHeatpump,
  fetchHeatpumps,
  fetchHpFile,
  uploadHPFile
} from '../../../../api/heatpump.api';
import Bloc from '../../../../components/Bloc/Bloc';
import Card from '../../../../components/Card/Card';
import LinesChart from '../../../../components/Chart/LinesChart';
import HPSelect from '../../../../components/HPSelect/HPSelect';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import PopupContext from '../../../../contexts/PopupContext';
import { bufferToArray } from '../../../../utils/csv.utils';
import { isObjNullOrEmpty } from '../../../../utils/data.utils';
import { downloadFile } from '../../../../utils/file.utils';
import { getHPAData, getHPGData } from '../../../../utils/heatpump.utils';
import { getDefaultValue } from '../../../../utils/param.utils';

// todo refaire
const HeatPumpsBloc = ({ form, setForm, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal, openErrorToast, openToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [HPGs, setHPGs] = useState();
  const [HPAs, setHPAs] = useState();
  const [HPA, setHPA] = useState();
  const [HPG, setHPG] = useState();
  const [file, setFile] = useState();
  const [HPAWarning, setHPAWarning] = useState();
  //#endregion

  // TODO FAIRE LE TRI DANS LES useEffect
  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        await getHeatPumps();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { HPGFilename, HPGFileID } = form;
        const heatpump = await fetchHeatpump(HPGFileID);
        setHPG({
          data: getHPGData(i18n, heatpump),
          fileId: HPGFileID,
          filename: HPGFilename,
          manufacturer: HPGFilename.split('_')[1]
        });
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [form.HPGFileID]);

  useEffect(() => {
    (async () => {
      try {
        const { HPAFilename, HPAFileID, InitHPAHeatingEnabled } = form;
        const heatpump = await fetchHeatpump(HPAFileID);
        const data = getHPAData(i18n, heatpump);
        setHPA(() => ({
          data,
          fileId: HPAFileID,
          filename: HPAFilename,
          manufacturer: HPAFilename.split('_')[1]
        }));

        const warning =
          isObjNullOrEmpty(data.Heating) && InitHPAHeatingEnabled
            ? i18n._('performance.HPA.warning.heatingEnabled')
            : null;
        setHPAWarning(() => warning);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [form.HPAFileID]);

  useEffect(() => {
    if (!file) return;
    (async () => {
      try {
        await uploadHPFile(file);
        await getHeatPumps();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [file]);
  //#endregion

  //#region [methods]
  const getHeatPumps = async () => {
    try {
      const { hpas, hpgs } = await fetchHeatpumps();
      setHPGs(hpgs);
      setHPAs(hpas);
    } catch (err) {
      throw err;
    }
  };

  const handleHpgFileChange = ({ fileId, filename }) => {
    setForm((oldForm) => ({
      ...oldForm,
      HPGFileID: fileId,
      HPGFilename: filename
    }));
  };

  const handleHpaFileChange = ({ fileId, filename }) => {
    setForm((oldForm) => ({
      ...oldForm,
      HPAFileID: fileId,
      HPAFilename: filename
    }));
  };

  const getLayout = (xAxis, yAxis) => ({
    xaxis: {
      title: {
        text: xAxis
      }
    },
    yaxis: {
      title: {
        text: yAxis
      }
    },
    width: 600,
    height: 450
  });

  const handleFileChange = (evt) => {
    try {
      const csvReader = new FileReader();
      const file = evt.target.files[0];
      if (!file) return;

      // on vérifie le nom du fichier
      const pattern =
        /^(COP|EER|COP-EER)_[^_]+_(Chiller|HeatPump|RéseauChaleur)_(AirWater|WaterWater)_[^_]+(_[^_]+)?\.csv$/;
      if (!pattern.test(file.name)) {
        openToast(
          i18n._('hp.fileError.title'),
          <span>
            {i18n._('hp.fileError.body')}
            <FontAwesomeIcon
              icon='circle-question'
              onClick={() =>
                openInfoModal(
                  i18n._('performance.hpModal.title'),
                  i18n._('performance.hpModal.body')
                )
              }
            />
          </span>
        );
        return;
      }

      // on vérifie le contenu du fichier
      csvReader.readAsText(file);
      csvReader.onload = (event) => {
        try {
          bufferToArray(i18n, event.target.result, Object.values(HP_COLS), '\t');
          setFile(file);
          openToast(
            i18n._('hp.success.title'),
            i18n._('hp.success.body', { file: file.name }),
            'success'
          );
        } catch (e) {
          console.error(e);
          openErrorToast(e);
        }
      };
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleDlClick = async (hpFileId, hpFilename) => {
    try {
      const file = await fetchHpFile(hpFileId);
      downloadFile(hpFilename, new Blob([file], { type: 'text/csv' }));
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const hpgLayout = getLayout(
    i18n._('performance.hpg.xaxis'),
    i18n._('performance.hpg.yaxis')
  );

  const hpaHeatLayout = getLayout(
    i18n._('performance.hpa.heat.xaxis'),
    i18n._('performance.hpa.heat.yaxis')
  );

  const hpaColdLayout = getLayout(
    i18n._('performance.hpa.cold.xaxis'),
    i18n._('performance.hpa.cold.yaxis')
  );
  return HPGs && HPAs && HPG && HPA ? (
    <Bloc
      className='performance-hp-bloc'
      title={i18n._('performance.heatPumps')}
    >
      <ParamInput
        label={i18n._('performance.InitStationCondTemperatureDelta')}
        value={form.InitStationCondTemperatureDelta}
        param={HP.InitStationCondTemperatureDelta}
        onChange={(value) =>
          onInputChange('InitStationCondTemperatureDelta', value)
        }
        bottomText={getDefaultValue(i18n, HP.InitStationCondTemperatureDelta)}
        unit
        type={INPUT_TYPE.NUMBER}
      />
      <Form.Group controlId='formFile'>
        <span className='param-input-label'>
          <Form.Label>{i18n._('performance.uploadHP')}</Form.Label>
          <FontAwesomeIcon
            icon='circle-question'
            className='modal-info-icon'
            onClick={() =>
              openInfoModal(
                i18n._('performance.hpModal.title'),
                i18n._('performance.hpModal.body')
              )
            }
          />
        </span>
        <Form.Control type='file' accept='.csv' onChange={handleFileChange} />
      </Form.Group>
      <div>
        <h2>{i18n._('performance.HPGFilename')}</h2>
        <div className='performance-hp-select'>
          <HPSelect
            heatpumps={HPGs}
            selectedHP={HPG}
            onHPChange={handleHpgFileChange}
          />
          <Button
            variant='outline-secondary'
            onClick={async () => await handleDlClick(HPG.fileId, HPG.filename)}
          >
            <FontAwesomeIcon icon='download' />
            {i18n._('download')}
          </Button>
        </div>
        <Tab.Container defaultActiveKey='withGlycol'>
          <Nav variant='tabs'>
            {HPG.data?.B && (
              <Nav.Item>
                <Nav.Link eventKey='withGlycol'>
                  <span className='nav-link-span'>
                    {i18n._('performance.withGlycol')}
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}
            {HPG.data?.W && (
              <Nav.Item>
                <Nav.Link eventKey='withoutGlycol'>
                  <span className='nav-link-span'>
                    {i18n._('performance.withoutGlycol')}
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Tab.Content>
            {HPG.data?.B && (
              <Tab.Pane eventKey='withGlycol'>
                <div className='performance-charts-tab-pane'>
                  {HPG.data.B.Heating && (
                    <Card title={i18n._('performance.heating')}>
                      <LinesChart
                        data={Object.values(HPG.data.B.Heating)}
                        filename={i18n._('hpg.b.heating.filename', {
                          hpg: HPG.name
                        })}
                        layout={hpgLayout}
                      />
                    </Card>
                  )}
                </div>
              </Tab.Pane>
            )}
            {HPG.data?.W && (
              <Tab.Pane eventKey='withoutGlycol'>
                <div className='performance-charts-tab-pane'>
                  {HPG.data.W.Heating && (
                    <Card title={i18n._('performance.heating')}>
                      <LinesChart
                        data={Object.values(HPG.data.W.Heating)}
                        filename={i18n._('hpg.w.heating.filename', {
                          hpg: HPG.name
                        })}
                        layout={hpgLayout}
                      />
                    </Card>
                  )}
                </div>
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
      <div className='mt-3'>
        <h2>{i18n._('performance.HPAFilename')}</h2>
        <div className='performance-hp-select'>
          <HPSelect
            heatpumps={HPAs}
            selectedHP={HPA}
            onHPChange={handleHpaFileChange}
          />
          <Button
            variant='outline-secondary'
            onClick={async () => await handleDlClick(HPA.fileId, HPA.filename)}
          >
            <FontAwesomeIcon icon='download' />
            {i18n._('download')}
          </Button>
        </div>
        {HPAWarning && (
          <Alert variant='warning' className='performance-warning'>
            {HPAWarning}
          </Alert>
        )}
        <div className='performance-hpa-charts-tab-pane-wrapper'>
          <div className='performance-charts-tab-pane'>
            {HPA.data?.Heating && (
              <Card title={i18n._('performance.heating')}>
                <LinesChart
                  data={Object.values(HPA.data.Heating)}
                  filename={i18n._('hpa.heating.filename', {
                    hpa: HPA.name
                  })}
                  layout={hpaHeatLayout}
                />
              </Card>
            )}
            {HPA.data?.Cooling && (
              <Card title={i18n._('performance.cooling')}>
                <LinesChart
                  data={Object.values(HPA.data.Cooling)}
                  filename={i18n._('hpa.cooling.filename', {
                    hpa: HPA.name
                  })}
                  layout={hpaColdLayout}
                />
              </Card>
            )}
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitInjectionTemperatureDelta')}
            value={form.InitInjectionTemperatureDelta}
            param={HP.InitInjectionTemperatureDelta}
            onChange={(value) =>
              onInputChange('InitInjectionTemperatureDelta', value)
            }
            bottomText={getDefaultValue(i18n, HP.InitInjectionTemperatureDelta)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitInjectionTemperatureMin')}
            value={form.InitInjectionTemperatureMin}
            param={HP.InitInjectionTemperatureMin}
            onChange={(value) =>
              onInputChange('InitInjectionTemperatureMin', value)
            }
            bottomText={getDefaultValue(i18n, HP.InitInjectionTemperatureMin)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.HPAPenaltyTemperatureMin')}
            value={form.HPAPenaltyTemperatureMin}
            param={HP.HPAPenaltyTemperatureMin}
            onChange={(value) =>
              onInputChange('HPAPenaltyTemperatureMin', value)
            }
            bottomText={getDefaultValue(i18n, HP.HPAPenaltyTemperatureMin)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.HPAPenaltyTemperatureMax')}
            value={form.HPAPenaltyTemperatureMax}
            param={HP.HPAPenaltyTemperatureMax}
            onChange={(value) =>
              onInputChange('HPAPenaltyTemperatureMax', value)
            }
            bottomText={getDefaultValue(i18n, HP.HPAPenaltyTemperatureMax)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <ParamInput
        label={i18n._('performance.HPAPenaltyPercentageMax')}
        value={form.HPAPenaltyPercentageMax}
        param={HP.HPAPenaltyPercentageMax}
        onChange={(value) => onInputChange('HPAPenaltyPercentageMax', value)}
        bottomText={getDefaultValue(i18n, HP.HPAPenaltyPercentageMax)}
        unit
        type={INPUT_TYPE.NUMBER}
      />
    </Bloc>
  ) : null;
  //#endregion
};

export default HeatPumpsBloc;
