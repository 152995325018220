const { SOURCE, NEED, UNIT } = require('../../constants');

const DETAILED_RESULTS = Object.freeze({
  BtesFlowRate: { unit: UNIT.CUBIC_METER_PER_HOUR, precision: 0 },
  T1: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  T2: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauInBtes: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauOutBtes: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  QBtes: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 0 },
  QHeadLoss: { unit: UNIT.KILOWATT_HOUR_ELECTRIC, precision: 2 },
  Wpac: { unit: UNIT.KILOWATT, precision: 0 }, // désintégré
  Whpg: { unit: UNIT.KILOWATT, precision: 0 }, // désintégré
  WHeatHpa: { unit: UNIT.KILOWATT, precision: 0 }, // désintégré
  WHotwHpa: { unit: UNIT.KILOWATT, precision: 0 }, // désintégré
  WColdHpa: { unit: UNIT.KILOWATT, precision: 0 }, // désintégré
  WGas: { unit: UNIT.KILOWATT, precision: 0 }, // désintégré
  WInjectionHpa: { unit: UNIT.KILOWATT, precision: 0 }, // désintégré
  COPInjectionHpa: { unit: UNIT.CELSIUS_DEGREE, precision: 2 }, // désintégré
  TDistribHt: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TDistribCold: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TCondHt: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TCondHw: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauInSolar: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauOutSolar: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  QSolar: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  SolarFlowRate: { unit: UNIT.CUBIC_METER_PER_HOUR, precision: 0 },
  RevPV: { unit: UNIT.EURO, precision: 0 }, // désintégré
  PVProd: { unit: UNIT.KILOWATT_HOUR_ELECTRIC, precision: 0 },
  PVSoldToGrid: { unit: UNIT.KILOWATT, precision: 0 },
  PVNotUsedForThermalNeeds: { unit: UNIT.KILOWATT, precision: 0 },
  PVUnused: { unit: UNIT.KILOWATT_HOUR_ELECTRIC, precision: 0 }, // désintégré
  QGeocooling: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }, // désintégré
  OPEX: { unit: UNIT.EURO, precision: 0 },
  NetOPEX: { unit: UNIT.EURO, precision: 0 },
  CO2kg: { unit: UNIT.KILOGRAM, precision: 0 }, // désintégré
  CO2kgref: { unit: UNIT.KILOGRAM, precision: 0 }, // désintégré
  QItesCharge: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  WColdItes: { unit: UNIT.KILOWATT, precision: 0 },
  WItesHpa: { unit: UNIT.KILOWATT, precision: 0 },
  COPItesHpa: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  Qdemand: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 } // désintégré
});

// l'ordre est important, ne pas le modifier
const DETAILED_RESULTS_THERMAL_CAPACITY = Object.freeze({
  [NEED.HEAT]: {
    sources: {
      Q_Heat_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      QTotal_Heat: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  },
  [NEED.COLD]: {
    sources: {
      Q_Cold_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      QTotal_Cold: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  },
  [NEED.DHW]: {
    sources: {
      Q_Hotwater_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      QTotal_Hotwater: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  }
});

const DETAILED_RESULTS_THERMAL_CAPACITY_BY_SOURCES = Object.freeze({
  Totals: {
    QTotal_Heat: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    QTotalHeat: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    QTotal_Hotwater: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    QTotalHotwater: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    QTotal_Cold: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    QTotalCold: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  },
  [SOURCE.HPG]: {
    Q_Heat_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Hotwater_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Cold_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  },
  [SOURCE.TFP]: {
    Q_Heat_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Hotwater_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Cold_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  },
  [SOURCE.HPA]: {
    Q_Heat_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Hotwater_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Cold_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Injection_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  },
  [SOURCE.GAS]: {
    Q_Heat_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Hotwater_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Cold_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }, // désintégré
  },
  [SOURCE.ST]: {
    Q_Heat_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Hotwater_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Cold_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }, // désintégré
  },
  [SOURCE.GEOCOOLING]: {
    Q_Cold_Geocooling: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    Q_Cold_GeoCooling: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  }
});

const DETAILED_RESULTS_CONSUMPTION_BY_SOURCES = Object.freeze({
  Totals: {
    WTotal_Heat: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    WTotal_Hotwater: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    WTotal_Cold: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  },
  [SOURCE.HPG]: {
    W_Heat_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Hotwater_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Cold_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  },
  [SOURCE.TFP]: {
    W_Heat_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Hotwater_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Cold_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  },
  [SOURCE.HPA]: {
    W_Heat_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Hotwater_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Cold_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Injection_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  },
  [SOURCE.GAS]: {
    W_Heat_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Hotwater_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Cold_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }, // désintégré
  },
  [SOURCE.ST]: {
    W_Heat_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Hotwater_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
    W_Cold_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }, // désintégré
  }
});

module.exports = {
  DETAILED_RESULTS,
  DETAILED_RESULTS_THERMAL_CAPACITY,
  DETAILED_RESULTS_THERMAL_CAPACITY_BY_SOURCES,
  DETAILED_RESULTS_CONSUMPTION_BY_SOURCES
};
