'use strict';
import React, { useMemo } from 'react';
import { h } from './RR.js';

export function IconTriangle({ deg, bar = false, style = {} }) {
  return (
    <svg {...{ width: 20, height: 20, style }}>
      <g
        transform={
          'translate(5 16) scale(0.08) rotate(' + deg + ') translate(-50 -50)'
        }
      >
        <path
          key='1'
          d='M 0 0 l 100 50 l -100 50 z'
          stroke='none'
          fill='black'
        />
        {bar ? (
          <rect
            key='2'
            {...{
              x: 90,
              y: 0,
              width: 10,
              height: 100,
              stroke: 'black',
              fill: 'black'
            }}
          />
        ) : (
          ''
        )}
      </g>
    </svg>
  );
}

export function Foldable(props) {
  return useMemo(() => Foldable1(props), [props]);
}

function Foldable1(props) {
  const {
      id,
      title,
      open,
      setOpen,
      backgroundColor = 'rgb(221,221,221,.8)',
      border = 'silver inset 2px',
      draggable = false
    } = props,
    style1 = { position: 'relative' };
  let rel = { x: 0, y: 0 };
  let dspos = { x: 0, y: 0 };
  return (
    <div
      key={id || title}
      style={{ ...style1, padding: '1px' }}
      draggable={draggable}
      onDragStart={(e) => {
        const target = e.target;
        dspos = {
          x: e.clientX,
          y: e.clientY
        };
      }}
      onDragOver={(e) => e.preventDefault()}
      onDragEnd={(e) => {
        e.preventDefault();
        const target = e.target;
        rel.x += e.clientX - dspos.x;
        rel.y += e.clientY - dspos.y;
        target.style.left = `${rel.x}px`;
        target.style.top = `${rel.y}px`;
      }}
    >
      <div>
        <a onClick={() => setOpen(!open)}>
          <u>{title}</u>
          <IconTriangle deg={open ? 90 : 0} />
        </a>
      </div>
      {open ? (
        <div style={{ backgroundColor, border }}>{props.children}</div>
      ) : (
        ''
      )}
    </div>
  );
}
