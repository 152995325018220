import { useLingui } from '@lingui/react';
import React from 'react';
import { SUBSIDIES } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamSelect from '../../../../components/ParamForm/ParamSelect';

const SubsidiesBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.subsidies')} level={2}>
      <ParamSelect
        value={form.InitSubventionVersion}
        onChange={(value) => onInputChange('InitSubventionVersion', value)}
        param={SUBSIDIES.InitSubventionVersion}
        label={i18n._('cost.subsidies.InitSubventionVersion')}
      />
    </Bloc>
  );
  //#endregion
};

export default SubsidiesBloc;
