import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { SRC_EXCHANGE } from '../../../../../../../../server/constants';
import { getHydraulicEquipmentParams } from '../../../../../../../../server/models/config/thermalProduction/geostorage/hydraulic.model';
import Section from '../../../../../../components/Section/Section';
import Table from '../../../../../../components/Table/Table';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';

const HydraulicEquipmentSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleFormCheck = (evt) => {
    const { checked, value } = evt.target;
    config.ConfigsSst[0].Data.geostorage.hydraulicEquipment[value] = checked;
    onFormChange(config);
  };
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const { heatpumps, geostorage } = config.ConfigsSst[0].Data;
    const { settings, exchangers, hydraulicEquipment } = geostorage;

    // on construit les params des équipements hydrauliques
    const HYDRAULIC_EQUIPMENT = getHydraulicEquipmentParams(
      exchangers.Ex400,
      exchangers.Ex410,
      geostorage,
      config.IsModular
    );

    // on parcourt la liste des sources et on filtre les échangeurs en fonction
    const exchange = [];
    if (settings.source.capture) exchange.push(SRC_EXCHANGE.CAPTURE);
    if (settings.source.injection) exchange.push(SRC_EXCHANGE.INJECTION);
    const entries = exchange.reduce((acc, src) => {
      Object.entries(HYDRAULIC_EQUIPMENT)
        .filter(([_, exchangeObj]) => exchangeObj[src])
        .forEach(([key, exchangeObj]) => {
          acc[key] = acc[key] || {};
          acc[key][src] = exchangeObj[src];
        });
      return acc;
    }, {});
    const colsNames = Object.keys(entries).map((colName) =>
      i18n._(`hydraulicConfig.table.th.${colName}`)
    );
    const header = [''].concat(colsNames);
    const firstCol = exchange.map((src) => i18n._(`src.exchange.${src}`));
    const heCols = Object.values(entries).map((equipmentPerSrcExchange) => {
      return Object.values(equipmentPerSrcExchange).map((equipment, index) => {
        const checked = hydraulicEquipment[equipment.value];
        const warning = checked !== equipment.default;
        const checkboxId = 'geo_he_' + index + equipment.value;
        return (
          <div
            data-tooltip-content={
              warning
                ? checked
                  ? i18n._('equipment.notChecked.warning')
                  : i18n._('equipment.checked.warning')
                : null
            }
            data-tooltip-id={'error_' + checkboxId}
            style={{ width: 'fit-content' }}
          >
            <Form.Check
              type='checkbox'
              label={equipment.value}
              value={equipment.value}
              onChange={handleFormCheck}
              checked={checked}
              className={warning ? 'warning' : ''}
              id={'geo_he_' + equipment.value}
              disabled={!equipment.enabled}
            />
            {warning &&
              createPortal(
                <Tooltip
                  id={'error_' + checkboxId}
                  place='bottom'
                  className='error-tooltip'
                  arrowColor='#f04460'
                  opacity={1}
                />,
                document.body
              )}
          </div>
        );
      });
    });
    const cols = [firstCol].concat(heCols);
    return { header, cols };
  }, [config]);
  //#endregion

  //#region [render]
  return (
    <Section
      title={
        config.IsModular
          ? i18n._(
              'config.geostorage.hydraulic.lowerModules.hydraulicEquipment'
            )
          : i18n._('config.geostorage.hydraulic.hydraulicEquipment')
      }
      level={2}
      open
    >
      <Table header={data.header} cols={data.cols} />
    </Section>
  );
  //#endregion
};

export default HydraulicEquipmentSection;
