const {
  PIPE_MATERIAL,
  PIPE_NOMINAL_DIAMETER,
  PROBES_PER_CONNECTION,
  SRC_TYPE,
  UNIT,
  SRC_EXCHANGE,
  HP_TYPE
} = require('../../../../constants');
const { hasColdAndHeatOrDhw } = require('../../../../utils/heatpump.utils');
const { getDefaultValues } = require('../../../../utils/param.utils');

const getSourceExchange = (heatpumps) => {
  return {
    capture: {
      key: SRC_EXCHANGE.CAPTURE,
      default: heatpumps.list.some((hp) => hp.type === HP_TYPE.GEO)
    },
    injection: {
      key: SRC_EXCHANGE.INJECTION,
      default: heatpumps.list.some(
        (hp) =>
          (hp.type === HP_TYPE.GEO && hasColdAndHeatOrDhw(hp)) ||
          (hp.type === HP_TYPE.AERO && heatpumps?.settings?.aero?.btesInjection)
      )
    }
  };
};

const getSourceType = (isModular) => {
  const selectValues = isModular
    ? Object.values(SRC_TYPE).filter(
        (src) =>
          src !== SRC_TYPE.BTES_CLEAN_WATER && src !== SRC_TYPE.GROUNDWATER
      )
    : Object.values(SRC_TYPE);

  return {
    type: {
      key: 'type',
      values: selectValues,
      default: SRC_TYPE.BTES_GLYCOL_WATER,
      translateValuesIds: Object.values(selectValues).map(
        (source) => `geostorage.sourceType.${source}`
      ) // TODO côté front
    }
  };
};

const PROBES = Object.freeze({
  count: {
    key: 'count',
    type: 'number',
    default: null,
    required: true
  },
  depth: {
    key: 'depth',
    type: 'number',
    default: 50,
    required: true,
    unit: UNIT.METER,
    min: 50,
    max: 800
  }
});

const getProbesHdpeNdParam = (depth) => {
  return {
    hdpeNd: {
      key: 'hdpeNd',
      values: PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE],
      default: depth < 150 ? 32 : 40
    }
  };
};

const HORIZONTAL_CONNECTIONS = Object.freeze({
  probesPerConnection: {
    key: 'probesPerConnection',
    default: 1,
    values: PROBES_PER_CONNECTION
  },
  fluidPathDistance: {
    key: 'fluidPathDistance',
    type: 'number',
    default: null,
    required: true,
    unit: UNIT.METER,
    min: 0
  }
});

const getConnectionsHdpeNdParam = (probesHdpeNd, probesPerConnection) => {
  const index = PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE].findIndex(
    (nd) => nd === probesHdpeNd
  );
  return {
    hdpeNd: {
      key: 'hdpeNd',
      values: PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE],
      default:
        probesPerConnection === 1
          ? PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE][index + 1]
          : PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE][index + 2]
    }
  };
};

const COLLECTORS = Object.freeze({
  hdpeNd: {
    key: 'hdpeNd',
    values: PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE],
    default: 32,
    unit: UNIT.MILLIMETER
  },
  fluidPathDistance: {
    key: 'fluidPathDistance',
    type: 'number',
    default: null,
    required: true,
    unit: UNIT.METER,
    min: 0
  }
});

const getDefaultSourceExchange = (heatpumps) => {
  return { ...getDefaultValues(getSourceExchange(heatpumps)) };
};

const getDefaultGeostorageSettingsValues = (heatpumps, isModular) => {
  const PROBES_HDPE = getProbesHdpeNdParam(PROBES.depth.default);
  const CONNECTIONS_HDPE = getConnectionsHdpeNdParam(
    PROBES_HDPE.hdpeNd.default,
    HORIZONTAL_CONNECTIONS.probesPerConnection.default
  );
  return {
    source: {
      ...getDefaultValues(getSourceExchange(heatpumps)),
      ...getDefaultValues(getSourceType(isModular))
    },
    probes: {
      ...getDefaultValues(PROBES),
      ...getDefaultValues(PROBES_HDPE)
    },
    horizontalConnections: {
      ...getDefaultValues(HORIZONTAL_CONNECTIONS),
      ...getDefaultValues(CONNECTIONS_HDPE)
    },
    collectors: { ...getDefaultValues(COLLECTORS) }
  };
};

module.exports = {
  getSourceExchange,
  getDefaultSourceExchange,
  getSourceType,
  PROBES,
  HORIZONTAL_CONNECTIONS,
  COLLECTORS,
  getProbesHdpeNdParam,
  getConnectionsHdpeNdParam,
  getDefaultGeostorageSettingsValues
};
