const Fan=h=>({on})=>{
	const c=x=>Math.cos(x*Math.PI/180),s=x=>Math.sin(x*Math.PI/180);
  const r1=15,r2=48,np=4,nf=12,a=180/np*.7;
	return h('g',{},[

  	h('circle',{r:49,stroke:'gray',fill:'gray'}),
    Array(nf).fill(0).map((_,i)=>h('g',{transform:`rotate(${i*360/np/nf})`,fill:'none',stroke:'black'},[
      Array(np).fill(0).map((_,i)=>i*360/np).map(deg=>h('g',{transform:`rotate(${deg})`},[

  		  h('path',{
            d:`M ${r1*c(-a)} ${r1*s(-a)} A ${r1} ${r1} ${2*a} 0 1 ${r1*c(a)} ${r1*s(a)} L ${.8*r2*c(a)} ${.8*r2*s(a)} A ${.2*r2} ${.2*r2} ${-90-a} 0 0 ${r2*c(.7*a)} ${r2*s(.7*a)} A ${r2} ${r2} ${-2*a} 0 0 ${r2*c(-.7*a)} ${r2*s(-.7*a)} A ${.2*r2} ${.2*r2} ${-90-a} 0 0 ${.8*r2*c(-a)} ${.8*r2*s(-a)} L ${r1*c(-a)} ${r1*s(-a)}`,
            stroke:'none',
            fill:'#ccc',
            visibility:i%12==10?'visible':'hidden'},
        on?h('animate',{attributeName:'visibility',values:Array(nf).fill(0).map((_,j)=>j==i%nf?'visible':'hidden').join(';'),dur:'.5s',calcMode:'discrete',repeatCount:'indefinite'}):'',
				),
	    ])),
    ])),
	  h('circle',{r:r1,stroke:'black',fill:'#ddd'}),
    h('ellipse',{cy:-r1*.4,rx:r1*.6,ry:r1*.3,stroke:'none',fill:'#eee'}),
  ]);

}

export default (h)=>h('defs',null,[
    h('linearGradient',{id:'GradEch',x1:0,y1:0,x2:1,y2:1},[
      h('stop',{offset:'0%','stop-color':'red'}),
      h('stop',{offset:'100%','stop-color':'blue'})
    ]),
    h('linearGradient',{id:'GradSolar',x1:1,y1:0,x2:1,y2:1},[
        h('stop',{offset:'0%','stop-color':'darkorange'}),
        h('stop',{offset:'100%','stop-color':'blue'})
    ]),
    h('linearGradient',{id:'GradCold',x1:0,y1:0,x2:0,y2:1},[
        h('stop',{offset:'0%','stop-color':'aqua','stop-opacity':.1}),
        h('stop',{offset:'100%','stop-color':'blue','stop-opacity':1})
    ]),
    h('linearGradient',{id:'GradChauffage',x1:0,y1:0,x2:0,y2:1},[
        h('stop',{offset:'0%','stop-color':'darkorange','stop-opacity':.1}),
        h('stop',{offset:'100%','stop-color':'gray'})
    ]),
    h('linearGradient',{id:'GradEcs',x1:0,y1:0,x2:0,y2:1},[
        h('stop',{offset:'0%','stop-color':'darkred','stop-opacity':.1}),
        h('stop',{offset:'100%','stop-color':'gray'})
    ]),
    h('pattern',{id:'solar',viewBox:'0,0,2,30',width:'10%',height:'100%'/*x:0,y:0,width:2,height:30*/},[
        //h('rect',{x:0,y:0,width:1,height:2,stroke:'none',fill:'#fff'}),
        h('rect',{x:1,y:0,width:1,height:30,stroke:'none',fill:'url(#GradSolar)'}),
    ]),
    h('circle',{id:'blackdisk',r:3,fill:'black'}),
    h('g',{id:'probe'},[
        h('path',{d:`M 0 -10 v 15`}),
        h('use',{href:'#blackdisk',y:-10}),
    ]),
    h('g',{id:'pump'},[
        h('circle',{cx:0,cy:0,r:10,stroke:'#000',fill:'none'}),
        h('path',{d:`M -9 0 l 15 -7 v 14 z`})
    ]),
    h('g',{id:'car'},[
        h('path',{d:`M 2 0 l -6 -4 v 8 l 6 -4 m 0 -5 v 10`,stroke:'#000',fill:'#000'})
    ]),
    h('g',{id:'exch'},[
        h('rect',{x:-10,y:-35,width:20,height:70,rx:2,fill:'url(#GradEch)'}),
    ]),
    // ...['','-on'].map(suffix=>
    // h('g',{id:'fan'+suffix,transform:`scale(.15)`},
    // [
    //     h('circle',{r:100}),
    //     h('g',{},[
    //         ...[10,120,240].map(angle=>h('g',{transform:`rotate(${angle-20})`},h('circle',{cx:50,r:50,fill:'silver'}))),
    //         suffix==''?'':h('animateTransform',{attributeName:'transform',attributeType:'XML','type':'rotate',from:0,to:120,dur:'1s',repeatCount:'indefinite'}),
    //     ]),
    //     h('circle',{r:30}),
    // ])),
    ...['','-on'].map(suffix=>
        h('g',{id:'fan'+suffix},
            h('g',{transform:`scale(.3)`},
                h(Fan(h),{on:suffix})
            )
        )
    ),
    ...['blue','red','aqua'].map(c=>
        h('g',{id:'arrowleft'+c},h('path',{d:`M -2 0 l 5 -3 v 6 z`,stroke:c,fill:c}))
    ),
    ...['blue','red','aqua'].map(c=>
        h('g',{id:'arrowright'+c},h('path',{d:`M 2 0 l -5 -3 v 6 z`,stroke:c,fill:c}))
    ),
    h('g',
    {
        id:'hpg',
        transform:`scale(0.110132159 0.152487961)`,
    },h('svg',{width: `454`, height: `623`, xmlns: `http://www.w3.org/2000/svg`,
        //'xmlns:xlink': `http://www.w3.org/1999/xlink`,
        overflow: `hidden`},
        [h('defs',{}, [h('clipPath',{id: `clip0`}, h('rect',{x: `286`, y: `64`, width: `454`, height: `623`})), h('linearGradient',{'x1': `805.283`, 'y1': `587.464`, 'x2': `220.717`, 'y2': `163.536`, gradientUnits: `userSpaceOnUse`, spreadMethod: `reflect`, id: `fill1`}, [h('stop',{offset: `0`, 'stop-color': `#C9C9C9`}), h('stop',{offset: `0.27`, 'stop-color': `#E8E8E8`}), h('stop',{offset: `1`, 'stop-color': `#F7F7F7`})]), h('linearGradient',{'x1': `540.494`, 'y1': `217.474`, 'x2': `484.506`, 'y2': `116.526`, gradientUnits: `userSpaceOnUse`, spreadMethod: `reflect`, id: `fill2`}, [h('stop',{offset: `0`, 'stop-color': `#B7EF92`}), h('stop',{offset: `0.5`, 'stop-color': `#D2F4BD`}), h('stop',{offset: `1`, 'stop-color': `#E8F9DF`})])]), h('g',{'clip-path': `url(#clip0)`, transform: `translate(-286 -64)`}, [h('path',{d: `M298.786 68 727.214 68C732.066 68 736 71.9338 736 76.7865L736 683 290 683 290 76.7865C290 71.9338 293.934 68 298.786 68Z`, stroke: `#262626`, 'stroke-width': `6`, 'stroke-miterlimit': `8`, fill: `url(#fill1)`, 'fill-rule': `evenodd`}), h('rect',{x: `290`, y: `189`, width: `446`, height: `49`, fill: `#262626`}), h('rect',{x: `437`, y: `121`, width: `151`, height: `93`, stroke: `#262626`, 'stroke-width': `6`, 'stroke-miterlimit': `8`, fill: `#7F7F7F`}), h('rect',{x: `453`, y: `134`, width: `119`, height: `66`, stroke: `#262626`, 'stroke-width': `6`, 'stroke-miterlimit': `8`, fill: `url(#fill2)`}), h('rect',{x: `290`, y: `560`, width: `446`, height: `50`, fill: `#262626`})])])
    ),
    h('symbol',{id:'sun'},h('g',{transform:`translate(40 40)`},[
        h('circle',{r:10,stroke:'yellow',fill:'yellow'}),
        [-135,-90,-45,0,45,90,135,180].map(angle=>h('g',{transform:`rotate(${angle})`},h('path',{d:'M 20 0 h 10',stroke:'gold','stroke-width':3}))),
    ])),
    h('symbol',{id:'Tank'},
        h('svg',{width: `40`, height: `80`, viewBox: `0 0 421 837`, stroke:'none', fill:'white', xmlns: `http://www.w3.org/2000/svg`, //
        //'xmlns:xlink': `http://www.w3.org/1999/xlink`,
        overflow: `hidden`}, [
            h('g',{transform: `translate(-1194 -528)`}, [
                h('path',{d: `M1401.05 830C1332.05 828.818 1263 827.672 1194 826.49L1194 587.712C1194 554.722 1288.26 528 1404.5 528 1520.74 528 1615 554.722 1615 587.712L1615 826.49C1543.68 827.672 1472.37 828.818 1401.05 830Z`}),
                h('path',{d:'M 1401 999 C 1332 1000 1263 1001 1194 1002 L 1194 1240 C 1194 1273 1288 1300 1405 1300 1521 1300 1615 1273 1615 1240 L 1615 1002 C 1544 1001 1472 1000 1401 999 Z'}),
                h('rect',{x: `1194`, y: `827`, width: `421`, height: `411`}),
                h('path',{d: `M1496.5 1311.5 1496.5 1348.7C1496.5 1362.78 1435.84 1362.59 1405.34 1362.48 1374.84 1362.36 1313.5 1362.09 1313.5 1348.01L1314.19 1311.5 1496.5 1311.5Z`, stroke: `#E3E3E3`, 'stroke-width': `4.58333`, 'stroke-miterlimit': `8`})
            ])
        ])
    ),
    h('mask',{id:'MTank'},h('use',{href:'#Tank'})),
    h('symbol',{id:'chaudiere_gaz'},h('svg',{
        viewBox:'0 0 802 1185',width: `40`, height: `60`,
        xmlns: `http://www.w3.org/2000/svg`,
        //'xmlns:xlink': `http://www.w3.org/1999/xlink`
    }, [
            h('g',{transform: `translate(-2186 -643)`}, [h('path',{d: `M2394.5 1747.5C2394.5 1708.84 2421.59 1677.5 2455 1677.5 2488.41 1677.5 2515.5 1708.84 2515.5 1747.5 2515.5 1786.16 2488.41 1817.5 2455 1817.5 2421.59 1817.5 2394.5 1786.16 2394.5 1747.5Z`, stroke: `#6C6C6C`, 'stroke-width': `20.625`, 'stroke-miterlimit': `8`, fill: `#6C6C6C`, 'fill-rule': `evenodd`}), h('path',{d: `M2688.5 1747.5C2688.5 1708.84 2715.59 1677.5 2749 1677.5 2782.41 1677.5 2809.5 1708.84 2809.5 1747.5 2809.5 1786.16 2782.41 1817.5 2749 1817.5 2715.59 1817.5 2688.5 1786.16 2688.5 1747.5Z`, stroke: `#6C6C6C`, 'stroke-width': `20.625`, 'stroke-miterlimit': `8`, fill: `#6C6C6C`, 'fill-rule': `evenodd`}), h('path',{d: `M2200 717.372C2200 684.029 2227.03 657 2260.37 657L2913.63 657C2946.97 657 2974 684.029 2974 717.372L2974 1707.63C2974 1740.97 2946.97 1768 2913.63 1768L2260.37 1768C2227.03 1768 2200 1740.97 2200 1707.63Z`, stroke: `#E3E3E3`, 'stroke-width': `27.5`, 'stroke-miterlimit': `8`, fill: `#E3E3E3`, 'fill-rule': `evenodd`}), h('path',{d: `M2394.5 1328C2394.5 1313.92 2405.92 1302.5 2420 1302.5L2758 1302.5C2772.08 1302.5 2783.5 1313.92 2783.5 1328L2783.5 1328C2783.5 1342.08 2772.08 1353.5 2758 1353.5L2420 1353.5C2405.92 1353.5 2394.5 1342.08 2394.5 1328Z`, stroke: `#6C6C6C`, 'stroke-width': `4.58333`, 'stroke-miterlimit': `8`, fill: `#6C6C6C`, 'fill-rule': `evenodd`}), h('path',{d: `M2515.5 1653.5C2515.5 1613.18 2548.41 1580.5 2589 1580.5 2629.59 1580.5 2662.5 1613.18 2662.5 1653.5 2662.5 1693.82 2629.59 1726.5 2589 1726.5 2548.41 1726.5 2515.5 1693.82 2515.5 1653.5Z`, stroke: `#6C6C6C`, 'stroke-width': `4.58333`, 'stroke-miterlimit': `8`, fill: `#6C6C6C`, 'fill-rule': `evenodd`})])])),
]);
