const {
  SRC_EXCHANGE,
  SRC_TYPE,
  GEOSTORAGE_EXCHANGER,
  GEOSTORAGE_HE,
  LOWER_MODULES
} = require('../../../../constants');
const { isNull, includes } = require('../../../../utils/data.utils');

const getEx4x0Params = (geostorage, isModular) => {
  const { settings, lowerModules } = geostorage;
  const sourceType = settings.source.type;
  return {
    Ex4x0: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.EX400,
        default: isModular
          ? includes(lowerModules.capture?.module, LOWER_MODULES.M_CAP_BC)
          : sourceType !== SRC_TYPE.BTES_GLYCOL_WATER
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.EX410,
        default: isModular
          ? !isNull(lowerModules.injection?.module)
          : sourceType !== SRC_TYPE.BTES_CLEAN_WATER
      }
    }
  };
};

const getExchangersParams = (Ex400, Ex410, geostorage, isModular) => {
  const { settings, lowerModules } = geostorage;
  const hasCapture = settings.source.capture;
  const hasInjection = settings.source.injection;
  return {
    Pr4x1: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.PR401,
        default: isModular ? !isNull(lowerModules.capture?.module) : hasCapture,
        enabled: hasCapture && !isModular
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.PR411,
        default: isModular ? !isNull(lowerModules.injection?.module) : Ex410,
        enabled: hasInjection && !isModular
      }
    },
    Pr4x2: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.PR402,
        default: isModular ? !isNull(lowerModules.capture?.module) : hasCapture,
        enabled: hasCapture && !isModular
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.PR412,
        default: isModular ? !isNull(lowerModules.injection?.module) : Ex410,
        enabled: hasInjection && !isModular
      }
    },
    Pr4x3: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.PR403,
        default: isModular
          ? includes(lowerModules.capture?.module, LOWER_MODULES.M_CAP_BC)
          : Ex400,
        enabled: hasCapture && !isModular
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.PR413,
        default: isModular ? !isNull(lowerModules.injection?.module) : Ex410,
        enabled: hasInjection && !isModular
      }
    },
    Pr4x4: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.PR404,
        default: isModular
          ? includes(lowerModules.capture?.module, LOWER_MODULES.M_CAP_BC)
          : Ex400,
        enabled: hasCapture && !isModular
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.PR414,
        default: isModular ? !isNull(lowerModules.injection?.module) : Ex410,
        enabled: hasInjection && !isModular
      }
    }
  };
};

const getHydraulicEquipmentParams = (Ex400, Ex410, geostorage, isModular) => {
  const { settings, lowerModules } = geostorage;
  const hasCapture = settings.source.capture;
  const hasInjection = settings.source.injection;
  const sourceType = settings.source.type;
  return {
    Sx00: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_HE.S400,
        default: isModular ? !isNull(lowerModules.capture?.module) : hasCapture,
        enabled: hasCapture && !isModular
      }
    },
    Etx00: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_HE.ET400,
        default: isModular ? !isNull(lowerModules.capture?.module) : hasCapture,
        enabled: hasCapture && !isModular
      }
    },
    Puxx0: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_HE.PU400,
        default: isModular
          ? false
          : Ex400 &&
            (sourceType === SRC_TYPE.BTES_CLEAN_WATER ||
              sourceType === SRC_TYPE.GROUNDWATER),
        enabled: hasCapture && !isModular
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_HE.PU410,
        default: isModular
          ? includes(lowerModules.injection?.module, LOWER_MODULES.M_INJ_PU)
          : Ex410 && sourceType === SRC_TYPE.BTES_GLYCOL_WATER,
        enabled: hasInjection && !isModular
      }
    },
    Vmxx0: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_HE.VM400,
        default: isModular
          ? includes(lowerModules.capture?.module, LOWER_MODULES.M_CAP_BC) &&
            includes(lowerModules.injection?.module, LOWER_MODULES.M_INJ_BC)
          : Ex400 &&
            (sourceType === SRC_TYPE.GLYCOOL_LOOP ||
              sourceType === SRC_TYPE.CLEAN_WATER_LOOP ||
              sourceType === SRC_TYPE.GROUNDWATER) &&
            hasInjection, // règle normale
        enabled: hasCapture && !isModular
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_HE.VM410,
        default: isModular
          ? includes(lowerModules.injection?.module, LOWER_MODULES.M_INJ_BC)
          : Ex410 &&
            (sourceType === SRC_TYPE.GLYCOOL_LOOP ||
              sourceType === SRC_TYPE.CLEAN_WATER_LOOP ||
              sourceType === SRC_TYPE.GROUNDWATER),
        enabled: hasInjection && !isModular
      }
    }
  };
};

const getDefaultValues = (params) => {
  return Object.values(params).reduce((acc, equipmentType) => {
    Object.values(equipmentType).forEach((equipment) => {
      acc[equipment.value] = equipment.default;
    });
    return acc;
  }, {});
};

const getDefaultGeostorageHydraulicConfigValues = (geostorage, isModular) => {
  const Ex4x0 = getDefaultValues(getEx4x0Params(geostorage, isModular));
  const otherExchangers = getDefaultValues(
    getExchangersParams(
      Ex4x0[GEOSTORAGE_EXCHANGER.EX400],
      Ex4x0[GEOSTORAGE_EXCHANGER.EX410],
      geostorage,
      isModular
    )
  );
  const hydraulicEquipment = getDefaultValues(
    getHydraulicEquipmentParams(
      Ex4x0[GEOSTORAGE_EXCHANGER.EX400],
      Ex4x0[GEOSTORAGE_EXCHANGER.EX410],
      geostorage,
      isModular
    )
  );
  return { exchangers: { ...Ex4x0, ...otherExchangers }, hydraulicEquipment };
};

module.exports = {
  getEx4x0Params,
  getExchangersParams,
  getHydraulicEquipmentParams,
  getDefaultGeostorageHydraulicConfigValues
};
