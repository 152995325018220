export const defaultBlockNames = {
  schema_Ht_BlockName: 'Chauffage',
  schema_HtBallonFinisseur_BlockName: 'Chauffage Ballon Finisseur',
  schema_Cd_BlockName: 'Froid',
  schema_Hw_BlockName: 'ECS',
  schema_HwBallonFinisseur_BlockName: 'ECS Ballon Finisseur',
  schema_BallonMixte_BlockName: 'Ballon Mixte',
  schema_Po_BlockName: 'Piscine',
  schema_Solar_BlockName: 'Solaire',
  schema_BallonSolaire_BlockName: 'Ballon Solaire',
  schema_Loop_BlockName: 'Boucle',
  schema_Captage_BlockName: 'Captage',
  schema_Injection_BlockName: 'Injection',
  schema_Hp01_BlockName: '',
  schema_Hp02_BlockName: '',
  schema_Hp03_BlockName: '',
  schema_Hp04_BlockName: '',
  schema_Hp05_BlockName: '',
  schema_Hp06_BlockName: '',
  schema_Hp07_BlockName: '',
  schema_Hp08_BlockName: ''
};

export function blockname(scope, key, defaultValue = '') {
  return scope[key] || defaultBlockNames[key] || defaultValue || key;
}
